import api from "utils/api";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import ArrowLeftIcon from "../../../../../assets/images/icon-chevron-left.svg";
import ArrowRightIcon from "../../../../../assets/images/icon-chevron-right.svg";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { ReactComponent as PhotoIcon } from "../../../../../assets/images/icon-photo.svg";
import { ReactComponent as ProgressIcon } from "../../../../../assets/images/icon-beach.svg";

const GroupLeaderWedding = () => {
    const [loader, setLoader] = useState(true);
    const [weddingDetail, setWeddingDetail] = useState({});
    const [itineraryDetail, setItineraryDetail] = useState({});

    useEffect(async () => {
        _getSummary();
        _getWeddingDetail();
    }, []);

    function _getWeddingDetail() {
        Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
            api.get('/api/wedding/getGroupLeaderWeddingDetailByRoomId?roomId=' + ROOM_ID)
                .then(response => {
                    setWeddingDetail(response.data);
                    setLoader(false);
                }).catch(error => { setLoader(false); });
        })
    }

    function _getSummary() {
        Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
            api.get('/api/Itinerary/getItineraryDetailByRoomId?roomId=' + ROOM_ID)
                .then(response => {
                    setItineraryDetail(response.data);
                    setLoader(false);
                }).catch(error => { setLoader(false); })
        })
    }

    function calculateProgress() {
        if (typeof itineraryDetail.daysTillTravelDate !== "undefined") {
            let date1 = new Date();
            let date2 = new Date('01/01/2020'); //TODO:: Replace this hardcode

            let difference = date1.getTime() - date2.getTime();
            let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
            let passedDays = totalDays - itineraryDetail.daysTillTravelDate;

            let progressPercentage = Math.round((passedDays / totalDays) * 100);
            return progressPercentage + "%";
        }
    }

    return (
        <div className="page-wedding">
            {loader && <Loading />}
            {!loader && weddingDetail && <>
                <h5 className="header">{weddingDetail.brideName + "'s wedding"} </h5>
                <Row>
                    <Col xl="6">
                        {weddingDetail && weddingDetail.formattedImages &&
                            <div className="c-photo-block">
                                <div className="photo">
                                    <CarouselProvider
                                        naturalSlideWidth={100}
                                        naturalSlideHeight={125}
                                        totalSlides={weddingDetail.formattedImages.length}
                                        isIntrinsicHeight={true}
                                        isPlaying={true}
                                        interval={3000}
                                    >
                                        <Slider>
                                            {weddingDetail.formattedImages.map((item, i) => {
                                                return <Slide index={i} key={i}><div className="slide-item-wrapper"> <img src={item} alt="Wedding Image" /></div></Slide>
                                            })}
                                        </Slider>
                                        <ButtonBack className="carouselButton">
                                            <img src={ArrowLeftIcon} alt="Back" />
                                        </ButtonBack>
                                        <ButtonNext className="carouselButton">
                                            <img src={ArrowRightIcon} alt="Next" />
                                        </ButtonNext>
                                    </CarouselProvider>
                                </div>
                                <div className="icon">
                                    <PhotoIcon />
                                </div>
                            </div>
                        }
                        <div className="c-itinerary-progress">
                            <div className="progress-bar">
                                <div className="progress-icon" style={{ left: calculateProgress(), transform: "translateX(-30px)" }}>
                                    <ProgressIcon />
                                </div>
                                <div className="bar" style={{ width: calculateProgress() }}></div>
                            </div>
                            <div className="progress-time">
                                <div className="counter">
                                    <div className="num">{weddingDetail.DaysTillWeddingDate}</div>
                                    days left
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xl="6">


                        <div className="c-border-box border-box-vertical">
                            <div className="box-item">
                                <div className="text-label">BRIDE'S NAME</div>
                                <div className="text-title">{weddingDetail.brideName}</div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">GROOM'S NAME</div>
                                <div className="text-title">{weddingDetail.groomName}</div>
                            </div>
                            <div className="box-item">
                                <div>
                                    <div className="text-label">WEDDING COORDINATOR</div>
                                    <div className="text-title">{weddingDetail.coordinatorName}</div>
                                </div>
                                <div>{weddingDetail.coordinatorEmail}</div>
                            </div>
                            <div className="box-item">
                                <div>
                                    <div className="text-label">WEDDING DATE</div>
                                    <div className="text-title">{weddingDetail.weddingDate}</div>
                                </div>
                                <div>
                                    <div className="text-label">WEDDING TIME</div>
                                    <div className="text-title">{weddingDetail.weddingTime}</div>
                                </div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">VENUE TYPE</div>
                                <div className="text-title">{weddingDetail.venueType}</div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">WEDDING PACKAGE AMOUNT</div>
                                <div className="text-title">{weddingDetail.weddingPackageAmount}</div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">WEDDING PACKAGE PAYMENT</div>
                                <div className="text-title">{weddingDetail.weddingPackagePayment}</div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">WEDDING LOCATION</div>
                                <div className="text-title">{weddingDetail.weddingLocation}</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="mt-2">
                    <Row>
                        <Col lg="6">
                            <a href={weddingDetail.formattedWebsiteUrl} target="_blank" className="c-btn btn-block mb-2">
                                View Website
                             </a>
                        </Col>
                        <Col lg="6">
                            <Link to="/" className="c-btn btn-block mb-2">
                                Secure Reservation
                            </Link>
                        </Col>
                    </Row>
                </div>
            </>}
        </div>
    );
};
export default GroupLeaderWedding;
