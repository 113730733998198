import { getMMDDYYYFormat } from "./utils";

import ChevronRight from "assets/images/icon-chevron-right.svg";

const MobileSummary = (props) => {
  return (
    <div>
      <div className="text-center">
        <h5>Your Room</h5>
        <div>{props.roomName}</div>
      </div>
      <div className="px-4 d-flex justify-content-between align-items-center">
        <div className="text-center">
          <div className="text-label text-sub-label">Check-in</div>
          <h5 className="header ">{getMMDDYYYFormat(props.checkInDate)}</h5>
          <p className="text-body">3:00 PM</p>
        </div>

        <div>
          <span>
            <img src={ChevronRight} />
          </span>
        </div>

        <div className="text-center">
          <div className="text-label text-sub-label">Check-out</div>
          <h5 className="header ">{getMMDDYYYFormat(props.checkOutDate)}</h5>
          <p className="text-body">11:00 AM</p>
        </div>
      </div>
      <div className="mx-4 text-center">
        <span>{props.totalNights}-night stay</span>
        <div className="border mt-2 border-1"></div>
      </div>
    </div>
  );
};

export default MobileSummary;
