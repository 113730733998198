import React from "react";
import Modal from "react-bootstrap/Modal";

import { ReservationHelper } from "core/reservationHelper";

const TravelAgreementModal = ({
  handleAcceptTerms,
  showSubModal,
  handleOnSubModalClose,
}) => {
  const isAccepted = ReservationHelper.GetAcceptTermsAndCondition();
  const show = showSubModal && !isAccepted;
  return (
    <>
      <Modal
        show={show}
        onHide={handleOnSubModalClose}
        backdrop="static"
        size="md"
      >
        <Modal.Header>
          <div className="modal-title">Travel Agreement</div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>
              <strong>Flexible Group Booking terms:</strong>
            </p>
            <ul style={{ listStyleType: `Disc` }}>
              <li>
                <span>
                  Each guest is responsible for creating their reservation and
                  paying a nonrefundable deposit ($150/per person ) for hotel
                  only reservations. Deposits are non refundable unless
                  insurance is purchased. With insurance, guests will receive a
                  refund less the cost of the insurance.
                </span>
              </li>

              <li>
                <span>
                  No rooms are being held. The only way for a guest to lock in a
                  room/rate is to obtain the Guest Dashboard confirmation
                  through Destify.
                </span>
              </li>

              <li>
                <span>
                  Reservations must be paid in full 65 days prior to trip
                </span>
              </li>

              <li>
                <span>
                  Guests can book any amount of nights above a 3-night minimum,
                  in any room category (based on availability). Hotel brands can
                  change their minimum night requirement based on certain time
                  travel times (i.e. holidays) at their discretion.
                </span>
              </li>

              <li>
                <span>
                  Rates are subject to change until deposits are received,
                  Destify will always try to find the lowest price available at
                  the time of booking for guests.
                </span>
              </li>

              <li>
                <span>
                  Modified reservations will be subject to current supplier
                  pricing at the time of the modification. Destify will
                  communicate any rate changes to the guest prior to confirming
                  the modification.
                </span>
              </li>

              <li>
                <span>
                  Once the wedding date is locked in and rooms are booked, if
                  the wedding date is moved outside of originally booked dates,
                  Cancel/Rebook penalties could apply to any reservations in the
                  group that need to be rebooked.
                </span>
              </li>

              <li>
                <span>
                  Please see Price Matching details{" "}
                  <a href="https://destify.com/price-matching/" target="_blank">
                    here
                  </a>{" "}
                </span>
              </li>

              <li>
                <span>
                  See full terms{" "}
                  <a
                    href="https://destify.com/terms-and-conditions"
                    target="_blank"
                  >
                    here
                  </a>{" "}
                </span>
              </li>
            </ul>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "2rem",
              }}
            >
              <button onClick={handleAcceptTerms} className="c-btn">
                I Agree to all terms outlined above.
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TravelAgreementModal;
