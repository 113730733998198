import api from "utils/api";
import { Row, Col } from "reactstrap";
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Loading from "../../../components/Loading";
import ReactInput from '../../../components/InputField';
import PhoneField from '../../../components/PhoneField';
import ReactInputArea from '../../../components/InputAreaField';
import SubMainScreen from '../../../layouts/SubMainScreen';

const ContactSupport = (props) => {
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState({});
    const [responseBody, setResponseBody] = useState(null);
    const [responseTitle, setResponseTitle] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const handleResponseModalClose = () => setShowResponseModal(false);
    const handleResponseOk = () => setShowResponseModal(false);

    const [inputs, setInputs] = useState({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    });

    const handlePhoneInputChange = (e) => setInputs({ phone: e });

    const handleInputChange = (e) => {
        e.persist();
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    }

    const handleValidation = () => {
        let errors = {};
        if (!inputs.name) errors.name = "Name is required";
        if (!inputs.email) errors.email = "Email is required";
        if (!inputs.phone) errors.phone = "Phone is required";
        if (!inputs.subject) errors.subject = "Subject is required";
        if (!inputs.message) errors.message = "Message is required";
        setErrors(errors);
        return errors;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = handleValidation();

        if (Object.keys(errors).length === 0) {
            setLoader(true);
            api.post('/api/support/contactsupport', {
                name: inputs.name,
                email: inputs.email,
                phone: inputs.phone,
                subject: inputs.subject,
                message: inputs.message
            }).then(response => {
                setLoader(false);
                setShowResponseModal(true);

                setResponseTitle("Success");
                setResponseBody("Ticket created successfully");
            }).catch(error => {
                setLoader(false);
                setShowResponseModal(true);
                setResponseTitle("Error");
                setResponseBody("Error occured during contact support. Please contact to the administrator.");
            });
        }
        else return false;
    }

    return (
        <SubMainScreen>

            <div className="mt-2">
                <Row>
                    <Col lg="6">
                        <div className="c-contact-support-screen container">
                            <h5 className="header">Contact Support</h5>
                            <br></br>
                            {loader && <Loading />}
                            {!loader && <>
                                <form className="c-form" onSubmit={handleSubmit}>
                                    <ReactInput type="text" label="Name" name="name" error={errors && errors.name} onChange={handleInputChange} />
                                    <ReactInput type="text" label="Email" name="email" error={errors && errors.email} onChange={handleInputChange} />
                                    <PhoneField international label="Phone Number" name="phone" placeholder="+1 (305) 1234 567" error={errors && errors.phone} onChange={handlePhoneInputChange} />
                                    <ReactInput type="text" label="Subject" name="subject" error={errors && errors.subject} onChange={handleInputChange} />
                                    <ReactInputArea className="message" type="text" label="Message" name="message" rows="5" error={errors && errors.message} onChange={handleInputChange} />
                                    <button type="submit" className="c-btn">Submit</button>
                                </form>
                            </>}
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="c-contact-support-screen container">
                            <h5 className="header">Who We Are</h5>
                            <br></br>
                            <p>
                                Destify is a destination wedding service provider, specializing in savings, impeccable customer care,
                                and efficiency for the group travel industry. We take pride in our easy process and booking technology
                                that creates the best value wedding packages for our clients. Destify was established with the core
                                goal to make destination weddings simpler, with more of the costs upfront, all while directing the
                                    savings back to our customers. Visit us at <a href="https://www.destify.com/" target="_blank" rel='nofollow'>www.destify.com</a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>

            <Modal show={showResponseModal} onHide={handleResponseModalClose} backdrop="static" size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>{responseTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{responseBody}</p>
                    <button className="c-btn c-btn--black c-btn--lg" type="button" onClick={handleResponseOk}>Ok</button>
                </Modal.Body>
            </Modal>
        </SubMainScreen>
    )
}

export default ContactSupport;