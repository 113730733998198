import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import DashboardHeader from "../../components/DashboardNavbar/DesktopHeader";
import DashboardSideMenu from "../../components/DashboardSideMenu";
import DashboardBodySection from "../../components/DashboardBodySection";
import { useState } from "react";


const DashboardLayout = (props) => {
  const [menuActive, setMenuActive] = useState(false);
  const handleMenuToggle = () => {
    
    setMenuActive(!menuActive);
    
  }
  useEffect(()=>{
    var wWidth = window.innerWidth;
    if(wWidth < 768){
      if(!menuActive){
        document.body.style.overflow="auto";
      }else{
        document.body.style.overflow="hidden";
      }
    }
  },[menuActive])
  return (
    <div className={`layout-dashboard ${menuActive?'active-menu':""}`}>
      <DashboardHeader onToggleMenu={handleMenuToggle}/>
      <Container fluid={true}>
        <Row className="d-flex">
          <Col xl="2" md="3" size={3} className="layout-menu">
            <DashboardSideMenu onMenuClicked={handleMenuToggle}/>
          </Col>
          <Col xl="10" md="9" size={9} className="layout-body">
            
            <DashboardBodySection>{props.children}</DashboardBodySection>
          </Col>
        </Row>
      </Container>
      <div className={`c-overlay ${menuActive?'active':""}`} onClick={handleMenuToggle}></div>
    </div>
  );
};

export default DashboardLayout;
