export const DropDownStyleConstant = {
  control: (base) => ({
    ...base,
    height: 50,
    minHeight: 50,
    border:"1px solid #e8e9ea"
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
  }),
};
