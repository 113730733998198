import React from "react";
import CoreHelper from "core/coreHelper";
import { RemainingAmountDueDate } from "../summary/utils";

const Payment = (props) => {
  return (
    <div>
      <div
        className="d-flex justify-content-between align-items-center mb-4"
        style={{ gap: "1em" }}
      >
        {props.showDeposit && (
          <>
            <button
              type="button"
              style={{ width: "45%" }}
              className={`rounded-pill py-2 outline-none border flex-grow-1 ${
                props.selectedPayment === "Deposit"
                  ? "bg-info text-white"
                  : "bg-light"
              }`}
              onClick={() =>
                props.selectPayment({
                  name: "Deposit",
                  amount: props.roomDeposit,
                })
              }
            >
              <div>Place a Deposit</div>
              <span>{CoreHelper.GetUsFormattedAmount(props.roomDeposit)}</span>
            </button>
            <span>or</span>
          </>
        )}
        <button
          type="button"
          style={{ width: "45%" }}
          className={`rounded-pill py-2 outline-none border flex-grow-1 ${
            props.selectedPayment === "Full Payment"
              ? "bg-info text-white"
              : "bg-light"
          }`}
          onClick={() =>
            props.selectPayment({
              name: "Full Payment",
              amount: props.fullPayment,
            })
          }
        >
          <div>Pay In Full</div>
          <span>{CoreHelper.GetUsFormattedAmount(props.fullPayment)}</span>
        </button>
      </div>
      <div className="text-center">
        <span></span>
        <span>
          {props.selectedPayment === "Deposit" && (
            <>
              Remaining balance due by:{" "}
              {RemainingAmountDueDate(props.checkInDate)}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Payment;
