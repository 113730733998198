import React from "react";
import Logo from "../Logo";
import { useState } from "react";
import { Col, Row } from "reactstrap";
import Modal from 'react-bootstrap/Modal';
import AuthHelper from "../../core/authHelper";
import UserProfile from "../../pages/General/UserProfile/index";
import { ReactComponent as NotificationIcon } from "../../assets/images/icon-bell.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/images/icon-arrow-down.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/icon-menu.svg";

const DashboardHeader = (props) => {
  const [responseBody, setResponseBody] = useState();
  const [responseTitle, setResponseTitle] = useState();
  const [showResponseModal, setShowResponseModal] = useState(false);
  const handleResponseModalClose = () => setShowResponseModal(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [accountDropdownActive, setAccountDropdownActive] = useState(false);
  const handleUserProfileModalClose = () => setShowUserProfileModal(false);

  const onAccountDropdownClick = () => {
    setAccountDropdownActive(!accountDropdownActive);
  }

  const onUsernameClick = () => {
    setShowUserProfileModal(true);
  }

  const handleUserProfileResponseCallback = (e) => {
    setShowResponseModal(true);
    setShowUserProfileModal(false);

    if (e) {
      setResponseTitle("Success");
      setResponseBody("User profile information updated successfully.");
    } else {
      setResponseTitle("Error !!!");
      setResponseBody("Error occured during user profile information update.");
    }
  }

  return (
    <div className="c-main-navbar">
      <Row className="d-flex align-items-center justify-content-between">
        <Col size="4">
          <div className="d-flex items-center">
            <div className="navbar-logo">
              <Logo />
            </div>
            <div className="navbar-trigger" onClick={() => props.onToggleMenu()}>
              <MenuIcon />
            </div>
          </div>
        </Col>
        <Col size="4">
          <div className="c-main-navbar__accounts  d-flex  align-items-center justify-content-end">
            <div className="c-notifications">
              <div className="notification-icon active">
                <NotificationIcon />
              </div>
            </div>

            <div className="c-header-account d-flex  align-items-center">
              <div className="initials">{AuthHelper.GetAuthenticatedInitials()}</div>
              <div className="username" onClick={onUsernameClick}>{AuthHelper.GetAuthenticatedFullName()}</div>
              <div className="account-dropdown">
                <div className="trigger" onClick={onAccountDropdownClick}>
                  <ArrowDownIcon />
                </div>
                <div className={`account-dropdown-content ${accountDropdownActive ? 'active' : ''}`}>
                  <div className="dropdown-item">
                    <div className="logout c-btn c-btn--danger" onClick={() => AuthHelper.Logout()}>Logout</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Modal show={showUserProfileModal} onHide={() => handleUserProfileModalClose()} backdrop="static" size="md">
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserProfile responseCallback={handleUserProfileResponseCallback} />
        </Modal.Body>
      </Modal>

      <Modal show={showResponseModal} onHide={() => handleResponseModalClose()} backdrop="static" size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{responseTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{responseBody}</p>
          <button className="c-btn c-btn--ghost c-btn--ghost__primary" type="button" onClick={() => handleResponseModalClose()}>Ok</button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DashboardHeader;
