import React from 'react';
import {Link} from 'react-router-dom';
const IconButton = ({target, icon, label,type,onClick,disabled}) => {
    if(type==="button"){
       return(<button onClick={onClick} disabled={disabled} className="c-btn c-btn--icon c-btn--ghost">
            <span className="icon">
                <img src={icon} alt={label}/>
            </span>
            <span className="label">
                {label}
            </span>
        </button> ) 
    }
    return(
        <Link to={target} className="c-btn c-btn--icon c-btn--ghost">
            <span className="icon">
                <img src={icon} alt={label}/>
            </span>
            <span className="label">
                {label}
            </span>
        </Link>
    )
}
export default IconButton;