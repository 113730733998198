import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import Authhelper from "core/authHelper";
import { ReservationHelper } from "core/reservationHelper";

import { getQueryStringValue } from "../utils";

export const useWeddingData = () => {
  const [weddingDateArray, setWeddingDateArray] = useState([]);
  const [weddingDate, setWeddingDate] = useState(null);
  const [minNights, setMinNights] = useState(0);
  const [loading, setLoading] = useState(true);

  const getGroupId = () => {
    const parsedGroupId = getQueryStringValue("groupId");

    if (parsedGroupId) {
      ReservationHelper.SetGroupId(parsedGroupId);
      return parsedGroupId;
    }
    return ReservationHelper.GetGroupId();
  };

  useEffect(() => {
    const loadData = async () => {
      const authenticatedUserEmailAddress =
        await Authhelper.GetAuthenticatedEmailAddress();
      const groupId = getGroupId();
      const [wedding, groupInfo] = await axios.all([
        axios.get(
          `${
            process.env.REACT_APP_LEGACY_API_URL
          }/api/wedding/getWeddingDetailByGroupId?groupId=${groupId}&email=${
            authenticatedUserEmailAddress || ""
          }`
        ),
        axios.get(
          `${process.env.REACT_APP_LEGACY_API_URL}/api/usergroup/getGroupInfoByGroupId?groupId=${groupId}`
        ),
      ]);

      //this has side-effects impacting localStorage
      await ReservationHelper.GetHotelsInformationAsync(
        groupId,
        groupInfo.data
      );

      const rawWeddingDate = wedding.data.weddingDate?.split("T")[0];
      const parsedWeddingDate = moment(new Date(rawWeddingDate)).toDate();
      setWeddingDate(parsedWeddingDate);
      const weddingDateList = parsedWeddingDate
        .toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "2-digit",
          year: "numeric",
        })
        .split(" ");
      setWeddingDateArray(weddingDateList);
      setMinNights(groupInfo.data.minimumNights);
    };
    loadData()
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return {
    weddingDate,
    weddingDateArray,
    minNights,
    loading,
  };
};
