import React, { useMemo } from "react";

import ReactInputDateField from "components/InputDateField";
import InputCounter from "components/InputCounter";

import { ReservationHelper } from "core/reservationHelper";

import Constraints from "./Constraints";
import { useCheckMobileScreen } from "hooks";

const GuestCounter = ({ hookData, maxGuests }) => {
  const guestHook = hookData;
  const isMobile = useCheckMobileScreen();

  const [adultConstraint] = guestHook.constraints?.adults || [];
  const childConstraint = useMemo(
    () =>
      guestHook.constraints?.children?.find(
        (constraint) => !constraint.isValid
      ),
    [guestHook.constraints?.children]
  );

  const showMaxGuestWarning = guestHook.hasExceededGuests();
  const adultsOnly = ReservationHelper.GetAdultsOnly();

  const constraints = useMemo(
    () => [adultConstraint, childConstraint].filter(Boolean),
    [adultConstraint, childConstraint]
  );

  return (
    <>
      <h4 className={`${isMobile ? "text-center" : ""}`}>
        How many people will be staying in the room with you?
      </h4>
      <Constraints constraints={constraints} />
      <br />
      <div className="row">
        <div className="col-md-6">
          <div className="adult-counter">
            <div className="label">Number of Adults :</div>
            <InputCounter
              limit={maxGuests}
              count={guestHook.adults.length}
              onAdd={guestHook.addAdult}
              onRemove={guestHook.removeAdult}
              min={1}
            />
          </div>
        </div>

        <div className="col-md-6">
          {!adultsOnly && (
            <div className="children-counter">
              <div className="label">Number of Children :</div>
              <InputCounter
                limit={maxGuests}
                count={guestHook.children.length}
                onAdd={guestHook.addChild}
                onRemove={guestHook.removeChild}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          {showMaxGuestWarning && (
            <div className="alert alert-success">
              <p>
                Single rooms cannot accommodate more than 6 people. Most rooms
                have a maximum of 2-4 Adults/Room. Consider booking a second
                room to accommodate your entire party.
              </p>
            </div>
          )}
        </div>
        <div className="col-md-6">
          {!adultsOnly && (
            <div className="child-ages flex flex-wrap">
              {guestHook.children &&
                guestHook.children.map((child) => {
                  return (
                    <div
                      className="child"
                      style={{ width: "48%" }}
                      key={child.index}
                    >
                      <ReactInputDateField
                        value={child.childDob}
                        name="childDob"
                        data-id={`childDob-${child.index}`}
                        label={`Child ${child.index + 1}, Date of Birth`}
                        placeholder="Date of Birth"
                        maxDate={guestHook.maxDate}
                        minDate={guestHook.minDate}
                        validate={guestHook.validateChildAges}
                        range={false}
                        onChange={guestHook.handleChangeDate(child.index)}
                      />
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GuestCounter;
