import api from "utils/api";
import { Row, Col } from "reactstrap";
import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import ModifyReservation from './modify'
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Loading from "../../../../../components/Loading";
import Authhelper from "../../../../../core/authHelper";
import ArrowLeftIcon from "../../../../../assets/images/icon-chevron-left.svg";
import ArrowRightIcon from "../../../../../assets/images/icon-chevron-right.svg";
import { ReactComponent as PhotoIcon } from "../../../../../assets/images/icon-photo.svg";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

const GuestRoom = () => {
    const [loader, setLoader] = useState(true);
    const [roomDetail, setRoomDetail] = useState({});
    const [roomDetails, setRoomDetails] = useState([]);
    const handleModifyReservationClick = () => setshowModifyReservationModal(true);
    const [showModifyReservationModal, setshowModifyReservationModal] = useState(false);
    const handleOnModifyReservationModalClose = () => setshowModifyReservationModal(false);

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            if (typeof AUTHENTICATED_EMAIL === "undefined" || AUTHENTICATED_EMAIL === null) {
                Authhelper.Logout('/login');
            } else {
                api.get('/api/room/getRoomDetailByEmail?email=' + AUTHENTICATED_EMAIL)
                    .then(response => {
                        if (response.data !== "") {
                            setRoomDetails(response.data.roomInfo);
                            setRoomDetail(response.data.roomInfo[0]);
                            setLoader(false);
                        }
                    }).catch(error => { setLoader(false); });
            };
        })
    }, []);

    const getGuestCount = (e) => {
        let _child = e.guestInfo?.filter(x => x.isChild).length;
        let _adult = e.guestInfo?.filter(x => !x.isChild).length;

        let res = _adult === 1 ? _adult + " Adult " : _adult > 1 ? _adult + " Adults " : "";
        res = _child === 1 ? res + _child + " Child" : _child > 1 ? res + _child + " Childs " : res;

        return (res);
    }

    function getGuestNames() {
        return roomDetail.guestInfo?.map((item, index) => {
            return (
                <div className="box-item" key={index}>
                    <div className="text-label">GUEST No{index + 1}</div>
                    <div className="text-title">{item.fullName}</div>
                </div>
            )
        })
    }

    function onRoomSwitch(e) {
        let _selectedRoom = roomDetails.filter(item => {
            return item.roomType === e.value;
        });
        setRoomDetail(_selectedRoom[0]);
    }

    function renderRoomSwitcher() {
        let _options = [];
        roomDetails.map(item => { _options.push(item.roomType) });

        if (_options.length > 1) {
            return < Row >
                <Col lg="6">
                    <Dropdown options={_options} onChange={onRoomSwitch} value={_options[0]} placeholder="Select a RoomType" />
                </Col>
            </Row>
        }
    }

    return (
        <div className="page-room">
            {loader && <Loading />}
            {!loader && <>
                <h5 className="header">Room Information</h5>
                {renderRoomSwitcher()}
                <Row>
                    <Col xl="6">
                        {roomDetail && roomDetail.roomImages &&
                            <div className="c-photo-block">
                                <div className="photo">

                                    <CarouselProvider
                                        naturalSlideWidth={100}
                                        naturalSlideHeight={125}
                                        totalSlides={roomDetail.roomImages.length}
                                        isIntrinsicHeight={true}
                                        isPlaying={true}
                                        interval={3000}
                                    >
                                        <Slider>
                                            {roomDetail.roomImages.map((item, i) => {
                                                return <Slide index={i} key={i}> <div className="slide-item-wrapper"><img src={item} alt="Room Image" /></div></Slide>
                                            })}


                                        </Slider>
                                        <ButtonBack className="carouselButton">
                                            <img src={ArrowLeftIcon} alt="Back" />
                                        </ButtonBack>
                                        <ButtonNext className="carouselButton">
                                            <img src={ArrowRightIcon} alt="Next" />
                                        </ButtonNext>
                                    </CarouselProvider>
                                </div>
                                <div className="icon">
                                    <PhotoIcon />
                                </div>
                            </div>
                        }

                        <h5 className="header header-lg">{roomDetail.roomType}</h5>
                        <p className="text-label text-sm text-400 mb-4">
                            {getGuestCount(roomDetail)}
                        </p>
                        <div className="c-border-box d-flex border-box-horizontal">
                            <div className="box-item flex-fill">
                                <div className="text-label text-sub-label">Check-in</div>
                                <div className="text-title text-lg">
                                    {roomDetail.checkInDate?.split('T')[0]}
                                </div>
                                <div className="text-sub-title">{roomDetail.checkInTime}</div>
                            </div>
                            <div className="box-item flex-fill">
                                <div className="text-label text-sub-label">Check-out</div>
                                <div className="text-title  text-lg">
                                    {roomDetail.checkOutDate?.split('T')[0]}
                                </div>
                                <div className="text-sub-title">{roomDetail.checkOutime}</div>
                            </div>
                        </div>

                    </Col>
                    <Col xl="6">
                        <div className="c-border-box border-box-vertical">
                            {getGuestNames()}
                            <div className="box-item">
                                <div className="text-label">AMOUNT PAID</div>
                                <div className="text-title">
                                    {roomDetail.formattedTotalAmountPaid}
                                </div>
                            </div>
                            <div className="box-item">
                                <div className="text-label">FLIGHT INFO RECEIVED</div>
                                <div className="text-title">
                                    {roomDetail.guestInfo[0].formattedFlightInfoReceived}
                                </div>
                            </div>

                            <div className="box-item">
                                <div className="text-label">INSURANCE</div>
                                <div className="text-title">
                                    {roomDetail.guestInfo[0].formattedPurchasedInsurance}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Link onClick={handleModifyReservationClick} className="c-btn btn-block mt-2">
                    Modify Reservation
                </Link>
            </>}

            <Modal show={showModifyReservationModal} onHide={handleOnModifyReservationModalClose} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <div className="modal-title">Room Modification Request Menu</div>
                </Modal.Header>
                <Modal.Body>
                    <ModifyReservation />
                </Modal.Body>
            </Modal>
        </div >
    );
};
export default GuestRoom;