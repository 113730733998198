import InsuranceImage from 'assets/images/icon-insurance.svg';
import CoreHelper from 'core/coreHelper';
import { useState } from 'react';

const travelGuardReferralLink =
  'https://www.travelguard.com/home?cmpid=kac-001-MK-TGUS-Google-Brand&mrkgadid=3200513130&mrkgcl=1151&mrkgen=gtext&mrkgbflag=1&mrkgcat=Brand_US&&acctid=21700000001697916&dskeywordid=43700051103102638&lid=43700051103102638&ds_s_kwgid=58700005603207548&device=c&network=g&matchtype=e&locationid=9060252&creative=585385103752&targetid=kwd-109853416&campaignid=796157898&adgroupid=48227796904&gclid=Cj0KCQjw-5y1BhC-ARIsAAM_oKkSa6yA_SqeDOHp2yqhhvDhaK9DtBgj7j3y9ZQTX5UiI3gxaWD7tGAaAtRSEALw_wcB&gclsrc=aw.ds';

const InsuranceQuotePrice = ({ price, showPrice }) => {
  if (showPrice) {
    return (
      <div className="text-bold">{CoreHelper.GetUsFormattedAmount(price)}</div>
    );
  }

  return <div>Click here for Insurance Quote Price</div>;
};

const InsuranceOptions = (props) => {
  const [selected, setSelected] = useState('no_insurance');
  const [showPrice, setShowPrice] = useState(false);
  const handleInsurancePurchaseClick = () => {
    window.open(travelGuardReferralLink, '_blank');
  };

  /*const shouldRedirect = useMemo(() => {
	  //check all permutations, cuz sure. why not.
    ['bol', 'bedsonline', 'bedonline'].includes(props.supplierCode?.toLowerCase());
  },[props.supplierCode])*/
  //Removed the useMemo as it was returning back as undefined inside handleSelect
  const shouldRedirect = ['bol', 'bedsonline', 'bedonline'].includes(
    props?.supplierCode?.toLowerCase()
  );
  const redirectUrl = shouldRedirect
    ? travelGuardReferralLink
    : 'https://destify.com/travel-protection-plus/';

  const insuranceElement = (
    <div className="d-flex flex-grow-1 justify-content-between align-items-center">
      <span>Purchase travel insurance now</span>
      <span>
        <InsuranceQuotePrice
          price={props.insurancePrice}
          showPrice={showPrice}
        />
      </span>
    </div>
  );

  const handleSelect = (checked) => (e) => {
    const name = e.target.name;
    if (selected === name) return;
    setSelected(name);
    if (checked) {
      shouldRedirect && handleInsurancePurchaseClick();
      setShowPrice(true);
    }
    props.setInsuranceChecked(checked);
  };

  const options = [
    {
      label: 'No, I would not like to insure my trip',
      checkValue: false,
      value: null,
      name: 'no_insurance',
    },
    {
      label: insuranceElement,
      checkValue: true,
      value: props.insurancePrice,
      name: 'insurance',
    },
  ];

  return (
    <div>
      <div className="c-payment-options">
        <div className="d-flex align-items-center payment-header">
          <img src={InsuranceImage} alt="Insurance Icon" />
          <h4>
            Add cancel for any reason travel insurance to your reservation
          </h4>
        </div>

        <div>
          <p>
            For more information on travel protection, please{' '}
            <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
              click here
            </a>
          </p>
        </div>

        {options.map((option, i) => {
          const id = `insurance-opt-${i + 1}`;
          return (
            <div className="payment-option" key={id}>
              <input
                type="radio"
                name={option.name}
                value={option.value || ''}
                id={id}
                onChange={handleSelect(option.checkValue)}
                checked={option.value === props.travelInsurance}
              />
              <label
                htmlFor={id}
                className="d-flex w-100 align-items-center justify-content-between"
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default InsuranceOptions;
