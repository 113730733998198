import React, { useState, useEffect } from "react";

import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  const [lists, setLists] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(undefined);
  useEffect(() => {
    setLists(props.lists);
  }, [props.lists]);

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
  };

  return (
    <div className="c-accordion">
      {lists &&
        lists.map((item, i) => (
          <AccordionItem
            key={i}
            data={item}
            index={i}
            selected={selectedIndex}
            handleClick={handleSelectedIndex}
          />
        ))}
    </div>
  );
};
export default Accordion;
