//import api from "utils/api";
//import Modal from 'react-bootstrap/Modal';
//import React, {UseEffect, UseState } from 'React';
import CoreHelper from "../../../../../../../core/coreHelper";
import TransferImage from "../../../../../../../assets/images/transfer.svg";

const TransferOptions = (props) => {
  return (
    <div>
      <div className="c-payment-options">
        <div className="d-flex align-items-center payment-header">
          <img src={TransferImage} alt="Insurance Icon" />
          <h4>Add transportation to and from your hotel</h4>
        </div>
        <div>
          {props.airportCode === "MBJ" && (
            <div className="text-left">
              <p>
                Transportation is available from Montego Bay Jamaica - Sangster
                International Airport (MBJ) only. We are unable to accommodate
                transportation from other airports.
              </p>
            </div>
          )}

          {props.airportCode === "PUJ" && (
            <div className="text-left">
              <p>
                Transportation is available from Punta Cana International
                Airport (PUJ) only. We are unable to accommodate transportation
                from other airports.
              </p>
            </div>
          )}
        </div>
        {props.TransferOptions !== null ? (
          <div>
            {props.TransferOptions.map((transfer, i) => {
              return (
                <span key={i}>
                  <div className="payment-option">
                    <input
                      type="radio"
                      name={`transfer-opt-1`}
                      id={transfer.transferTypeName}
                      value={transfer.travelZapTransferPrice}
                      onChange={props.toggleRecheck}
                      checked={
                        props.selectedTransferOption !== null &&
                        typeof props.selectedTransferOption !== "undefined" &&
                        transfer.travelZapTransferPrice ==
                          props.selectedTransferOption.travelZapTransferPrice
                      }
                    />
                    <label
                      className="d-flex w-100 align-items-center justify-content-between"
                      htmlFor={transfer.transferTypeName}
                    >
                      <span>{transfer.transferTypeLabel}</span>
                      <span className="payment-price">
                        {CoreHelper.GetUsFormattedAmount(
                          transfer.travelZapTransferPrice
                        )}
                      </span>
                    </label>
                  </div>
                </span>
              );
            })}
          </div>
        ) : (
          <div>
            <p>
              <strong>No transfers availabile for Hotel</strong>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransferOptions;
