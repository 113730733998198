
import api from "utils/api";
import React, { useState, useEffect } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import { ReactComponent as DownloadIcon } from "../../../../../assets/images/icon-download.svg";

const GuestList = (props) => {
    const [loader, setLoader] = useState(true);
    const [guestList, setGuestList] = useState({});

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            api.get('/api/guest/getGuestList')
                .then(response => {
                    setGuestList(response.data);
                    setLoader(false);
                }).catch(error => { setLoader(false); });
        })
    }, []);

    const renderNames = (names) => {
        return names.map((item, index) => {
            return (<span key={index}><span className={`d-block ${names.length - 1 == index ? 'mb-0' : 'mb-3'}`} key={index}>{item}</span></span>);
        })
    }

    const renderTable = () => {
        return guestList && guestList.guest && guestList.guest.map((item, index) => {
            return (<tr key={index}>
                <td style={{ padding: "25px 15px" }}>{renderNames(item.guestName)}</td>
                <td>{item.travelDate}</td>
                <td>{item.dateAdded}</td>
                <td>{item.nights}</td>
                <td style={{ width: '28%' }}>
                    <span className={`text-xs ${item.status + " status"}`}> {item.status}</span>
                </td>
            </tr>)
        });
    }

    return (
        <div className="w-100">
            {loader && <Loading />}
            {!loader &&
                <div>
                    <div className="d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-3 justify-content-between">
                        <h5 className="header">Guest List </h5>
                        <a href="#" className="c-download d-flex align-items-center">
                            <DownloadIcon /> Download List
                    </a>
                    </div>

                    <div className="list-header mb-5">
                        <div className="item">
                            <span className="number">3</span>
                            <span className="label">Total Rooms</span>
                        </div>
                        <div className="item">
                            <span className="number">14</span>
                            <span className="label">Total Nights</span>
                        </div>
                        <div className="item">
                            <span className="number">6</span>
                            <span className="label">Total Guests</span>
                        </div>
                        <div className="item">
                            <span className="number">5</span>
                            <span className="label">Adults</span>
                        </div>
                        <div className="item">
                            <span className="number">1</span>
                            <span className="label">Children</span>
                        </div>
                    </div>
                    <div className="c-table-responsive">
                        <table className="c-table table-bordered">
                            <thead>
                                <tr>
                                    <th>Guests in Room</th>
                                    <th>Travel Date</th>
                                    <th>Date Added</th>
                                    <th>Nights</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </table>
                    </div>
                </div>
            }

        </div>
    )
}

export default GuestList;