import moment from "moment";


export const getDateFormat = (date) => {
  return moment.utc(date).format("MMMM DD, YYYY");
};

export const getFullName = (firstName, middleName, lastName) => {
  const fullName = `${firstName} ${middleName ? middleName : ""} ${lastName}`;

  return <span>{fullName}</span>;
};