import React from 'react';
import { withRouter } from 'react-router-dom';
import Logo from '../../../components/Logo';

const Landing = () => {

    return (
     
            <div className="c-auth-screen">
                <div className="auth-content">
                    <div><Logo></Logo></div>
                    <div>To get started, visit your couple's wedding website, click Book Now, and then Reserve A Room. </div>
                    <br></br>
                    <div>You can also contact us via email: guestservices@destify.com</div>
                </div>
            </div>
      
    )
}

export default withRouter(Landing);
