import api from "utils/api";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/Loading";
import SubMainScreen from "../../../layouts/SubMainScreen";
import ReactPassword from "../../../components/PasswordField";

class ChangePasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmNewPassword: "",
      errors: {},
      loader: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = this.handleFormValidation(this.state);
    this.setState(
      {
        errors,
      },
      () => {
        if (Object.keys(errors).length === 0) {
          this.setState({ loader: true });
          const params = new URLSearchParams(this.props.location.search);
          const data = {
            password: this.state.newPassword,
            confirmPassword: this.state.confirmNewPassword,
            email: params.get("email"),
            token: params.get("token"),
          };
          api
            .post("/api/auth/resetPassword", data)
            .then((response) => {
              this.props.history.push("/forgot-password/post-confirmation");
            })
            .catch((error) => {
              //TODO:: replace with popup modal
              alert(error.message);
            })
            .finally(() => {
              this.setState({ loader: false });
            });
        }
      }
    );
  };

  handleFormValidation = () => {
    let errors = {};
    if (!this.state.newPassword) errors.newPassword = "Enter your new password";

    if (!this.state.confirmNewPassword) {
      errors.confirmNewPassword = "Re-Enter your new password";
    }

    if (this.state.newPassword !== this.state.confirmNewPassword) {
      errors.confirmNewPassword =
        "Mismatch detected. Both New Password and Confirm New Password fields must match";
    }
    return errors;
  };
  render() {
    const { errors } = this.state;
    return (
      <SubMainScreen>
        {this.state.loader && <Loading />}
        {!this.state.loader && (
          <>
            <div className="c-auth-screen">
              <div className="auth-content">
                {/* If they got to this screen, it would have been from password reset email
                and it would have opened in a new window/tab. Therefore, probably doesnt make sense to show a BackButton */}
                {/* <BackButton target="/guest/reservation/confirmation" /> */}
                <div className="auth-title">Change Password</div>

                <form className="c-form" onSubmit={this.handleFormSubmit}>
                  <ReactPassword
                    label="New Password"
                    name="newPassword"
                    placeholder="New Password"
                    error={errors && errors.newPassword}
                    onChange={this.handleInputChange}
                  />
                  <ReactPassword
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    error={errors && errors.confirmNewPassword}
                    onChange={this.handleInputChange}
                  />
                  <button className="c-btn" type="submit">
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </>
        )}
      </SubMainScreen>
    );
  }
}

export default withRouter(ChangePasswordScreen);
