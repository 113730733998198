import React from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as ReactLogo} from '../../assets/images/logo.svg';

const Logo = () => {
    return (
        <div className="c-logo">
           <ReactLogo/>
        </div>
    )
}
export default Logo;