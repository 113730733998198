import React, { useEffect } from "react";
import { useState } from "react";
import Logo from "../../components/Logo";
import AuthHelper from "../../core/authHelper";

const Navigation = () => {
  const [accountDropdownActive, setAccountDropdownActive] = useState(false);
  const [IsAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.user_info
  );
  const [emailAddress, setEmailAddress] = useState(null);

  const onAccountDropdownClick = () => {
    setAccountDropdownActive(!accountDropdownActive);
  };

  useEffect(() => {
    setIsAuthenticated(!!localStorage.user_info);
  });

  useEffect(() => {
    if (!emailAddress && localStorage.user_info?.length) {
      setEmailAddress( JSON.parse(localStorage.user_info).authenticatedEmail)
    }
  });

  return (
    <div className="sub-screen-header">
      <div className="row">
        <div className="col-md-3 col-s-12" align="center">
          <div className="content h-100">
            <Logo />
          </div>
        </div>
        <div className="col-md-9 col-s-12">
          {IsAuthenticated && (
            <div className="c-main-navbar__accounts  d-flex  align-items-center justify-content-end">
              <div className="c-header-account d-flex  align-items-center">
                <div className="username">{emailAddress}</div>
                <div className="account-dropdown">
                  <div className="initials" onClick={onAccountDropdownClick}>
                    {AuthHelper.GetAuthenticatedInitials()}
                  </div>

                  <div
                    className={`account-dropdown-content ${
                      accountDropdownActive ? "active" : ""
                    }`}
                  >
                    <div className="dropdown-item">
                      <div
                        className="logout c-btn c-btn--danger"
                        onClick={() => AuthHelper.Logout()}
                      >
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
