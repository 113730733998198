import React from "react";
import DatePicker from "react-datepicker";

import { ReservationHelper } from "core/reservationHelper";

import Constraints from "./Constraints";
import CalenderDay from "./CalenderDay";
import { useCheckMobileScreen } from "hooks";

const today = new Date();

const DateField = ({ weddingData, hookData }) => {
  const category = ReservationHelper.GetCategory() || "Wedding";
  const { minNights, weddingDate, weddingDateArray } = weddingData;
  const isMobile = useCheckMobileScreen();
  const {
    startDate,
    endDate,
    handleChangeDate,
    handleClear,
    totalDate,
    constraints,
  } = hookData;

  const onChange = (dates) => {
    //clear next steps
    const [start, end] = dates;
    handleChangeDate(start, end);
  };

  const [day, month, date, year] = weddingDateArray;

  return (
    <>
      <h4 className={`${isMobile ? "text-center" : ""}`}>
        Select your travel dates below to get started!
      </h4>
      <br />
      <Constraints constraints={constraints} />
      <div className="wedding-date">
        <div className="whitespace-nowrap label">
          {category === "Wedding" ? "Wedding" : "Event"} Date:
          <span className="date">
            {` ${day} ${month}`}
            <span className="day">{date?.replace(",", "")}</span>
            <span>{year}</span>
          </span>
        </div>
      </div>
      <br />
      <div className="c-date-picker-inline">
        <DatePicker
          selected={weddingDate}
          monthsShown={2}
          inline
          selectsRange
          showPreviousMonths
          startDate={startDate}
          endDate={endDate}
          onChange={onChange}
          minDate={today}
          renderDayContents={CalenderDay}
        />

        <div className="date-counter">
          <div className="total-dates">
            {!startDate && <span>Select Check-In dates</span>}
            {startDate && !endDate && <span>Select Check-Out dates</span>}
            {startDate && endDate && totalDate >= minNights && (
              <span className="number">{totalDate} Nights Selected</span>
            )}
          </div>
          <div className="clear-dates" onClick={handleClear}>
            Clear
          </div>
        </div>
      </div>
    </>
  );
};

export default DateField;
