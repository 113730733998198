import api from "utils/api";
import moment from 'moment';
import FlightForm from './form';
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Container } from "reactstrap";
import React, { useEffect, useState } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import CoreHelper from '../../../../../core/coreHelper';
import FlightImage from '../../../../../assets/images/flight.svg';

const GuestFlight = () => {
    const [loader, setLoader] = useState(true);
    const [returnInfo, setReturnInfo] = useState({});
    const [arrivalInfo, setArrivalInfo] = useState({});
    const [flightInfo, setFlightInfo] = useState(false);
    const [airportCode, setAirportCode] = useState(null);
    const [vacationtype, setVacationType] = useState('');
    const [responseBody, setResponseBody] = useState(null);
    const [responseTitle, setResponseTitle] = useState(null);
    const [showFlightModal, setShowFlightModal] = useState(false);
    const handleFlightModalClose = () => setShowFlightModal(false);
    const [flightModelTitle, setFlightModelTitle] = useState(null);
    const [showResponseModal, setShowResponseModal] = useState(false);
    const [loggedInTravelerId, setLoggedInTravelerId] = useState(null);
    const handleResponseModalClose = () => setShowResponseModal(false);

    useEffect(async () => initializeData(), []);

    const initializeData = () => {
        setLoader(true);
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
                Authhelper.GetAuthenticatedTravelerId().then(TRAVELER_ID => {
                    setLoggedInTravelerId(TRAVELER_ID);
                    if (typeof TRAVELER_ID !== "undefined" || TRAVELER_ID !== null) {
                        api.all([
                            api.get('/api/flight/getflightInformationByTravelerId?travelerId=' + TRAVELER_ID),
                            api.get('/api/room/getRoomDetailByEmail?email=' + AUTHENTICATED_EMAIL),
                            api.get('/api/resort/getResortDetailByRoomId?roomId=' + ROOM_ID),
                        ]).then(api.spread((flight, room, resort) => {
                            //room
                            setVacationType(room.data.roomInfo[0].vacationType);
                            if (flight.data !== "not available") {
                                setFlightInfo(true);

                                //prepare arrival flight info
                                let _arrivalInformation = flight.data.arrivalFlight[flight.data.arrivalFlight.length - 1];
                                let departureDateTimeArray = _arrivalInformation.departureDateTime.split('T');
                                _arrivalInformation.formattedDate = departureDateTimeArray[0];
                                _arrivalInformation.formattedTime = departureDateTimeArray[departureDateTimeArray.length - 1];
                                setArrivalInfo(_arrivalInformation);

                                //prepare departure flight info
                                let _departureInformation = flight.data.departureFlight[0];
                                let arrivalDateTimeArray = _departureInformation.arrivalDateTime.split('T');
                                _departureInformation.formattedDate = arrivalDateTimeArray[0];
                                _departureInformation.formattedTime = arrivalDateTimeArray[arrivalDateTimeArray.length - 1];
                                setReturnInfo(_departureInformation);
                            }

                            //resort
                            setAirportCode(resort.data.airportCode);

                            setLoader(false);
                        })).catch(e => { setLoader(false); })
                    }
                }).catch(e => { setLoader(false); })
            })
        })
    }

    const renderArrivalInformation = () => {
        return (
            <div>
                <h4 className="destination-header">Arrival to {airportCode}</h4>
                <div className="destination-list">
                    <div className="time-block">
                        <div className="date">{arrivalInfo.formattedDate} </div>
                        <div className="time">{CoreHelper.ConvertTimeFrom24To12Format(arrivalInfo.formattedTime)}</div>
                    </div>
                    <div className="destination-list-right">
                        <div className="destination-header">
                            <div>{arrivalInfo.airlineName} -  {arrivalInfo.airlineConfirmationNo}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const renderReturnInformation = () => {
        return (
            <div>
                <h4 className="destination-header">Return from {airportCode}</h4>
                <div className="destination-list">
                    <div className="time-block">
                        <div className="date">{returnInfo.formattedDate} </div>
                        <div className="time">{CoreHelper.ConvertTimeFrom24To12Format(returnInfo.formattedTime)}</div>
                    </div>
                    <div className="destination-list-right">
                        <div className="destination-header">
                            <div>{returnInfo.airlineName} -  {returnInfo.airlineConfirmationNo}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const handelFlightModelStepsCallback = (e) => setFlightModelTitle(e);

    const handelFlightModelResponseCallback = (apiResponse) => {
        initializeData();

        if (apiResponse === 'added') {
            setResponseTitle("Success.");
            setResponseBody("Flight information added successfully.");
        }
        if (apiResponse === 'updated') {
            setResponseTitle("Success.");
            setResponseBody("Flight information updated successfully.");
        }
        if (apiResponse === 'error') {
            setResponseTitle("Error !!");
            setResponseBody("Error occured. Please contact to the administrator.");
        }

        setShowFlightModal(false);
        setShowResponseModal(true);
    }

    const handleAddFlightInformation = () => {
        setShowFlightModal(true);
    }

    const handleModifyFlightInformation = () => {
        setShowFlightModal(true);
    }

    return (
        <div className="page-flight">
            {loader && <Loading />}
            {!loader && vacationtype !== "hotel" && <>
                {!loader && flightInfo && <>
                    <h5 className="header">Flight Information</h5>

                    <div className="destinations">
                        {arrivalInfo &&
                            <div className="destination-block">
                                {renderArrivalInformation()}
                            </div>
                        }
                        {returnInfo &&
                            <div className="destination-block">
                                {renderReturnInformation()}
                            </div>
                        }
                    </div>
                    { vacationtype === "hotelandtransfers" &&
                        <a className="c-btn btn-block mt-2" onClick={() => handleModifyFlightInformation(loggedInTravelerId)}>Modify Flight Information</a>
                    }
                </>}
                {
                    !loader && !flightInfo && vacationtype === "hotelandtransfers" && <div className="c-empty-content">
                        <Container fluid={true}>
                            <Row className="justify-content-center">
                                <Col lg="4">
                                    <div className="image">
                                        <img src={FlightImage} alt="Flight" />
                                    </div>
                                    <div className="text">
                                        <div className="text-title">No flight information</div>
                                        <div className="text-description">
                                            You have not added the flight information yet. Let’s do it now!
                                    </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg="6">
                                    <a className="c-btn btn-block mt-2" onClick={() => handleAddFlightInformation(loggedInTravelerId)}>Add Flight Information</a>
                                </Col>
                            </Row>
                        </Container>
                    </div>}
                {
                    !loader && !flightInfo && (vacationtype === "allinclusive" || vacationtype === "hotelandair") && <h5>Your flight information is not present currently. Please check back later</h5>
                }
            </>}

            {!loader && vacationtype === "hotel" && <><h4>Flight Information not available</h4></>}

            <Modal show={showFlightModal} onHide={handleFlightModalClose} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{flightModelTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlightForm arrivalInfo={arrivalInfo} returnInfo={returnInfo} travelerId={loggedInTravelerId} flightModelStepsCallback={handelFlightModelStepsCallback} flightModelResponseCallback={handelFlightModelResponseCallback} />
                </Modal.Body>
            </Modal>

            <Modal show={showResponseModal} onHide={handleResponseModalClose} backdrop="static" size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>{responseTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{responseBody}</p>
                    <button className="c-btn c-btn--black c-btn--lg" type="button" onClick={handleResponseModalClose}>Ok</button>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default GuestFlight;
