import React, { useEffect } from "react";
import { Col } from "reactstrap";
import Select from "react-select";

import ReactInput from "components/InputField";
import ReactInputDateField from "components/InputDateField";

import {
  filterParens,
  filterWhiteSpace,
  debounce,
  getChildAgeRange,
} from "./util";

import { DropDownStyleConstant } from "core/dropdownStyleConstant";
import { ReservationHelper } from "core/reservationHelper";

const genders = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const { maxDate, minDate } = getChildAgeRange();

const ChildForm = ({ childs, setChilds, childErrors, setChildErrors }) => {
  const updateChild = (index, key, value) => {
    setChilds((prev) => {
      return prev.map((child, id) => {
        if (id === index) {
          return {
            ...child,
            [key]: value,
          };
        }
        return child;
      });
    });
  };

  const resetError = (index, ...keys) => {
    setChildErrors((errors) => {
      return errors.map((error) => {
        if (error.index === index) {
          return {
            ...error,
            ...keys.reduce((acc, key) => ({ ...acc, [key]: "" }), {}),
          };
        }
        return error;
      });
    });
  };

  useEffect(() => {
    const handler = debounce(() => ReservationHelper.SetChilds(childs));
    return handler();
  }, [childs]);

  const handleChildFirstnameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    // set default child phone and email value as they are removed from UI.
    resetError(index, key, "childEmail", "childPhone");
    updateChild(index, key, filterParens(value));
  };

  const handleChildMiddlenameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    updateChild(index, key, filterParens(value));
  };

  const handleChildLastnameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    resetError(index, key);
    updateChild(index, key, filterParens(value));
  };

  const handleChildDOBChange = (e, index) => {
    const key = "childDob";
    const value = e;
    updateChild(index, key, value);
  };

  const handleChildGenderChange = (e, index) => {
    const key = "childGender";
    const value = e.value;
    resetError(index, key);
    updateChild(index, key, value);
  };

  return (
    <>
      {childs
        ? childs.map((child, i) => {
            return (
              <Col lg={childs.length > 3 ? 4 : 6} key={i}>
                <form className="c-form">
                  <h3>Child #{i + 1}</h3>
                  <ReactInput
                    value={child.childFirstName}
                    required
                    name="childFirstName"
                    label="First Name"
                    error={childErrors[i]?.childFirstName}
                    onChange={(e) => handleChildFirstnameChange(e, i)}
                  />
                  <ReactInput
                    value={child.childMiddleName}
                    required
                    name="childMiddleName"
                    label="Middle Name"
                    onChange={(e) => handleChildMiddlenameChange(e, i)}
                  />
                  <ReactInput
                    value={child.childLastName}
                    required
                    name="childLastName"
                    label="Last Name"
                    error={childErrors[i]?.childLastName}
                    onChange={(e) => handleChildLastnameChange(e, i)}
                  />
                  <ReactInputDateField
                    value={child.childDob}
                    name="childDob"
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    error={childErrors[i]?.childDob}
                    range={false}
                    onChange={(e) => handleChildDOBChange(e, i)}
                    maxDate={maxDate}
                    minDate={minDate}
                    disabled={true}
                  />
                  <div className="form-group">
                    <label>Gender</label>
                    <Select
                      value={{
                        value: `${child.childGender}`,
                        label: `${child.childGender ?? ""}`,
                      }}
                      styles={DropDownStyleConstant}
                      classNamePrefix="c-select"
                      options={genders}
                      placeholder="Select Gender"
                      onChange={(e) => handleChildGenderChange(e, i)}
                    />
                    {childErrors[i]?.childGender !== "" ? (
                      <p className="error">{childErrors[i]?.childGender}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </form>
              </Col>
            );
          })
        : null}
    </>
  );
};

export default ChildForm;
