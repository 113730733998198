import api from "utils/api";
import React from "react";
import SubMainScreen from "../../../../layouts/SubMainScreen";
import EnvelopeIcon from "../../../../assets/images/icon-envelope-success.svg";

const ForgetPasswordPreConfirmationScreen = () => {
  const onResendPasswordResetLink = () => {
    let email = localStorage.getItem("forget_password_email");
    let returnUrl = localStorage.getItem("forget_password_return_url");
    api.get(
      `/api/auth/createForgetPasswordToken?email=${encodeURIComponent(
        email
      )}&returnUrl=${encodeURIComponent(returnUrl)}`
    );
  };

  return (
    <SubMainScreen>
      <div className="c-auth-screen">
        <div className="auth-content">
          <div className="text-center mb-5">
            <img src={EnvelopeIcon} alt="Success" />
          </div>
          <div className="auth-title text-center">Information</div>
          <div className="description text-center ">
            {/* We've sent a password reset link to your email address. Please
            check. */}
            Please check your Inbox. We've sent password reset instructions to
            the email address you provided.
          </div>
          <div className="text-note text-center">
            Didn't get the email?{" "}
            <a href="#">
              <strong
                className="text-primary text-primary--hover"
                onClick={onResendPasswordResetLink}
              >
                Resend Password Reset Link
              </strong>
            </a>
          </div>
        </div>
      </div>
    </SubMainScreen>
  );
};

export default ForgetPasswordPreConfirmationScreen;
