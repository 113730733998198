import api from "utils/api";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { store } from "../../../../../../context/store";
import CoreHelper from "../../../../../../core/coreHelper";
import Loading from "../../../../../../components/Loading";
import Authhelper from "../../../../../../core/authHelper";
import React, { useContext, useEffect, useState } from "react";
import BedIcon from "../../../../../../assets/images/icon-bed.svg";
import BathIcon from "../../../../../../assets/images/icon-bath.svg";
import {
  ReservationHelper,
  ALGReservationExtensionHelper,
} from "../../../../../../core/reservationHelper";
import DirectionIcon from "../../../../../../assets/images/icon-direction.svg";
import ArrowLeftIcon from "../../../../../../assets/images/icon-chevron-left.svg";
import ArrowRightIcon from "../../../../../../assets/images/icon-chevron-right.svg";
import { ERROR_BOOKING_ROOMS_NOT_FOUND } from "../../../../../../core/globalConstant";
import { setTotalProgress } from "../../../../../../context/actions/reservationAction";
import { ReactComponent as PhotoIcon } from "../../../../../../assets/images/icon-photo.svg";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import {
  setDates,
  setNextReservationUrl,
  setRooms,
} from "../../../../../../context/actions/reservationAction";
import Modal from "react-bootstrap/Modal";
import renderHTML from "react-render-html";
import SelectedReservationRooms from "./selectedRoom";

const AvailableRooms = (props, setAdults, setChilds) => {
  const [state, dispatch] = useContext(store);
  const [loader, setLoader] = useState(true);
  const isIframeDetected = CoreHelper.IsIframeDetected();
  const [availableRooms, setAvailableRooms] = useState(null);
  const [totalNightsSelected, setTotalNightsSelected] = useState(
    ReservationHelper.GetTotalNight()
  );
  const [isPseudoBook, setIsPseudoBook] = useState(false);
  const [validateChildsMessage, setValidateChildsMessage] = useState(null);
  const [validatedChildsDobs, setValidatedChildsDobs] = useState(null);
  const [showSubModal, setShowSubModal] = useState(false);
  const handleOnSubModalClose = () => setShowSubModal(false);
  const [hotelInformationDictionary, sethotelInformationDictionary] =
    useState(null);
  const [roomSelectedForDetail, setRoomSelectedForDetail] = useState(null);

  useEffect(() => {
    const getAuthenticatedEmailAddress = async () => {
      const AUTHENTICATED_EMAIL =
        await Authhelper.GetAuthenticatedEmailAddress();
      let GROUP_ID = getGroupId();

      //get our hotel info, which will come from localStorage first, if it's there
      //else, it will run a bunch of ajax calls, and then store it into localStorage, to be retrieved on subsquent calls
      const HOTELS_INFORMATION =
        await ReservationHelper.GetHotelsInformationAsync(GROUP_ID);

      if (isIframeDetected)
        getAvailableRoomsForIFrame(AUTHENTICATED_EMAIL, GROUP_ID);
      else {
        //pass the hotel info into this function, so it doesnt' have to fetch it, itself
        getAvailableRooms2(AUTHENTICATED_EMAIL, GROUP_ID, HOTELS_INFORMATION);

        if (state.reservationState.rooms) {
          setTotalProgress(dispatch, 50);
        }
      }
    };
    getAuthenticatedEmailAddress();
  }, []);

  const getAvailableRooms2 = (
    AUTHENTICATED_EMAIL,
    GROUP_ID,
    HOTELS_INFORMATION
  ) => {
    api
      .all([
        api.get(
          `/api/wedding/getWeddingDetailByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
        ),
        api.get(
          `/api/resort/getResortDetailByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
        ),
        api.get(`/api/usergroup/getGroupInfoByGroupId?groupId=${GROUP_ID}`),
      ])
      .then(
        api.spread((wedding, resort, groupInfo) => {
          ReservationHelper.SetSupplierCode(resort.data.supplierCode);
          ReservationHelper.SetGroupVacationType(resort.data.groupVacationType);

          let _childDobs = [];
          let travelDate = ReservationHelper.GetStartDate();
          let nights = ReservationHelper.GetTotalNight();
          let _adults = ReservationHelper.GetAdults();
          let _childs = ReservationHelper.GetChilds();
          _childs.map((x) => {
            _childDobs.push(x.childDob);
          });

          let _algHotelCode = "";
          let _bolHotelCode = "";
          let _hbsiHotelCode = "";
          //sethotelInformationDictionary(_hotelsInfo);
          let _hotelsInformation = HOTELS_INFORMATION;

          sethotelInformationDictionary(_hotelsInformation);
          let _pricingType = groupInfo.data.pricingType;

          _hotelsInformation.forEach((x) => {
            x.algHotelCode !== null
              ? (_algHotelCode = _algHotelCode + `${x.algHotelCode},`)
              : (_algHotelCode = _algHotelCode);
            x.bolHotelCode !== null
              ? (_bolHotelCode = _bolHotelCode + `${x.bolHotelCode},`)
              : (_bolHotelCode = _bolHotelCode);
            x.hbsiHotelCode !== null
              ? (_hbsiHotelCode = _hbsiHotelCode + `${x.hbsiHotelCode},`)
              : (_hbsiHotelCode = _hbsiHotelCode);
          });

          // remove trailing comma
          _algHotelCode = _algHotelCode.slice(0, -1);
          _bolHotelCode = _bolHotelCode.slice(0, -1);
          _hbsiHotelCode = _hbsiHotelCode.slice(0, -1);
          // build _hbsiAvailRoomStayCandidate list right now 1 hotel
          let _hbsiAvailRoomstayCandidates = [
            {
              numAdults: _adults.length > 1 ? _adults.length : 1,
              childDobs: _childDobs,
              roomType: "*",
              roomNo: 1,
              promoCode: "",
              mealPlanCodes: "*",
            },
          ];
          //DWA-2020
          let selectedStartingTravelDate =
            travelDate ?? getCalculatedTravelDate(wedding.data.weddingDate);

          // build _availableRoomsRequestData for availability request
          let _availableRoomsRequestData = {
            promocode: "",
            nights: nights,
            destination: resort.data.airportCode,
            supplierCode: resort.data.supplierCode,
            algHotelCode: _algHotelCode,
            bolHotelCode: _bolHotelCode,
            hbsiHotelCode: _hbsiHotelCode,
            groupCommissionPercentage: resort.data.groupCommissionMultiplier,
            hotelChainCommissonPercentage:
              resort.data.resortCommissionMultiplier,
            travelDate: selectedStartingTravelDate,
            weddingDate: groupInfo.data.weddingDate,
            supplierCommissionList: resort.data.supplierCommissionList,
            preferredSupplier: resort.data.preferredSupplier,
            hotelsInfo: _hotelsInformation,
            availRoomStayCandidates: _hbsiAvailRoomstayCandidates,
            pricingType: _pricingType,
          };
          // post request
          api
            .post(
              `/api/reservation/getAvailableRooms2`,
              _availableRoomsRequestData
            )
            .then((response) => {
              setLoader(false);

              let _availableRooms = response.data.roomStays;

              _availableRooms.map((room) => {
                if (room.roomTypeContentDetailDTO === null) {
                  room.roomTypeContentDetailDTO = {
                    roomImages: {},
                    descriptions: "",
                    roomFeatures: "",
                  };
                  room.roomTypeContentDetailDTO.roomImages =
                    resort.data.images.map((image, i) => {
                      let imgItem = {
                        imageURL: image,
                        altTagText: "",
                        imageOrder: i,
                      };
                      return imgItem;
                    });
                }

                if (room.roomTypeContentDetailDTO.roomImages.length === 0) {
                  room.roomTypeContentDetailDTO.roomImages =
                    resort.data.images.map((image, i) => {
                      let imgItem = {
                        imageURL: image,
                        altTagText: "",
                        imageOrder: i,
                      };
                      return imgItem;
                    });
                }

                room.roomTypeContentDetailDTO.roomImages =
                  room.roomTypeContentDetailDTO.roomImages.sort((a, b) => {
                    return a.imageOrder - b.imageOrder;
                  });

                if (room.roomTypeContentDetailDTO.descriptions.length > 0) {
                  room.roomTypeContentDetailDTO.descriptions =
                    room.roomTypeContentDetailDTO.descriptions.replace('"', "");
                }
                return room;
              });
              let _valdatedDobs = response.data.roomStays[0].childDobs.map(
                (x) => x.split("T")[0]
              ); // take only the date component for validatedDobs
              let _preValidatedChilds = ReservationHelper.GetChilds();

              let _availableRoomsDictionary = groupByHotel(
                _availableRooms,
                "hotelName",
                _hotelsInformation
              );
              let _isPseudoBook = response.data.isPseudoBook;
              // default pseudoBook to false for now
              _isPseudoBook =
                _isPseudoBook === null || typeof _isPseudoBook === "undefined"
                  ? false
                  : _isPseudoBook;

              setAvailableRooms(_availableRoomsDictionary);
              ReservationHelper.SetAvailableRooms(_availableRooms);
              ReservationHelper.SetIsPseudoBook(_isPseudoBook);
              ReservationHelper.SetAvailableRoomsRequestData(
                _availableRoomsRequestData
              );
              ALGReservationExtensionHelper.SetALGInsuranceKey(
                response.data.algInsuranceKey
              );
              setIsPseudoBook(_isPseudoBook);
            })
            .catch((e) => {
              setLoader(false);
            });
        })
      )
      .catch((e) => {
        setLoader(false);
      });
  };

  const getAvailableRoomsForIFrame = (AUTHENTICATED_EMAIL, GROUP_ID) => {
    api
      .all([
        api.get(
          `/api/wedding/getWeddingDetailByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
        ),
        api.get(
          `/api/resort/getResortDetailByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
        ),
      ])
      .then(
        api.spread((wedding, resort) => {
          console.log("Loading rooms on iframe");
          let _nights = 5;
          setTotalNightsSelected(_nights);

          let _availableRoomsRequestData = {
            //promocode: resort.data.promoCode,
            nights: _nights,
            childAges: [],
            numAdults: 2,
            destination: resort.data.airportCode,
            supplierCode: resort.data.supplierCode,
            algHotelCode: resort.data.algHotelCode,
            bolHotelCode: resort.data.bolHotelCode,
            groupVacationType: resort.data.groupVacationType,
            groupCommissionPercentage: resort.data.groupCommissionMultiplier,
            hotelChainCommissonPercentage:
              resort.data.resortCommissionMultiplier,
            travelDate: getCalculatedTravelDate(wedding.data.weddingDate),
          };
          api
            .post(
              `/api/reservation/getAvailableRooms`,
              _availableRoomsRequestData
            )
            .then((response) => {
              setLoader(false);
              setAvailableRooms(response.data.rooms);
              console.log("Rooms loaded on iframe");
            })
            .catch((e) => {
              setLoader(false);
              console.log("Error occured while loading on iframe");
            });
        })
      )
      .catch((e) => {
        setLoader(false);
      });
  };

  const groupByHotel = (list, itemKey, _hotelInformationDictionary) => {
    const aLGSupplier = ["funjet", "apple", "united", "southwest", "alg"];
    const map = new Map();
    const dictionary = {};
    list.forEach((item) => {
      let key = "";

      if (aLGSupplier.includes(item.supplierCode)) {
        key = _hotelInformationDictionary
          .find((x) => x.crmHotelId == item.crmHotelCode)
          .hotelName.trim();
      } else if (item.supplierCode == "hbsi") {
        key = _hotelInformationDictionary
          .find((x) => x.crmHotelId == item.crmHotelCode)
          .hotelName.trim();
      } else if (item.pricingType == "static_pricing") {
        key = _hotelInformationDictionary
          .find((x) => x.crmHotelId == item.crmHotelCode)
          .hotelName.trim();
      } else {
        key = _hotelInformationDictionary
          .find((x) => x.crmHotelId == item.crmHotelCode)
          .hotelName.trim();
      }

      if (typeof dictionary[key] === "undefined") {
        dictionary[key] = [];
      }

      dictionary[key].push(item);
    });

    return dictionary;
  };

  const handleRoomSelect = (e, selectedRoom) => {
    //clear next steps
    clearNextSteps();
    let _hotelsInfo = hotelInformationDictionary;
    let _CRMHotelInfo = "";
    _CRMHotelInfo = _hotelsInfo.find(
      (x) => x.crmHotelId == selectedRoom.crmHotelCode
    );
    selectedRoom.address = _CRMHotelInfo.address;
    ReservationHelper.SetSelectedRoom(selectedRoom);
    ReservationHelper.SetSupplierCode(selectedRoom.supplierCode);
    ReservationHelper.SetHotelCode(selectedRoom.hotelCode);
    ReservationHelper.SetCRMHotelId(selectedRoom.crmHotelCode);
    ReservationHelper.SetHotelName(selectedRoom.hotelName);
    setTotalProgress(dispatch, 50);
    //ALGReservationExtensionHelper.SetALGTransferPrice(selectedRoom.transferPrice);
    let enableDestifyDashboard = ReservationHelper.GetEnableDashboardFlow();
    props.history.push("/guest/reservation/room-arrangements");
  };

  const getCalculatedTravelDate = (weddingDate) => {
    //subtract 2 days from wedding date
    let calculatedTravelDate = new Date(weddingDate);
    calculatedTravelDate.setDate(calculatedTravelDate.getDate() - 2);

    let dd = calculatedTravelDate.getDate();
    let mm = calculatedTravelDate.getMonth() + 1;
    let y = calculatedTravelDate.getFullYear();
    let dateString = `${mm}/${dd}/${y}`;
    return new Date(dateString);
  };

  const getRoomDetailLink = (linkText, room) => {
    return (
      <button
        className="btn btn-link text-left text-bold"
        style={{ padding: "none" }}
        onClick={() => setRoomSelectedForDetail(room)}
      >
        {linkText}
      </button>
    );
  };

  const getGroupId = () => {
    let _groupId = props?.location?.search?.split("=")[1];
    _groupId = typeof _groupId === "undefined" ? "" : _groupId;

    if (
      _groupId === null ||
      typeof _groupId === "undefined" ||
      _groupId.length === 0
    ) {
      _groupId = ReservationHelper.GetGroupId();
    }
    return _groupId !== null && typeof _groupId !== "undefined" ? _groupId : "";
  };

  const handleAddAdult = (_adultDbo) => {
    //add adult
    let adults = ReservationHelper.GetAdults();
    adults.push({
      adultFirstName: null,
      adultLastName: null,
      adultEmail: null,
      adultPhone: null,
      adultDob: _adultDbo,
      adultGender: "",
    });

    ReservationHelper.SetAdults(adults);
    ReservationHelper.SetAdultsCount(adults.length);
    props.setAdults(adults);
  };

  const clearNextSteps = () => {
    ReservationHelper.SetChildCount(0);
    ReservationHelper.SetAdultsCount(0);
    ReservationHelper.SetSelectedPaymentAmount(0);
  };

  const handleRequestRoomSelect = (e) => {
    clearNextSteps();
    ReservationHelper.SetSelectedRoom({ room: 0 });
    setTotalProgress(dispatch, 50);
    setNextReservationUrl(dispatch, "/guest/reservation/request-room");
    props.history.push("/guest/reservation/request-room");
  };

  const resortName = availableRooms ? Object.keys(availableRooms)[0] : "";

  const availableRoomsArray = availableRooms
    ? Object.entries(availableRooms)
        .sort(([, a], [, b]) => {
          return (
            hotelInformationDictionary[0].crmHotelsOrder.indexOf(
              a[0].crmHotelCode
            ) -
            hotelInformationDictionary[0].crmHotelsOrder.indexOf(
              b[0].crmHotelCode
            )
          );
        })
        .map(([key, value]) => {
          return value;
        })[0]
    : [];

  const renderRows = () => {
    if (availableRooms && Object.entries(availableRooms).length > 0) {
      return Object.entries(availableRooms)
        .sort(([, a], [, b]) => {
          return (
            hotelInformationDictionary[0].crmHotelsOrder.indexOf(
              a[0].crmHotelCode
            ) -
            hotelInformationDictionary[0].crmHotelsOrder.indexOf(
              b[0].crmHotelCode
            )
          );
        })
        .map(([key, value], x) => {
          return (
            <React.Fragment key={x}>
              <h3>{key}</h3>
              <Modal
                show={roomSelectedForDetail !== null}
                onHide={() => {
                  setRoomSelectedForDetail(null);
                }}
              >
                <Modal.Header closeButton>
                  <div className="modal-title">
                    {roomSelectedForDetail !== null &&
                      roomSelectedForDetail.roomName}
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <SelectedReservationRooms
                    selectedRoom={roomSelectedForDetail}
                    {...props}
                    totalNightsSelected={totalNightsSelected}
                  />
                </Modal.Body>
              </Modal>
              {value.map((roomDetail, i) => {
                return (
                  <div
                    className={
                      roomDetail.selected
                        ? "c-room-card c-room-card-selected"
                        : "c-room-card"
                    }
                    key={i}
                  >
                    <Row>
                      <Col md={5} lg={5} xl={3}>
                        <div className="c-photo-block mx-auto lg:ml-0">
                          <div className="photo">
                            {roomDetail.roomTypeContentDetailDTO.roomImages && (
                              <CarouselProvider
                                naturalSlideWidth={100}
                                naturalSlideHeight={125}
                                totalSlides={
                                  roomDetail.roomTypeContentDetailDTO.roomImages
                                    ?.length
                                }
                                isIntrinsicHeight={true}
                                isPlaying={false}
                              >
                                <Slider>
                                  {roomDetail.roomTypeContentDetailDTO.roomImages.map(
                                    (item, i) => {
                                      return (
                                        <Slide index={i} key={i}>
                                          <div className="slide-item-wrapper">
                                            {" "}
                                            <img
                                              src={item.imageURL}
                                              alt={item.altTagText}
                                            />
                                          </div>
                                        </Slide>
                                      );
                                    }
                                  )}
                                </Slider>

                                {!isIframeDetected && (
                                  <div>
                                    <ButtonBack className="carouselButton">
                                      <img src={ArrowLeftIcon} alt="Back" />
                                    </ButtonBack>
                                    <ButtonNext className="carouselButton">
                                      <img src={ArrowRightIcon} alt="Next" />
                                    </ButtonNext>
                                  </div>
                                )}
                              </CarouselProvider>
                            )}
                          </div>
                          <div className="icon">
                            {getRoomDetailLink(<PhotoIcon />, roomDetail)}
                          </div>
                        </div>
                      </Col>

                      <Col md={7} lg={7} xl={9}>
                        <div className="text">
                          <h3 className="room-title text-center text-md-left">
                            {roomDetail.roomTypeContentDetailDTO.isBestValue
                              ? "Destify's Favorite"
                              : ""}
                            {getRoomDetailLink(
                              `${roomDetail.roomName} - ${roomDetail.hotelName}`,

                              roomDetail
                            )}
                          </h3>
                          <div className="room-features">
                            {roomDetail.roomBed && (
                              <div className="item">
                                <div className="feature-icon">
                                  <img src={BedIcon} alt={roomDetail.beds} />
                                </div>
                                <div className="feature-name">
                                  {roomDetail.roomBed}
                                </div>
                              </div>
                            )}

                            {roomDetail.bathTube && (
                              <div className="item">
                                <div className="feature-icon">
                                  <img src={BathIcon} />
                                </div>
                                <div className="feature-name">
                                  {roomDetail.bathTube}
                                </div>
                              </div>
                            )}

                            {roomDetail.roomSize && (
                              <div className="item">
                                <div className="feature-icon">
                                  <img src={DirectionIcon} />
                                </div>
                                <div className="feature-name">
                                  {roomDetail.roomSize}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="room-description">
                            {roomDetail.roomTypeContentDetailDTO.descriptions
                              ?.length > 78 ? (
                              <>
                                {" "}
                                {renderHTML(
                                  roomDetail.roomTypeContentDetailDTO.descriptions?.substring(
                                    0,
                                    78
                                  )
                                )}
                                {getRoomDetailLink("... Read More", roomDetail)}
                              </>
                            ) : (
                              renderHTML(
                                roomDetail.roomTypeContentDetailDTO.descriptions
                              )
                            )}
                          </div>

                          <div className="room-footer d-flex flex-column-reverse flex-md-row align-items-center align-items-md-end">
                            {roomDetail.isSoldOut ? (
                              <>
                                <div className="button">
                                  <a
                                    onClick={(e) =>
                                      handleRoomSelect(e, roomDetail)
                                    }
                                    className={`c-btn c-btn-disabled ${
                                      !roomDetail.selected
                                        ? "c-btn--ghost c-btn--ghost__primary"
                                        : ""
                                    }`}
                                  >
                                    {roomDetail.selected
                                      ? "SOLD OUT"
                                      : "SOLD OUT"}
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="button">
                                  <a
                                    onClick={(e) =>
                                      handleRoomSelect(e, roomDetail)
                                    }
                                    className={`c-btn ${
                                      !roomDetail.selected
                                        ? "c-btn--ghost c-btn--ghost__primary"
                                        : ""
                                    }`}
                                  >
                                    {roomDetail.selected
                                      ? "Selected"
                                      : "Select Room"}
                                  </a>
                                </div>
                              </>
                            )}

                            {roomDetail.isSoldOut ? (
                              <>
                                <div className="room-price">
                                  <div className="price-section">
                                    <div className="per-night">
                                      <div className="number">SOLD OUT</div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="room-price">
                                  <div className="price-section">
                                    <div className="per-night">
                                      <div className="number">
                                        {CoreHelper.GetUsFormattedAmount(
                                          roomDetail.pricePerNight
                                        )}
                                      </div>
                                      <label> per night </label>
                                    </div>
                                    <br />
                                    <div className="total">
                                      <strong>
                                        {CoreHelper.GetUsFormattedAmount(
                                          roomDetail.travelZapHotelPrice
                                        )}{" "}
                                        for {totalNightsSelected} nights
                                      </strong>
                                    </div>
                                    <div>(Includes taxes and fees)</div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ); // end of render
              })}
            </React.Fragment>
          );
        });
    } else {
      return (
        <div>
          {ERROR_BOOKING_ROOMS_NOT_FOUND}
          <br />
          <p>
            <strong>
              Not finding the room package that you were looking for? Contact
              our Guest Service Team to help you book a room.
            </strong>
          </p>
          <br />
          <div className="button">
            <a
              onClick={handleRequestRoomSelect}
              className="c-btn c-btn--ghost c-btn--ghost__primary"
            >
              Request a room
            </a>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="c-room-cards">
      {loader && (
        <>
          <Loading />{" "}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "-3rem",
            }}
          >
            <p style={{ marginBottom: 0 }}>
              Gathering available rooms that match your request.
            </p>
            <p style={{ marginBottom: 0 }}>
              Please allow up to 90 seconds for us to gather results.
            </p>
            <p style={{ marginBottom: 0 }}>Thanks!</p>
          </div>
        </>
      )}

      <Modal
        show={showSubModal}
        onHide={handleOnSubModalClose}
        backdrop="static"
        size="md"
      >
        <Modal.Header closeButton>
          <div className="modal-title">
            Child will be adult at time of Travel
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div>{validateChildsMessage}</div>
            <br />
            {validateChildsMessage != null
              ? validatedChildsDobs.map((x) => <p>&emsp;{x}</p>)
              : ""}
          </div>
        </Modal.Body>
      </Modal>
      {!loader && renderRows()}
    </div>
  );
};
export default AvailableRooms;
