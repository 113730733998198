import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const Loading = (props) => {
    return (
        <>
            <div className="c-loading">
                <div className="spinner-block">
                    <div className="spinner spinner-1"></div>
                </div>                
            </div>
            <div className="c-loading" style={{minHeight: "0px"}}>{typeof props.loadingText == "undefined" && props.loadingText ? "" : props.loadingText}</div>
        </>
  );
};

export default Loading;
