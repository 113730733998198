const Constraint = ({ message }) => {
  return <p className="text-danger">{message}</p>;
};

const Constraints = ({ constraints }) => {
  return (
    <div
      style={{
        minHeight: `calc(${constraints.length} * calc(1.5em + 1rem))`,
      }}
    >
      {constraints.map((constraint) => {
        if (!constraint.isValid) {
          return (
            <Constraint
              key={constraint.message.concat(constraint.isValid)}
              message={constraint.message}
            />
          );
        } else {
          const error =
            (constraint.children &&
              constraint.children.find((constraint) => !constraint.isValid)) ||
            null;
          if (error) {
            return (
              <Constraint
                key={constraint.message.concat(constraint.isValid)}
                message={error.message}
              />
            );
          }
          return null;
        }
      })}
    </div>
  );
};

export default Constraints;
