import CoreHelper from "core/coreHelper";
import { ReservationHelper } from "core/reservationHelper";
import { EMAIL_VALIDATION_REGEX } from "core/globalConstant";
const DUPLICATE_NAME_ERROR_TEXT =
  "There are duplicate passenger names in this reservation. Please add a middle name OR add the appropriate suffix (Sr, Jr, III) after last name and resubmit your request. Thank you!";
  
const blockedWordsForName = ["tbd"];
const isBlockedForName = (value) => blockedWordsForName.includes(value?.toLowerCase());

export const filterParens = (str) => {
  return (str ?? "").replace(/[()]+/g, "");
};

export const filterWhiteSpace = (str) => {
  return (str ?? "").replace(/[\s]+/g, "");
};

export const debounce =
  (callback, delay = 1) =>
    (...args) => {
      const timer = setTimeout(() => {
        callback(...args);
      }, delay * 1000);
      return () => clearTimeout(timer);
    };

export const getAdultAgeRange = () => {
  const date = new Date();
  const _maxDate = new Date(
    date.getFullYear() - 18,
    date.getMonth(),
    date.getDate()
  );
  const _adultMinDate = new Date(
    date.getFullYear() - 120,
    date.getMonth(),
    date.getDate()
  );

  const maxDate = _maxDate.toISOString().split("T")[0];
  const minDate = _adultMinDate.toISOString().split("T")[0];

  return {
    maxDate,
    minDate,
  };
};

export const getChildAgeRange = () => {
  const _maxDate = new Date();
  const _minDate = new Date(
    _maxDate.getFullYear() - 18,
    _maxDate.getMonth(),
    _maxDate.getDate()
  );
  const maxDate = _maxDate.toISOString().split("T")[0];
  const minDate = _minDate.toISOString().split("T")[0];
  return {
    minDate,
    maxDate,
  };
};

export const validateChildForm = (children, _dupNames) => {
  const validationErrors = children.map((item, index) => {
    let validationError = {};
    validationError.index = index;
    if (!item.childFirstName) {
      validationError.childFirstName = "First Name is required";
    }
    if (!item.childLastName) {
      validationError.childLastName = "Last Name is required";
    }
    // check for duplicate names
    if (_dupNames?.length) {
      const _fullname = `${(item.childFirstName || "").toLowerCase()}${(item.childMiddleName || "").toLowerCase()}${(item.childLastName || "").toLowerCase()}`;
      if (_dupNames.includes(_fullname)) {
        validationError.childFirstName = DUPLICATE_NAME_ERROR_TEXT;
        validationError.childMiddleName = DUPLICATE_NAME_ERROR_TEXT;
        validationError.childLastName = DUPLICATE_NAME_ERROR_TEXT;
      }
    }

    if (isBlockedForName(item.childFirstName)) {
      validationError.childFirstName = `${item.childFirstName} not allowed as First Name`;
    }
    if (isBlockedForName(item.childLastName)) {
      validationError.childLastName =  `${item.childLastName} not allowed as Last Name`;
    }

    if (!item.childDob) {
      validationError.childDob = "Date of Birth is required";
    }
    if (!item.childGender) {
      validationError.childGender = "Gender is required";
    }

    return validationError;
  });
  return validationErrors;
};

const concat = (...names) => {
  return names.join(" ").replace(/\s+/g, " ");
};

export const validateAdultForm = (adults, _dupNames) => {
  const { minDate } = getAdultAgeRange();
  const _minDateString = new Date(minDate).toLocaleString().split(",")[0];
  const travelDate = ReservationHelper.GetTravelDate();
  const _adultAtTravelDateDOB = CoreHelper.GetValid18AtTravelDate(travelDate);
  const emailRegex = EMAIL_VALIDATION_REGEX;

  const validationErrors = adults.map((item, index) => {
    let validationError = {};
    validationError.index = index;

    if (!item.adultFirstName) {
      validationError.adultFirstName = "First Name is required";
    }

    if (!item.adultLastName) {
      validationError.adultLastName = "Last Name is required";
    }

    // check for duplicate names
    if (_dupNames?.length) {
      const _fullname = `${(item.adultFirstName || "").toLowerCase()}${(item.adultMiddleName || "").toLowerCase()}${(item.adultLastName || "").toLowerCase()}`;
      if (_dupNames.includes(_fullname)) {
        validationError.adultFirstName = DUPLICATE_NAME_ERROR_TEXT;
        validationError.adultMiddleName = DUPLICATE_NAME_ERROR_TEXT;
        validationError.adultLastName = DUPLICATE_NAME_ERROR_TEXT;
      }
    }

    if (isBlockedForName(item.adultFirstName)) {
      validationError.adultFirstName = `${item.adultFirstName} not allowed as First Name`;
    }

    if (isBlockedForName(item.adultLastName)) {
      validationError.adultLastName = `${item.adultLastName} not allowed as Last Name`;
    }

    if (!item.adultEmail) {
      validationError.adultEmail = "Email is required";
    } else if (!emailRegex.test(item.adultEmail)) {
      validationError.adultEmail = "Invalid email address";
    }

    const adultTravelerPhoneNumber = CoreHelper.GetPhoneNoWithoutCountryCode(
      item.adultPhone
    );
    if (!adultTravelerPhoneNumber) {
      validationError.adultPhone = "Phone Number is required";
    } else if (adultTravelerPhoneNumber.length < 10) {
      validationError.adultPhone =
        "Invalid Phone Number. Must be between 10-14 digits, including area code";
    }

    if (!item.adultDob) {
      validationError.adultDob = "Date of Birth is required";
    } else {
      if (item.adultDob < minDate) {
        validationError.adultDob = `DOB must be after ${_minDateString}`;
      } else {
        if (item.adultDob > _adultAtTravelDateDOB) {
          // minimum date for adult age
          validationError.adultDob = `DOB must be before ${_adultAtTravelDateDOB}`;
        }
      }
    }

    if (!item.adultGender) {
      validationError.adultGender = "Gender is required";
    }
    return validationError;
  });
  return validationErrors;
};
