import { Row, Col } from 'reactstrap';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactInput from '../../../../../components/InputField';
import ReactInputTimeField from '../../../../../components/InputTimeField';
import ReactInputDateField from '../../../../../components/InputDateField';

const FlightArrival = (props) => {
    const [errors, setErrors] = useState({});
    const [inputs, setInputs] = useState(props.arrivalFlightDetail);

    const handelDateChange = (x) => {
        let _formattedDate = `${x.getFullYear()}/${x.getMonth() + 1}/${x.getDate()}`;
        setInputs(inputs => ({ ...inputs, formattedDate: _formattedDate }));
        setInputs(inputs => ({ ...inputs, departureDateTime: x }));
    }

    const handelTimeChange = (x) => {
        setInputs(inputs => ({ ...inputs, formattedTime: x }));
    }

    const handleInputChange = (e) => {
        e.persist();
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    }

    const handleArrivalValidation = () => {
        let errors = {};
        if (!inputs.airlineName) errors.airlineName = "Airline name is required";
        if (!inputs.formattedDate) errors.formattedDate = "Departure date is required";
        if (!inputs.formattedTime) errors.formattedTime = "Departure time is required";
        if (!inputs.airlineConfirmationNo) errors.airlineConfirmationNo = "Airline confirmation number is required";
        setErrors(errors);
        return errors;
    }

    const handleArrivalSave = (e) => {
        e.preventDefault();
        const errors = handleArrivalValidation();

        if (Object.keys(errors).length === 0) {
            props.arrivalCallback(inputs);
        }
        else return false;
    }

    return (
        <div>
            <Row>
                <Col lg="12" xl="12">
                    <form className="c-form" onSubmit={handleArrivalSave}>
                        <ReactInput value={inputs.airlineName} type="text" label="Airline Name" name="airlineName" error={errors && errors.airlineName} onChange={handleInputChange} />
                        <ReactInput value={inputs.airlineConfirmationNo} type="text" label="Airline Confirmation Number" name="airlineConfirmationNo" error={errors && errors.airlineConfirmationNo} onChange={handleInputChange} />
                        <ReactInputDateField value={inputs.formattedDate} name="formattedDate" label="Arrival Date" placeholder="Select Date" range={false} error={errors && errors.formattedDate} onChange={handelDateChange} />
                        <ReactInputTimeField value={inputs.formattedTime} name="formattedTime" label="Arrival Time" placeholder="Select Time" range={false} error={errors && errors.formattedTime} onChange={handelTimeChange} />
                        <div className="d-flex justify-content-center">
                            <button type="submit" className="c-btn c-btn--ghost c-btn--ghost__primary">Next</button>
                        </div>
                    </form>
                </Col>
            </Row>
        </div>
    );
}
export default withRouter(FlightArrival);