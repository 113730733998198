import React from 'react';
import {Link} from 'react-router-dom';
import Close from '../../assets/images/icon-close.svg';
const CloseButton = ({target,onClick}) => {
    return (
    
    <Link to={target} className="c-close" onClick={onClick}>
        <img src={Close} alt="close" />
        Close
    </Link>);
}
export default CloseButton