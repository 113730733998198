import api from "utils/api";
import UserForm from './form';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react';
import Loading from "../../../../../components/Loading";

const UsersManagement = () => {
    const [loader, setLoader] = useState(true);
    const [userList, setUserList] = useState([]);
    const [modalTitle, setModaltitle] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [apiResponseMessageBody, setApiResponseMessageBody] = useState(null);
    const [apiResponseMessageTitle, setApiResponseMessageTitle] = useState(null);
    const [activateDeactivateUserModalBody, setActivateDeactivateUserModalBody] = useState(null);

    const [showUserFormModal, setShowUserFormModal] = useState(false);
    const handleOnCloseUserFormModal = () => setShowUserFormModal(false);

    const [showActivateDeactivateModal, setShowActivateDeactivateModal] = useState(false);
    const handleOnCloseActivateDeactivateModal = () => setShowActivateDeactivateModal(false);

    const [showApiResponseModal, setShowApiResponseModal] = useState(false);
    const handleOnCloseApiResponseModal = () => setShowApiResponseModal(false);


    useEffect(async () => {
        _getUsers();
    }, []);

    const _getUsers = () => {
        api.get('/api/manageuser/listGuestUsers').then(response => {
            setUserList(response.data);
            setLoader(false);
        }).catch(error => { setLoader(false); });
    }

    const handelAddNewUserClick = () => {
        setSelectedUser(null);
        setModaltitle("Add New User");
        setShowUserFormModal(true);
    }

    const handelEditUserClick = (user) => {
        setSelectedUser(user);
        setModaltitle("Edit User");
        setShowUserFormModal(true);
    }

    const handelUserFormCallback = (title, body) => {
        setShowUserFormModal(false);
        setApiResponseMessageTitle(title);
        setApiResponseMessageBody(body);
        setShowApiResponseModal(true);
        _getUsers();
    }

    const handelActivateDeactivateClick = (user) => {
        setSelectedUser(user);
        if (user.isActive) {
            setModaltitle("Deactivate User");
            setActivateDeactivateUserModalBody(`Are you sure want to deactivate the user ${user.firstName} ${user.lastName}?`)
        } else {
            setModaltitle("Activate User");
            setActivateDeactivateUserModalBody(`Are you sure want to activate the user ${user.firstName} ${user.lastName}?`)
        }
        setShowActivateDeactivateModal(true);
    }

    const handelUserActivateDeactivateConfirm = () => {
        let _url = null;
        if (selectedUser.isActive)
            _url = `/api/manageuser/deactivateUser?email=${selectedUser.email}`;
        if (!selectedUser.isActive)
            _url = `/api/manageuser/activateUser?email=${selectedUser.email}`;

        if (_url !== null) {
            api.put(_url).then(e => {
                if (e.status === 200) {
                    userList.map(user => {
                        return user.isActive = user.email ===
                            selectedUser.email ? !selectedUser.isActive : user.isActive;
                    })
                }
                setShowActivateDeactivateModal(false);
            }).catch(error => { setShowActivateDeactivateModal(false) });
        }
    }

    return (
        <div className="page-wedding">
            {loader && <Loading />}
            {!loader && userList && <>
                <a className="c-btn c-btn-small" onClick={() => handelAddNewUserClick()}>New Users</a>
                <br />
                <Table responsive="lg">
                    <thead>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email Address</th>
                            <th>Phone Number</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            userList.map((user, index) => {
                                return (<tr key={index}>
                                    <td>{user.firstName}</td>
                                    <td>{user.lastName}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phoneNo}</td>
                                    <td>
                                        <a className="c-btn c-btn-small" onClick={() => handelEditUserClick(user)}>Edit</a>
                                        {user.isActive ?
                                            <a className="c-btn c-btn-small c-btn--danger" onClick={() => handelActivateDeactivateClick(user)}>Deactivate</a>
                                            :
                                            <a className="c-btn c-btn-small" onClick={() => handelActivateDeactivateClick(user)}>Activate</a>
                                        }
                                    </td>
                                </tr>)
                            })
                        }
                    </tbody>
                </Table>
            </>}

            {/* add/edit user form modal*/}
            <Modal show={showUserFormModal} onHide={handleOnCloseUserFormModal} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <UserForm user={selectedUser} callback={handelUserFormCallback} />
                </Modal.Body>
            </Modal>

            {/* activate/deactivate user modal */}
            <Modal show={showActivateDeactivateModal} onHide={handleOnCloseActivateDeactivateModal} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{activateDeactivateUserModalBody}</p>
                    <div className="d-flex">
                        <button className="c-btn c-btn--ghost c-btn--ghost__primary" type="button" onClick={handleOnCloseActivateDeactivateModal}>Cancel</button>
                        <button style={{ marginLeft: '10px' }} className="c-btn c-btn--ghost c-btn--ghost__primary" type="button" onClick={handelUserActivateDeactivateConfirm}>Confirm</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* api response modal */}
            <Modal show={showApiResponseModal} onHide={handleOnCloseApiResponseModal} backdrop="static" size="md">
                <Modal.Header closeButton>
                    <Modal.Title>{apiResponseMessageTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: "center" }}>
                        <p>{apiResponseMessageBody}</p>
                        <button className="c-btn c-btn--ghost c-btn--ghost__primary" type="button" onClick={handleOnCloseApiResponseModal}>Ok</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default UsersManagement;
