import React, { useEffect, useState, Component } from "react";
import api from "utils/api";
import { Link } from "react-router-dom";
import { FormGroup, Input, Label } from "reactstrap";
import GoogleLogin from "react-google-login";
import Authhelper from "../../../../../../core/authHelper";
import Loading from "../../../../../../components/Loading";
import IconButton from "../../../../../../components/IconButton";
import GoogleIcon from "../../../../../../assets/images/icon-google.svg";
import EmailIcon from "../../../../../../assets/images/icon-envelope.svg";

import PhoneField from "../../../../../../components/PhoneField";
import ReactInput from "../../../../../../components/InputField";
import ReactPassword from "../../../../../../components/PasswordField";
import { ReservationHelper } from "../../../../../../core/reservationHelper";
import LoginFormScreen from "../../login/loginFormScreen";
import { EMAIL_VALIDATION_REGEX, ERROR_GENERAL } from 'core/globalConstant';

import FacebookIcon from "../../../../../../assets/images/icon-facebook.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

class SignupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            errors: {},
            loader: false,
            loginError: false,
            signupErrorMessage: null,
            queryParams: new URLSearchParams(window.location.search),
            destifyWebAppUrl: props.destifyWebAppUrl,
            allowMessaging: false,
        };
    }

    handlePhoneInputChange = (e) => this.setState({ phone: e });

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleCheckbox = (e) => this.setState({ [e.target.name]: e.target.checked });

    setError = (error) => {
        console.log({ error });
        if (error?.response?.status == 400)
            this.setState({
                loader: false,
                loginError: true,
                signupErrorMessage: error.response.errors,
            });
        else
            this.setState({
                loader: false,
                loginError: true,
                signupErrorMessage: ERROR_GENERAL,
            });
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        const errors = this.handleFormValidation(this.state);
        const returnUrl = this.state.queryParams.get("returnurl");
        const defaultAccountCreationErrorMessage = "An unexpected error has occurred trying to create your account. Please try again.";
        const defaultAccountLoginErrorMessage = "An unexpected error has occurred. Please try again.";
        const createAccountCallback = async () => {
            //check if we have any errors
            if (Object.keys(errors).length === 0) {
                //crete our account registration payload
                const data = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                    password: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    //Would you ever be in here without a groupId? and why/how?
                    groupId: ReservationHelper.GetGroupId() === 'null' ? "" : ReservationHelper.GetGroupId(),
                    isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true',
                    returnUrl: returnUrl,
                    allowMessaging: this.state.allowMessaging,
                };

                //show the loader
                this.setState({ loader: true });

                try {
                    //post the payload to try and register the account
                    const response = await api.post("/api/auth/register", data);

                    try {
                        //try to authenticate them
                        const authenticationResponse = await api.post("/api/auth/authenticate", {
                            email: this.state.email,
                            password: this.state.password,
                        });
                        Authhelper.RemoveGroupId();
                        localStorage.setItem(
                            "user_info",
                            JSON.stringify(authenticationResponse.data)
                        );
                        this.setState({ loader: false });
                        this.props.onSubmit();

                        window.dataLayer?.push({
                            event: "LoginSuccess",
                            method: "Email",
                            groupId: ReservationHelper?.GetGroupId(),
                        });
                    }
                    catch (error) {
                        //show error for unable to authenticate, with failure status code
                        this.setState({
                            loader: false,
                            loginError: true,
                            signupErrorMessage: defaultAccountLoginErrorMessage,
                        });
                        return false;
                    }
                }
                catch (error) {
                    //show error for unable to authenticate, with failure status code
                    this.setState({
                        loader: false,
                        loginError: true,
                        signupErrorMessage: error?.response?.data?.error?.some(e => e === "ACCOUNT_ALREADY_EXISTS") ? "ACCOUNT_ALREADY_EXISTS" : defaultAccountCreationErrorMessage,
                    });
                    return false;
                }
            }
            else {
                return false;
            }
        }
        this.setState({ errors }, createAccountCallback);
        return false;
    };

    handleFormValidation = (state) => {
        let errors = {};
        const emailRegex = EMAIL_VALIDATION_REGEX;
        if (!this.state.firstName) errors.firstName = "Enter your first name";
        if (!this.state.lastName) errors.lastName = "Enter your last name";
        if (!this.state.email) errors.email = "Enter email address";
        if (!emailRegex.test(this.state.email))
            errors.email = "Invalid email address";
        if (!this.state.phone) errors.phone = "Enter your phone number";
        if (!this.state.password) errors.password = "Enter your password";

        if (this.state.password.length < 6) errors.password = "Password must be 6 characters or longer";
        if (this.state.confirmPassword !== this.state.password)
            errors.confirmPassword = "Confirm Password and Password should match";
        return errors;
    };

    render() {
        const { errors } = this.state;

        return (
            <div>
                {this.state.loader && <Loading />}
                {!this.state.loader && (
                    <>
                        <div
                            className="c-auth-screen"
                            style={{ minHeight: "fit-content", padding: "0 0 1rem 0" }}
                        >
                            <div className="w-100 d-flex justify-content-center">
                                <div className="auth-content">
                                    <div
                                        className="auth-title "
                                        style={{ marginBottom: "1.25rem", marginTop: "0" }}
                                    >
                                        Sign Up
                                    </div>

                                    {this.state.loginError && (
                                        <>

                                            <div className="error-message">
                                                {this.state.signupErrorMessage !== "ACCOUNT_ALREADY_EXISTS" && (
                                                    <span> {this.state.signupErrorMessage}</span>
                                                )}

                                                {this.state.signupErrorMessage === "ACCOUNT_ALREADY_EXISTS" && (
                                                    <span>
                                                        It seems that email address already has an account.
                                                        Please try logging in instead, or using the
                                                        {" "}
                                                        <Link to="/forgot-password"><strong className="text-primary text-primary--hover">password resett</strong></Link>
                                                        {" "}
                                                        feature, if you've forgotten your password.
                                                        <br />
                                                    </span>
                                                )}
                                                <small>
                                                    Contact <a href="mailto:guestservices@destify.com" target="_blank">guestservices@destify.com</a> for additional help.
                                                </small>

                                            </div>
                                        </>
                                    )}

                                    <form className="c-form" onSubmit={this.handleFormSubmit}>
                                        <ReactInput
                                            type="text"
                                            name="firstName"
                                            placeholder="First Name"
                                            error={errors && errors.firstName}
                                            onChange={this.handleInputChange}
                                        />
                                        <ReactInput
                                            type="text"
                                            name="lastName"
                                            placeholder="Last Name"
                                            error={errors && errors.lastName}
                                            onChange={this.handleInputChange}
                                        />
                                        <ReactInput
                                            type="email"
                                            name="email"
                                            placeholder="Email"
                                            error={errors && errors.email}
                                            onChange={this.handleInputChange}
                                        />
                                        <PhoneField
                                            international
                                            name="phone"
                                            placeholder="Phone Number"
                                            error={errors && errors.phone}
                                            onChange={this.handlePhoneInputChange}
                                        />
                                        <ReactPassword
                                            name="password"
                                            placeholder="Password (must be at least 6 characters)"
                                            error={errors && errors.password}
                                            onChange={this.handleInputChange}
                                        />

                                        <ReactPassword
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            error={errors && errors.confirmPassword}
                                            onChange={this.handleInputChange}
                                        />
                                        <div className="row">
                                            <div className="col">
                                                <label className="text-left">
                                                    <div
                                                        style={{ marginBottom: "-1.5em" }}
                                                        className={`float-left mr-2`}
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            name={"allowMessaging"}
                                                            onChange={this.handleCheckbox}
                                                            style={{ height: 20, width: 20, margin: '5px' }}
                                                        />
                                                    </div><p>
                                                        {" "}  Would you like a response via text? (Opt-in means agreeing to SMS from Destify. Rates may apply. Reply STOP to end or HELP for info.)</p>
                                                </label>
                                            </div>
                                        </div>
                                        <button className="c-btn mt-3" type="submit">
                                            Create My Account
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const ConfirmationLoginForm = (props) => {
    const [loader, setLoader] = useState(false);
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [facebookApiKey, setFacebookApiKey] = useState(null);
    const [destifyWebAppUrl, setDestifyWebAppUrl] = useState('');

    const [showSignupForm, setShowSignupForm] = useState(false);
    const [showLoginForm, setShowLoginForm] = useState(true);

    const queryParams = new URLSearchParams(window.location.search);

    const handleToggleSignupForm = () => {
        setShowSignupForm(!showSignupForm);
    };
    const handleToggleLoginForm = () => {
        setShowSignupForm(false);
        setShowLoginForm(true);
    };

    const handleHideLoginForm = () => {
        setShowLoginForm(false);
        setShowSignupForm(false);
    };

    useEffect(() => {
        let GROUP_ID = queryParams.get("groupId");
        if (typeof GROUP_ID !== null) localStorage.setItem("group_id", GROUP_ID);

        const getDetails = async () => {
            //try to get it from localStorage before making yet another api call for the same redundant information
            let settings = Authhelper.GetAppSettingData();
            if (settings === null) {
                const { data } = await api.get(`/api/appSetting/getSettings`);
                settings = data;
            }
            setLoader(false);
            setDestifyWebAppUrl(settings.destifyWebAppUrl);
            setGoogleApiKey(settings.googleClientId);
            setFacebookApiKey(process.env.REACT_APP_FACEBOOK_API_KEY);
        }
        getDetails().catch((e) => {
            console.error(e);
            setLoader(false);
        });
    }, []);

    const responseFacebook = (response) => {
        setLoader(true);
        api.post('/api/auth/facebook', {
            userId: response.userID,
            email: response.email,
            name: response.name,
            groupId: Authhelper.GetGroupId()
        })
            .then((response) => {
                if (!response.data.success) {
                    setLoader(false);
                    return;
                }

                if (response.data.success) {
                    Authhelper.RemoveGroupId();
                    localStorage.setItem("user_info", JSON.stringify(response.data));
                    props.onSubmit();

                    // Add GTM dataLayer event for successful Facebook authentication
                    window.dataLayer?.push({
                        event: "LoginSuccess",
                        method: "Facebook",
                        groupId: ReservationHelper?.GetGroupId(),
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setLoader(false);
            });
    };

    const responseGoogle = (response) => {
        setShowSignupForm(false);
        setLoader(true);
        api
            .post("/api/auth/google", {
                email: response.profileObj.email,
                FirstName: response.profileObj.givenName,
                LastName: response.profileObj.familyName,
                GoogleId: response.profileObj.googleId,
                ImageUrl: response.profileObj.ImageUrl,
                Name: response.profileObj.name,
                groupId: Authhelper.GetGroupId(),
                isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true'
            })
            .then((response) => {
                if (response.data.success) {
                    Authhelper.RemoveGroupId();
                    localStorage.setItem("user_info", JSON.stringify(response.data));
                    props.onSubmit();

                    // Add GTM dataLayer event for successful Google authentication
                    window.dataLayer?.push({
                        event: "LoginSuccess",
                        method: "Google",
                        groupId: ReservationHelper?.GetGroupId(),
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                setLoader(false);
            });
    };

    return (
        <>
            {loader && <Loading />}
            {!loader && (
                <>
                    <div style={{ maxWidth: "36rem" }}>
                        {showSignupForm && (
                            <SignupForm
                                destifyWebAppUrl={destifyWebAppUrl}
                                submitButtonLabel={"Create My Account"}
                                onSubmit={() => {
                                    setShowSignupForm(false);
                                    props.onSubmit()
                                }}
                            />
                        )}

                        {showLoginForm && (
                            <>
                                <LoginFormScreen
                                    destifyWebAppUrl={destifyWebAppUrl}
                                    onSubmit={() => {
                                        setShowSignupForm(false);
                                        setShowLoginForm(false);
                                        props.onSubmit();
                                    }}
                                    handleHideLoginForm={handleHideLoginForm}
                                />
                            </>
                        )}

                        <div
                            className="auth-buttons"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            {!showSignupForm && !showLoginForm && (
                                <div
                                    style={{
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                    }}
                                >
                                    {" "}
                                    <IconButton
                                        type="button"
                                        onClick={handleToggleSignupForm}
                                        icon={EmailIcon}
                                        label="Sign Up with email"
                                    />
                                </div>
                            )}
                            <div
                                style={{

                                    marginBottom: "1rem",
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {facebookApiKey &&
                                    <FacebookLogin
                                        appId={facebookApiKey}
                                        fields="name,email,picture"
                                        cssClass="c-btn c-btn--icon c-btn--ghost facebook-btn"
                                        callback={responseFacebook}
                                        render={renderProps => (
                                            <IconButton type="button" onClick={renderProps.onClick} disabled={renderProps.disabled} icon={FacebookIcon} label="Log in with Facebook" />
                                        )}
                                    />
                                }
                            </div>
                            {googleApiKey && (
                                <GoogleLogin
                                    clientId={googleApiKey}
                                    onSuccess={responseGoogle}
                                    render={(renderProps) => (
                                        <IconButton
                                            type="button"
                                            onClick={renderProps.onClick}
                                            icon={GoogleIcon}
                                            label="Log in with Google"
                                            disabled={false}
                                        />
                                    )}
                                    className="c-btn c-btn--icon c-btn--ghost google-btn"
                                />
                            )}
                            {!showLoginForm && (
                                <div className="text-note" style={{ marginTop: "1rem" }}>
                                    I have an account!{" "}
                                    <button
                                        className="btn btn-link"
                                        onClick={handleToggleLoginForm}
                                    >
                                        <strong className="text-primary text-primary--hover">
                                            Log in
                                        </strong>
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ConfirmationLoginForm;