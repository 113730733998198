import api from "utils/api";
import { Link } from 'react-router-dom';
import Logo from '../../../components/Logo';
import GoogleLogin from 'react-google-login';
import Authhelper from '../../../core/authHelper';
import Loading from "../../../components/Loading";
import React, { useEffect, useState } from 'react';
import MainScreen from '../../../layouts/MainScreen';
import IconButton from '../../../components/IconButton';
import GoogleIcon from '../../../assets/images/icon-google.svg';
import EmailIcon from '../../../assets/images/icon-envelope.svg';
import FacebookIcon from '../../../assets/images/icon-facebook.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ReservationHelper } from "core/reservationHelper";

const SignupScreen = (props) => {
    const [loader, setLoader] = useState(true);
    const [groupName, setGroupName] = useState(null);
    const [weddingDate, setWeddingDate] = useState(null);
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [facebookApiKey, setFacebookApiKey] = useState(null);
    const [toggleMobilePopup, setToggleMobilePopup] = useState(false);
    const handleToggleMobilePopup = () => setToggleMobilePopup(!toggleMobilePopup);
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(async () => {
        //let param = props.location.search.split('&')[0];
        let GROUP_ID = queryParams.get('groupId');

        //Set GROUP_ID to localstorage
        if (typeof GROUP_ID !== null)
            localStorage.setItem("group_id", GROUP_ID);
        api.all([
            api.get(`/api/usergroup/getGroupInfoByGroupId?groupId==${GROUP_ID}`),
            api.get(`/api/appSetting/getSettings`),
        ]).then(api.spread((group, setting) => {
            setLoader(false);

            //group
            setGroupName(group.data.groupName);
            setWeddingDate(group.data.weddingDate?.split('T')[0]);

            //setting
            setGoogleApiKey(setting.data.googleClientId);
            setFacebookApiKey(setting.data.facebookApiKey);
        })).catch(e => { setLoader(false); })
    }, [])
    const responseFacebook = (response) => {
        setLoader(true);
        api.post('/api/auth/facebook', {
            userId: response.userID,
            email: response.email,
            name: response.name,
            groupId: Authhelper.GetGroupId(),
            isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true' ? true : false,
        })
            .then(response => {
                if (response.data.success) {
                    Authhelper.RemoveGroupId();
                    localStorage.setItem("user_info", JSON.stringify(response.data));

                    if (Authhelper.IsGuestRoleAuthorized()) {
                        if (queryParams.get('returnurl') != null)
                            window.location.replace(queryParams.get('returnurl'))
                        else
                            window.location.replace("/guest/wedding");
                    } else if (Authhelper.IsGroupLeaderRoleAuthorized()) {
                        if (queryParams.get('returnurl') != null)
                            window.location.replace(queryParams.get('returnurl'))
                        else
                            window.location.replace("/groupleader/wedding");
                    } else Authhelper.Logout();
                }
            }).catch(error => { setLoader(false); });
    }

    const responseGoogle = (response) => {
        setLoader(true);
        api.post('/api/auth/google', {
            email: response.profileObj.email,
            FirstName: response.profileObj.givenName,
            LastName: response.profileObj.familyName,
            GoogleId: response.profileObj.googleId,
            ImageUrl: response.profileObj.ImageUrl,
            Name: response.profileObj.name,
            groupId: Authhelper.GetGroupId(),
            isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true' ? true : false,
        })
            .then(response => {
                if (response.data.success) {
                    Authhelper.RemoveGroupId();
                    localStorage.setItem("user_info", JSON.stringify(response.data));

                    if (Authhelper.IsGuestRoleAuthorized()) {
                        if (queryParams.get('returnurl') != null)
                            window.location.replace(queryParams.get('returnurl'))
                        else
                            window.location.replace("/guest/wedding");
                    } else if (Authhelper.IsGroupLeaderRoleAuthorized()) {
                        if (queryParams.get('returnurl') != null)
                            window.location.replace(queryParams.get('returnurl'))
                        else
                            window.location.replace("/groupleader/wedding");
                    } else Authhelper.Logout();
                }
            }).catch(error => { setLoader(false); });
    }

    return (
        <MainScreen blur={toggleMobilePopup}>
            {loader && <Loading />}
            {!loader && <>
                <div className={`c-auth-screen ${toggleMobilePopup ? 'mobile-popup' : ''}`}>
                    <div className="content text-center">
                        <Logo />
                        <div className="description">
                            Plan your wedding step by step. In one place.
                        </div>
                        <div>
                            {groupName && <div><strong> {groupName}</strong></div>}
                            {weddingDate && <div><strong> {weddingDate}</strong></div>}
                        </div>
                        <br></br>
                        <div className={`mobile-auth-trigger d-lg-none ${toggleMobilePopup ? 'opacity-0' : ''}`}>
                            <div className="d-flex justify-content-center">
                                <button type="button" className="c-btn" onClick={handleToggleMobilePopup}>Sign Up</button>
                            </div>
                        </div>
                        <div className={`mobile-auth-popup ${toggleMobilePopup ? 'active' : ''}`}>
                            <div className="mobile-auth-title d-lg-none">Sign Up</div>
                            <div className="mobile-auth-popup-toggle d-lg-none" onClick={handleToggleMobilePopup}>+</div>
                            <div className="auth-buttons">

                                <IconButton target={queryParams.get('returnurl') != null ? "/register/profile?returnurl=" + queryParams.get('returnurl') : "/register/profile"} icon={EmailIcon} label="Sign Up with email" />

                                {/*{facebookApiKey &&*/}
                                {/*    <FacebookLogin*/}
                                {/*        appId={facebookApiKey}*/}
                                {/*        fields="name,email,picture"*/}
                                {/*        cssClass="c-btn c-btn--icon c-btn--ghost facebook-btn"*/}

                                {/*        callback={responseFacebook}*/}
                                {/*        render={renderProps => (*/}
                                {/*            <IconButton type="button" onClick={renderProps.onClick} disabled={renderProps.disabled} icon={FacebookIcon} label="Log in with Facebook" />*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*}*/}

                                {googleApiKey &&
                                    <GoogleLogin
                                        clientId={googleApiKey}
                                        buttonText="Log in with Google"
                                        onSuccess={responseGoogle}
                                        render={renderProps => (
                                            <IconButton type="button" onClick={renderProps.onClick} disabled={renderProps.disabled} icon={GoogleIcon} label="Log in with Google" />
                                        )}
                                        className="c-btn c-btn--icon c-btn--ghost google-btn"
                                    />
                                }
                            </div>
                        </div>
                        <div className="text-note">
                            I have account! <Link to={queryParams.get('returnurl') != null ? "/login?returnurl=" + queryParams.get('returnurl') : "/login"}><strong className="text-primary text-primary--hover">Log in</strong></Link>
                        </div>
                    </div>
                </div>
            </>}
        </MainScreen>
    )
}

export default SignupScreen;