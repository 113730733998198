import api from "utils/api";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/Loading";
import BackButton from "../../../components/BackButton";
import ReactInput from "../../../components/InputField";
import SubMainScreen from "../../../layouts/SubMainScreen";
import { EMAIL_VALIDATION_REGEX, ERROR_GENERAL } from "core/globalConstant";

class ForgotPasswordScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {},
      loader: false,
      apiError: false,
    };
  }
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    //store email to use it while resend forget pwd link
    localStorage.setItem("forget_password_email", this.state.email);

    const returnUrl = window.location.origin + "/change-password";

    localStorage.setItem("forget_password_return_url", returnUrl);

    const errors = this.handleFormValidation(this.state);
    this.setState({ errors }, () => {
      if (Object.keys(errors).length === 0) {
        this.setState({ loader: true });
        api
          .get(
            `/api/auth/createForgetPasswordToken?email=${encodeURIComponent(
              this.state.email
            )}&returnUrl=${encodeURIComponent(returnUrl)}`
          )
          .then((response) => {
            this.setState({ loader: false });
            this.props.history.push("/forgot-password/pre-confirmation");
          })
          .catch((error) => {
            this.setState({ loader: false, apiError: true });
          });
      } else {
        return false;
      }
    });
    return false;
  };

  handleFormValidation = (state) => {
    let errors = {};
    const emailRegex = EMAIL_VALIDATION_REGEX;
    if (!this.state.email) errors.email = "Enter email address";
    if (!emailRegex.test(this.state.email))
      errors.email = "Invalid email address";

    return errors;
  };
  render() {
    const { errors } = this.state;
    return (
      <SubMainScreen>
        {this.state.loader && <Loading />}
        {!this.state.loader && (
          <>
            <div className="c-auth-screen">
              <div className="auth-content">
                <BackButton target="/guest/reservation/confirmation" />

                <div className="auth-title">Forgot Password</div>
                <p className="description">
                  Please enter the email address you used to register your
                  account and we will email you instructions on how to reset
                  your password.
                </p>
                <div
                  className="description"
                  style={{
                    borderLeft: "solid 0.25rem #f0ad4e",
                    paddingLeft: "0.75rem",
                  }}
                >
                  <small>
                    <b>Please Note:</b>{" "}
                    <i>
                      If you used a Facebook or Google account to signup, please
                      refer to their websites on how to reset your password. We
                      cannot reset passwords for Facebook or Google accounts.
                    </i>
                  </small>
                </div>

                <form className="c-form" onSubmit={this.handleFormSubmit}>
                  <ReactInput
                    type="email"
                    label="Email"
                    name="email"
                    placeholder="Email"
                    error={errors && errors.email}
                    onChange={this.handleInputChange}
                  />
                  <button className="c-btn" type="submit">
                    Reset Password
                  </button>
                </form>
                {this.state.apiError && (
                  <div>
                    <p>
                      <div
                        style={{
                          fontSize: "0.85em",
                          borderLeft: "solid 0.25rem #dc3545",
                          padding: "0.5rem",
                          paddingLeft: "0.75rem",
                          background: "rgba(255,100,100,.1)",
                          marginTop: "0.5em",
                          color: "#dc3545",
                        }}
                      >
                        It seems something went wrong with the password reset
                        attempt. Please try again.
                        <br />
                        <br />
                        If you continue to have problems with your password
                        reset request, please contact us at
                        <br />
                        <a href="tel:8449483911" target="_blank">
                          (844) 948-3911
                        </a>{" "}
                        or{" "}
                        <a
                          href="mailto:GuestServices@destify.com"
                          target="_blank"
                        >
                          GuestServices@destify.com
                        </a>{" "}
                        for additional assistance.
                      </div>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </SubMainScreen>
    );
  }
}

export default withRouter(ForgotPasswordScreen);
