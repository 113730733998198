import {
  SET_DATE_STATUS,
  SET_ROOMS_STATUS,
  SET_ROOM_ARRANGEMENTS_STATUS,
  SET_CONFIRMATION_STATUS,
  SET_TOTAL_PROGRESS,
  SET_PREV_RESERVATION_URL,
  SET_NEXT_RESERVATION_URL,
} from "../actionTypes";
import { ReservationHelper } from "core/reservationHelper";

export const initialState = {
  dates: false,
  rooms: false,
  roomArrangements: false,
  confirmation: false,
  //DWA-2918
  total:
    ReservationHelper.GetReservationStepProgress() ||
    ReservationHelper.RESERVATION_STEPS.SELECT_DATES,
  reservationURLFrom: "",
  reservationUrlToGo: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATE_STATUS:
      return {
        ...state,
        dates: action.payload,
      };

    case SET_ROOMS_STATUS:
      return {
        ...state,
        rooms: action.payload,
      };

    case SET_ROOM_ARRANGEMENTS_STATUS:
      return {
        ...state,
        roomArrangements: action.payload,
      };

    case SET_CONFIRMATION_STATUS:
      return {
        ...state,
        confirmation: action.payload,
      };
    case SET_TOTAL_PROGRESS:
      //DWA-2918: we need to strongly keep track of where they are, in local storage, resilient to refreshes
      //so they dont exploit our system and get a five finger discount
      ReservationHelper.SetReservationStepProgress(action.payload);
      return {
        ...state,
        total: action.payload,
      };
    case SET_PREV_RESERVATION_URL:
      return {
        ...state,
        reservationUrlFrom: action.payload,
      };
    case SET_NEXT_RESERVATION_URL:
      return {
        ...state,
        reservationUrlToGo: action.payload,
      };

    default:
      return state;
  }
};
