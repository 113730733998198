import { isBefore as _isBefore } from "./utils";

const today = new Date();

const CalenderDay = (day, date) => {
  const dateString = new Date(date).toDateString();
  const todayString = today.toDateString();
  const isBefore = _isBefore(date, today);
  return (
    <span
      className={`d-inline-block w-100 h-100 rounded-circle relative -z-1 ${
        isBefore ? "text-muted" : "unset"
      } ${dateString === todayString ? "bg-light" : "initial"}`}
    >
      {day}
    </span>
  );
};

export default CalenderDay;
