import api from "utils/api";
import Modal from 'react-bootstrap/Modal';
import Stripe from "../../../summary/stripe";
import React, { useContext, useState } from 'react';
import { store } from "../../../../../../../context/store";
import Loading from "../../../../../../../components/Loading";
import Authhelper from "../../../../../../../core/authHelper";
import { SECURE_RESERVATION } from '../../../../../../../core/globalConstant';
import { ReservationHelper, ReservationStepsValidator } from '../../../../../../../core/reservationHelper';
import { setConfirmation, setTotalProgress } from "../../../../../../../context/actions/reservationAction";

const ConfirmPayment = (props) => {
    const [state, dispatch] = useContext(store);
    const [loader, setLoader] = useState(false);
    const [responseBody, setResponseBody] = useState(null);
    const [responseTitle, setResponseTitle] = useState(null);
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
    const handleConfirmPaymentModalClose = () => setShowConfirmPaymentModal(false);
    const [showConfirmPaymentResponseModal, setShowConfirmPaymentResponseModal] = useState(false);
    const [showDupPaxError, setShowDupPaxError] = useState(false);
    const secureReservationMessage = "Please remain on the page while we secure your reservation. Do not click back or refresh, as this may result in multiple charges and/or reservation errors.";
    const handleConfirmPaymentResponseModalClose = () => {
        setShowConfirmPaymentResponseModal(false);
    }
    const handleGoToRoomArrangeStep = () => {
        props.history.push("/guest/reservation/room-arrangements");
    }
    const handleGoToSelectDateStep = () => {
        //erase pax state for reservation
        ReservationHelper.SetAdultsCount(0);
        ReservationHelper.SetAdults(null);
        ReservationHelper.SetChildCount(0);
        ReservationHelper.SetChilds(null);
        props.history.push("/guest/reservation/select-date");
    }
    const handelConfirmPaymentModalOpen = () => {
        let step1 = ReservationStepsValidator.IsSelectDatesStepValid();
        let step2 = ReservationStepsValidator.IsSelectRoomStepValid();
        let step3 = ReservationStepsValidator.IsRoomArrangementStepValid();
        let step4 = ReservationStepsValidator.IsConfirmationStepValid();

        if (!step1) {
            setResponseTitle("Warning !!!");
            setResponseBody("'Select Dates' step looks invalid. Please review before proceeding.");
            setShowConfirmPaymentResponseModal(true);
        }
        else if (!step2) {
            setResponseTitle("Warning !!!");
            setResponseBody("'Select Room' step looks invalid. Please review before proceeding.");
            setShowConfirmPaymentResponseModal(true);
        }
        else if (!step3) {
            setResponseTitle("Warning !!!");
            setResponseBody("'Room Arrangements' step looks invalid. Please review before proceeding.");
            setShowConfirmPaymentResponseModal(true);
        }
        else if (!step4) {
            setResponseTitle("Warning !!!");
            setResponseBody("'Confirmation' step looks invalid. Please review before proceeding.");
            setShowConfirmPaymentResponseModal(true);
        } else {
            window.dataLayer?.push({
              event: "BookingCheckoutInitiated",
              supplierCode: ReservationHelper.GetSupplierCode(),
              ...ReservationHelper.GetRoomInformationRequestData(),
            });
            setShowConfirmPaymentModal(true);
          }
    }

    const handleSecureReservation = async (paymentData) => {
        setLoader(true);
        const userInfo = await JSON.parse(localStorage.getItem("user_info"));
        const _isPseudoBook = ReservationHelper.GetIsPseudoBook();
        const _resort = ReservationHelper.GetResort();
        const _travelZapPrice = ReservationHelper.GetRoomPackageAmount();
        const _selectedReservation = ReservationHelper.GetReservationRequestData();
        const _roomInformation = ReservationHelper.GetRoomInformationRequestData();
        const _insurancePurchased = ReservationHelper.GetInsurancePurchased();
        const _insuranceAmount = ReservationHelper.GetInsurancePrice();
        const _supplierCode = ReservationHelper.GetSupplierCode();
        const _hotelCommissionPercentage = ReservationHelper.GetHotelCommissionPercentage();
        _roomInformation.Supplier = _supplierCode;
        _roomInformation.resortCommissionMultiplier = _hotelCommissionPercentage;

        // this code needs to change with tranfers
        const _bookedTransferRequest = {
            Price: ReservationHelper.GetTransferPrice(),
            SupplierCode: ReservationHelper.GetSupplierCode()
        };

        paymentData.paymentType = "Deposit";
        paymentData.travelZapPrice = _isPseudoBook ? parseFloat(ReservationHelper.GetSupplierPrice()) : parseFloat(_travelZapPrice);
        paymentData.paymentAmount = ReservationHelper.GetSelectedPaymentAmount();
        paymentData.insuranceAmount = _insuranceAmount;
        paymentData.insurancePurchased = _insurancePurchased;

        _selectedReservation.holderFirstName = paymentData.firstName;
        _selectedReservation.holderLastName = paymentData.lastName;
        _selectedReservation.supplierPrice = ReservationHelper.GetSupplierPrice();
        const _groupId = ReservationHelper.GetGroupId();

        const userEmail = userInfo.authenticatedEmail?.toLowerCase();
        const groupLeaderEmail = ReservationHelper.GetGroupLeaderEmail()?.toLowerCase();
        const IsGroupLeader = userEmail === groupLeaderEmail ? true : Authhelper.IsGroupLeaderRoleAuthorized();

        const _secureReservationDTO = {
            paymentInformation: paymentData,
            roomInformation: _roomInformation,
            SelectedReservation: _selectedReservation,
            BookedTransferRequest: _bookedTransferRequest,
            GroupType: _resort.groupType,
            GroupId: _groupId,
            GroupName: ReservationHelper.GetGroupName(),
            IsUserAuthenticated: Authhelper.IsAuthenticated(),
            isPseudoBook: _isPseudoBook,
            isGroupLeader: IsGroupLeader,
            groupLeaderEmail,
            //SupplierPrice: ReservationHelper.GetSupplierPrice()
        };
        if (_roomInformation.pricingType === "static_pricing") {
            _roomInformation.groupCommissionMultiplier = 0.0;
            _roomInformation.resortCommissionMultiplier = 0.0;
            _roomInformation.supplierCommission = 0.0;
            _roomInformation.supplier = _roomInformation.pricingType;
        }

        api.post('/api/payment/secureReservation',
            _secureReservationDTO
        ).then((response) => {
            if (response?.data?.success) {
                const roomKey = response.data.roomKey;
                const bookingReferenceNumber = response.data.referenceNumber;
                const isHelpDeskTicketCreated = response.data.isHelpDeskTicketCreated;

                ReservationHelper.SetBookingReferenceNumber(bookingReferenceNumber);
                setTotalProgress(dispatch, 100);
                setConfirmation(dispatch, true);

                const _insuranceAmount = 0;//TODO:: 0 for now- TBD
                const _roomPackageAmount = ReservationHelper.GetRoomPackageAmount();
                const _summaryAmount = parseFloat(_roomPackageAmount) + parseFloat(_insuranceAmount);
                const _totalPaidToday = ReservationHelper.GetSelectedPaymentAmount();
                const _balanceAmount = _summaryAmount - parseFloat(_totalPaidToday);
                const _selectedRoom = ReservationHelper.GetSelectedRoom();
                const _transfersIncluded = ReservationHelper.GetGroupVacationType()?.toLowerCase() === "hotelandtransfers";

                window.dataLayer?.push({
                    event: "BookingSuccess",
                    ...paymentData,
                    ..._roomInformation,
                  });
              
                props.history.push({
                    pathname: '/guest/reservation/completed',
                    state: {
                        completionData: {
                            referenceNumber: bookingReferenceNumber,
                            isHelpDeskTicketCreated,
                            adults: ReservationHelper.GetAdults()?.length,
                            childs: ReservationHelper.GetChilds()?.length,
                            nights: ReservationHelper.GetTotalNight(),
                            roomPackageAmount: _roomPackageAmount.toFixed(2),
                            totalPaidToday: _totalPaidToday.toFixed(2),
                            insurance: _insuranceAmount.toFixed(2),
                            summaryAmount: _summaryAmount.toFixed(2),
                            balanceAmount: _balanceAmount.toFixed(2),
                            roomType: _selectedRoom.roomName,
                            roomKey: roomKey,
                            transfersIncluded: _transfersIncluded,
                            groupId: _groupId,
                            hotelName: _selectedRoom.hotelName
                        }
                    }
                });

                //erase state for reservation
                ReservationHelper.ClearReservation();

                return;
            }
            //try to read out any errors
            let errors = response?.data?.error;
            let errorMessage = Array.isArray(errors) ? errors.join(",") : null;

            //see specifically if duplicate passenger names error was triggered
            if (errorMessage?.toLowerCase().includes("duplicate passenger names")) {
                setShowDupPaxError(true);
            }

            const jsError = {
                "ErrorMessage": errorMessage || "Unknown Error",
                "Transaction": `Posted Payload: ${JSON.stringify(_secureReservationDTO)}\nlocalStorage contents:${JSON.stringify({ ...localStorage })}`,
                "StackTrace": `${JSON.stringify(Error().stack)}`,
                "ErrorName": "Unexpected failure in handleSecureReservation call",
                "Datetime": new Date()
            };
            api.post('/api/JSTransactionError/postJSTransactionError', jsError);
            setResponseTitle("Error");

            //explicitly making the verbiage change here be different than the catch below, so if we get screenshots from customers
            //we can narrow it down to the right place where things exploded
            setResponseBody(errorMessage ?? "We have experienced an error during the booking step. Please check your email for confirmation. Otherwise, please try your action again or contact guestservices@destify.com");
        }).catch(function (error) {
            const isAxiosError = "isAxiosError" in error;

            //if it's an axios error, the error object we're already serializing and sending in the "ErrorMessage" prop, will already have all the necessary data
            let transaction = !isAxiosError ? `Posted Payload: ${JSON.stringify(_secureReservationDTO)}` : "";

            transaction += `\nlocalStorage contents:${JSON.stringify({ ...localStorage })}`
            const jsError = {
                "ErrorMessage": `${(isAxiosError ? JSON.stringify(error) : JSON.stringify(error, Object.getOwnPropertyNames(error)))}`,
                "Transaction": transaction,
                "StackTrace": `${JSON.stringify(Error().stack)}`,
                "ErrorName": "Axios Promise Error in handleSecureReservation call",
                "Datetime": new Date()
            };
            api.post('/api/JSTransactionError/postJSTransactionError', jsError);
            setResponseTitle("Unknown Error");
            setResponseBody("We have experienced an unknown error during the booking step. Please check your email for confirmation. Otherwise, please try your action again or contact guestservices@destify.com");
        }).finally(() => {
            setLoader(false);
            setShowConfirmPaymentModal(false);
            setShowConfirmPaymentResponseModal(true);
        });
    }

    const canMakePayment = props.paymentAmount > 0 && props.selectedTransferOption !== null;

    return (
      <div>
        <button
          type="button"
          onClick={handelConfirmPaymentModalOpen}
          className={`c-btn ${
            canMakePayment ? "" : "c-btn-disabled"
          } c-btn--ghost c-btn--ghost__primary`}
        >
          {props.label || "Confirm Payment"}
        </button>

        <Modal
          show={showConfirmPaymentModal}
          onHide={handleConfirmPaymentModalClose}
          backdrop="static"
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader && <Loading loadingText={secureReservationMessage} />}
            {!loader && (
              <>
                <Stripe
                  formType={SECURE_RESERVATION}
                  callback={handleSecureReservation}
                />
              </>
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={showConfirmPaymentResponseModal}
          onHide={handleConfirmPaymentResponseModalClose}
          backdrop="static"
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>{responseTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{responseBody}</p>
          </Modal.Body>
          <Modal.Footer>
            {!showDupPaxError && (
              <>
                <button
                  className="c-btn c-btn--black c-btn--lg"
                  type="button"
                  onClick={handleConfirmPaymentResponseModalClose}
                >
                  Ok
                </button>
              </>
            )}
            {showDupPaxError && (
              <>
                <button
                  className="c-btn c-btn--black c-btn--lg"
                  type="button"
                  onClick={handleGoToRoomArrangeStep}
                >
                  Edit Passenger Information
                </button>
                <button
                  className="c-btn c-btn--black c-btn--lg"
                  type="button"
                  onClick={handleGoToSelectDateStep}
                >
                  Start Over
                </button>
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
}
export default ConfirmPayment