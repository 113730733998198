import { useEffect, useMemo, useState } from "react";
import { ReservationHelper } from "core/reservationHelper";
import { useStore } from "context/store";

import { setDates, setRooms } from "context/actions/reservationAction";

import {
  differenceInDays,
  isBefore,
  addDays,
  formatDate,
  getQueryStringValue,
  isAtLeastTwoDaysBefore,
} from "../utils";

export const isGroupLeader = getQueryStringValue("groupLeader") === "true";

const format = (date) => new Date(formatDate(date, "YYYY/MM/DD"));

export const useDate = ({ minNights, weddingDate }) => {
  const [, dispatch] = useStore();
  const [startDate, setStartDate] = useState(() => {
    const date = ReservationHelper.GetStartDate();
    // NOTE: formatting date is important because localstorage has stored date values in 'YYYY-MM-DD' format, which
    // could be offset by -1 or +1 or 0 day when parsed through new Date(), based on the timezone you are on.
    // formatting with YYYY/MM/DD gives the accurate date without any offsets
    if (date) return format(date);
    return null;
  });
  const [endDate, setEndDate] = useState(() => {
    const date = ReservationHelper.GetEndDate();
    if (date) return format(date);
    return null;
  });
  const [totalDate, setTotalDate] = useState(0);

  const minNightValidationMessage = `You must stay for at least ${minNights} nights.`;
  const groupLeaderValidationMessage =
    "Must arrive at least 2 nights before wedding date.";
  const dateRangeValidationMessage =
    "It is required your arrival date is at least 1 day before the wedding.";
  const departureDateValidationMessage =
    "Departure date must be after the wedding date.";
  const arrivalDateValidationMessage =
    "Arrival date must be at least 4 days in the future.";

  const [constraints, setConstraints] = useState([]);

  const lastDayToBook = useMemo(() => addDays(-3, weddingDate), [weddingDate]);
  const today = new Date();
  const dayAfterEndDay = useMemo(() => addDays(1, weddingDate), [weddingDate]);

  useEffect(() => {
    setConstraints([
      {
        message: minNightValidationMessage,
        isValid: totalDate >= minNights,
      },
      ...(isGroupLeader
        ? [
            {
              message: groupLeaderValidationMessage,
              isValid: isAtLeastTwoDaysBefore(startDate, weddingDate),
              children: [
                {
                  message: arrivalDateValidationMessage,
                  isValid:
                    isBefore(today, startDate) &&
                    Math.abs(differenceInDays(startDate, today)) >= 3,
                },
                {
                  message: departureDateValidationMessage,
                  isValid: isBefore(weddingDate, endDate),
                },
              ],
            },
          ]
        : [
            {
              message: dateRangeValidationMessage,
              isValid: isBefore(startDate, weddingDate),
              children: [
                {
                  message: arrivalDateValidationMessage,
                  isValid:
                    isBefore(today, startDate) &&
                    Math.abs(differenceInDays(startDate, today)) >= 3,
                },
                {
                  message: departureDateValidationMessage,
                  isValid: isBefore(weddingDate, endDate),
                },
              ],
            },
          ]),
    ]);
  }, [
    totalDate,
    minNights,
    startDate,
    endDate,
    weddingDate,
    dayAfterEndDay,
    lastDayToBook,
  ]);

  const calculateTotalNights = (start, end) => {
    const total = differenceInDays(start, end);
    return total;
  };

  useEffect(() => {
    const nights = calculateTotalNights(startDate, endDate);
    setTotalDate(nights);
    if (startDate && endDate) {
      setDates(dispatch, true);
    } else {
      setDates(dispatch, false);
    }
  }, [startDate, endDate]);

  const handleClear = () => {
    //clear next steps
    // clearNextSteps();
    setEndDate(null);
    setStartDate(null);
    setTotalDate(0);
    setDates(dispatch, false);
    setRooms(dispatch, false);
    ReservationHelper.SetSelectedRoom(null);
    setConstraints([]);
  };

  const handleChangeDate = (start, end) => {
    setStartDate(start);
    ReservationHelper.SetStartDate(start);
    ReservationHelper.SetTravelDate(start);
    setEndDate(end);
    ReservationHelper.SetEndDate(end);
    const nights = calculateTotalNights(start, end);
    setTotalDate(nights);
    ReservationHelper.SetTotalNight(nights);
    setDates(dispatch, true);
  };

  return {
    constraints,
    startDate,
    endDate,
    totalDate,
    handleChangeDate,
    handleClear,
  };
};
