import api from "utils/api";
import { Row, Col } from 'reactstrap';
import FlightArrival from './arrival';
import FlightDeparture from './departure';
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";

const FlightForm = (props) => {
    const [step, setStep] = useState(1);
    let loggedInTravelerId = props.travelerId;
    const [loader, setLoader] = useState(true);
    const [travellers, setTravelers] = useState([]);
    const [isAddModal, setIsAddModal] = useState(true);
    const [selectedTravelers, setSelectedTravelers] = useState([]);
    const [arrivalFlightDetail, setArrivalFlightDetail] = useState({});
    const [departureFlightDetail, setDepartureFlightDetail] = useState({});

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            let hasArrivalInfo = Object.keys(props.arrivalInfo).length > 0;
            let hasDepartureInfo = Object.keys(props.returnInfo).length > 0;

            if (hasArrivalInfo && hasDepartureInfo) {
                setIsAddModal(false);
                setArrivalFlightDetail(props.arrivalInfo);
                setDepartureFlightDetail(props.returnInfo);
            }

            api.all([api.get(`/api/room/getRoomDetailByEmail?email=${AUTHENTICATED_EMAIL}`)])
                .then(api.spread((room) => {
                    props.flightModelStepsCallback("Arrival Flight");

                    //room
                    let _travelers = [];
                    room.data?.roomInfo.map((room) => {
                        room.guestInfo.map((guest) => {
                            guest.default = guest.id === loggedInTravelerId ? true : false;
                            guest.selected = guest.id === loggedInTravelerId ? true : false;
                            if (guest.id === loggedInTravelerId) { _travelers.unshift(guest); } else _travelers.push(guest);
                        })
                    })

                    setTravelers(_travelers);
                    setLoader(false);
                })).catch(e => { setLoader(false); })
        })
    }, []);

    const handleTravellerChange = (e) => {
        let _defaultTraveller = selectedTravelers[0];
        travellers.map((item) => { if (e.target.name === item.id) item.selected = e.target.checked });
        let selected = travellers.reduce((a, o) => (o.selected && a.push(o.id), a), [])
        selected.push(_defaultTraveller);
        setSelectedTravelers(selected)
    }

    const handleArrivalCallback = (e) => {
        setStep(2);
        setArrivalFlightDetail(e);
        props.flightModelStepsCallback("Return Flight");
    }

    const handleDepartureCallback = (e) => {
        setStep(3);
        setDepartureFlightDetail(e);
        props.flightModelStepsCallback("Select Travellers");
    }

    async function handleTravellerSelectionAndSave(e) {
        e.preventDefault();
        setLoader(true);

        let _arrivalDate = arrivalFlightDetail.formattedDate;
        let _arrivalTime = arrivalFlightDetail.formattedTime;
        let _departureDate = departureFlightDetail.formattedDate;
        let _departureTime = departureFlightDetail.formattedTime;

        let _data = {
            roundTripFlight: {
                arrivalFlight: [{
                    flightNo: arrivalFlightDetail.flightNo,
                    airlineName: arrivalFlightDetail.airlineName,
                    arrivalAirport: arrivalFlightDetail.arrivalAirport,
                    departureDateTime: `${_arrivalDate}T${_arrivalTime}`,
                    departureAirport: arrivalFlightDetail.departureAirport,
                    airlineConfirmationNo: arrivalFlightDetail.airlineConfirmationNo
                }],
                departureFlight: [{
                    flightNo: departureFlightDetail.flightNo,
                    airlineName: departureFlightDetail.airlineName,
                    arrivalAirport: departureFlightDetail.arrivalAirport,
                    arrivalDateTime: `${_departureDate}T${_departureTime}`,
                    departureAirport: departureFlightDetail.departureAirport,
                    airlineConfirmationNo: departureFlightDetail.airlineConfirmationNo,
                }]
            },
            travelerIDs: selectedTravelers
        };

        await api.post('/api/flight/updateflightinfo', _data).then((res) => {
            setLoader(false);

            if (res.data) {
                if (isAddModal) props.flightModelResponseCallback('added');
                else props.flightModelResponseCallback('updated');
            } else props.flightModelResponseCallback('error');

        }).catch((error) => {
            setLoader(false);
            props.flightModelResponseCallback('error');
        });
    }

    const renderTravellers = () => {
        return travellers.map((item, index) => {
            if (item.default) {
                selectedTravelers.push(item.id);
                return (<div key={index}><label><input name={item.id} type="checkbox" disabled checked /> {item.fullName}</label></div>);
            } else {
                return (<div key={index} ><label><input name={item.id} type="checkbox" onChange={handleTravellerChange} /> {item.fullName}</label></div>);
            }
        })
    }

    return (
        <div>
            {loader && <Loading />}
            {!loader && <>
                <div className="w-100">
                    <div>
                        {step === 1 && <FlightArrival arrivalCallback={handleArrivalCallback} arrivalFlightDetail={arrivalFlightDetail} />}
                        {step === 2 && <FlightDeparture departureCallback={handleDepartureCallback} departureFlightDetail={departureFlightDetail} />}
                        {step === 3 &&
                            <div className="state">
                                <Row>
                                    <Col lg="8" xl="8">
                                        <form className="c-form" onSubmit={handleTravellerSelectionAndSave}>
                                            <div>{renderTravellers()}</div>
                                            <br></br>
                                            <button type="submit" className="c-btn c-btn--ghost c-btn--ghost__primary">Save</button>
                                        </form>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </div>
            </>}

        </div >
    );
}
export default withRouter(FlightForm);