import React from "react";
import { Link } from "react-router-dom";
import SubMainScreen from "../../../../layouts/SubMainScreen";
import TickIcon from "../../../../assets/images/check-animated.gif";

const ForgetPasswordPostConfirmationScreen = () => {
  return (
    <SubMainScreen>
      <div className="c-auth-screen d-flex align-items-center">
        <div className="auth-content">
          <div className="text-center mb-3">
            <img src={TickIcon} alt="Success" />
          </div>
          <div className="auth-sub-title text-center mb-3">
            Password changed
          </div>
          <div className="description text-center mb-4">
            You can now log in to your account.
          </div>
          <Link
            to="/guest/reservation/confirmation"
            className="c-btn c-btn--sm c-btn--outline"
          >
            Continue where you left off
          </Link>
        </div>
      </div>
    </SubMainScreen>
  );
};

export default ForgetPasswordPostConfirmationScreen;
