import React, { useEffect, useMemo, useState } from "react";

import GuestReservation from "../reservationLayout";
import Loading from "components/Loading";
import DateField from "./DateField";
import GuestCounter from "./GuestCounter";
import TravelAgreementModal from "./TravelAgreementModal";

import { useDate, useGuest, useWeddingData } from "./hooks";

import {
  setNextReservationUrl,
  setTotalProgress,
} from "context/actions/reservationAction";
import { useStore } from "context/store";

import { getQueryStringValue } from "./utils";

import { ReservationHelper } from "core/reservationHelper";

const MAX_ALLOWED_GUESTS = 6;
const NEXT_ROUTE = "/guest/reservation/rooms";

const SelectReservationDates = (props) => {
  const [store, dispatch] = useStore();
  const { loading, minNights, weddingDate, weddingDateArray } =
    useWeddingData();
  const guestHook = useGuest(MAX_ALLOWED_GUESTS);
  const dateHook = useDate({ minNights, weddingDate });
  const [showSubModal, setShowSubModal] = useState(true);

  const handleOnSubModalClose = () => setShowSubModal(false);
  const handleAcceptTerms = () => {
    setShowSubModal(false);
    ReservationHelper.SetAcceptTermsAndCondition(true);
  };

  useEffect(() => {
    const getGroupLeader = () => {
      const parsedGroupLeader = Boolean(
        getQueryStringValue("groupLeader") || false
      );
      ReservationHelper.SetGroupLeaderFlag(parsedGroupLeader);
      setShowSubModal(parsedGroupLeader);
    };
    getGroupLeader();

    setNextReservationUrl(dispatch, NEXT_ROUTE);
    return () => setNextReservationUrl(dispatch, "");
  }, []);

  //DWA-2901 Bug Fix: Wrong hotel being booked through PTID.
  //Fetch HotelInfo on ech render to reflect Hotel Info changes from CRM.
  useEffect(() => {
    const fetchHotelInformation = async () => {
      const GROUP_ID = ReservationHelper.GetGroupId();
      if (GROUP_ID) {
        try {
          await ReservationHelper.GetHotelsInformationAsync(GROUP_ID);
        } catch (error) {
          console.error("Failed to fetch hotel information:", error);
        }
      }
    };
    fetchHotelInformation();
  }, []);

  const isValid = useMemo(() => {
    const validateItems = (items) => {
      if (!items?.length) return true;
      return items.every(
        (item) =>
          item.isValid && (item.children ? validateItems(item.children) : true)
      );
    };
    return validateItems([
      ...guestHook.constraints.children,
      ...guestHook.constraints.adults,
      ...dateHook.constraints,
    ]);
  }, [guestHook.constraints, dateHook.constraints]);

  const canNext = isValid;

  const handleNextButtonClick = (e) => {
    if (!canNext) return;
    setTotalProgress(dispatch, 25);
    props.history.push(store.reservationState.reservationUrlToGo);
  };

  return (
    <GuestReservation>
      {loading ? (
        <Loading />
      ) : (
        <div className="d-inline-block">
          <GuestCounter hookData={guestHook} maxGuests={MAX_ALLOWED_GUESTS} />

          <br />

          <DateField
            weddingData={{
              minNights,
              weddingDate,
              weddingDateArray,
            }}
            hookData={dateHook}
          />

          <br />

          <div className="mt-3 text-center">
            <button
              disabled={!canNext}
              onClick={handleNextButtonClick}
              className={`c-btn ${!canNext ? "c-btn-disabled" : ""}`}
              data-testid="next-button"
              style={{ color: "white" }}
            >
              Next
            </button>
          </div>
        </div>
      )}
      <TravelAgreementModal
        handleAcceptTerms={handleAcceptTerms}
        handleOnSubModalClose={handleOnSubModalClose}
        showSubModal={showSubModal}
      />
    </GuestReservation>
  );
};
export default SelectReservationDates;
