import Rsvp from './rsvp';
import {Row, Col} from 'reactstrap';
import GuestList from './guestList';
import GroupLeaderWedding from './wedding';
import React,{useState,useEffect} from 'react';
import Loading from '../../../../components/Loading';
import DashboardLayout from '../../../../layouts/Dashboard';
import GroupProgress from '../../../../components/GroupProgress';

const GroupLeader = (props) => {
  
  const [tabItem, setTabItem ] = useState(undefined);

  useEffect(()=>{
      let item;
      switch (props.type) {
        case "wedding":
          item =  <GroupLeaderWedding />;
          break;

        case "rsvps":
          item = <Rsvp />
          break;

        case "guestlist":
          item =  <GuestList />;
          break;

        default:
          item = <Loading/>;

      }
      setTabItem(item);
  },[props.type]);

    return(
        <DashboardLayout>
            <Row>
                <Col xl="7">
                    <div className="c-card-layout">
                    {tabItem}
                    </div>
                </Col>
                <Col xl="5" className="mt-5 mt-lg-0">
                    <div className="c-card-layout">
                      <GroupProgress/>
                        
                    </div>
                </Col>
        </Row>
      </DashboardLayout>
    )
}
export default GroupLeader;