import moment from "moment";

export function addDays(days, target = new Date()) {
  try {
    const date = moment(target);
    date.add(days, "days");
    return date.toDate();
  } catch (err) {
    console.error(err);
  }
}

export function formatDate(date, format) {
  try {
    return moment(date).format(format);
  } catch (err) {
    console.error(err);
  }
}

export function isBefore(targetDate, compareDate) {
  try {
    const a = moment(targetDate);
    const b = moment(compareDate);
    return a.isBefore(b);
  } catch (err) {
    console.error(err);
  }
}

export function differenceInTime(start, end, param) {
  try {
    const a = moment(start);
    const b = moment(end);
    let total = b.diff(a, param);
    total = Object.is(NaN, total) ? 0 : total;
    return total;
  } catch (err) {
    console.error(err);
  }
}

export function differenceInDays(start, end) {
  try {
    return differenceInTime(start, end, "days");
  } catch (err) {
    console.log(err);
  }
}

export function differenceInYears(start, end) {
  try {
    return differenceInTime(start, end, "years");
  } catch (err) {
    console.error(err);
  }
}

export function getQueryStringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  //URLSearchParams is case sensitive, so if it had groupid and we search for groupId, no match
  //therefore we need to make it all lower case, and do our search, using lowercase
  const lowerCasedUrlParams = new URLSearchParams(
    [...urlParams].map(([key, value]) => [key.toLowerCase(), value])
  );
  const keyValue = lowerCasedUrlParams.get(key.toLowerCase());
  return keyValue;
}

export const isAtLeastTwoDaysBefore = (dayToCheckIn, dayOfWedding) => {
  const differenceInDays = moment(dayOfWedding).diff(
    moment(dayToCheckIn),
    "days"
  );
  return differenceInDays >= 2;
};
