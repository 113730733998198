import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL;

const get = (url, params) => {
  return axios.get(`${baseUrl}${url}`, { params });
};

const post = (url, data) => {
  return axios.post(`${baseUrl}${url}`, data);
};

const put = (url, data) => {
  return axios.put(`${baseUrl}${url}`, data);
};

const del = (url, data) => {
  return axios.delete(`${baseUrl}${url}`, data);
};

const all = (requests) => {
  return axios.all(requests);
};

const spread = (callback) => {
  return axios.spread(callback);
};

const api = {
  get,
  post,
  put,
  delete: del,
  all,
  spread,
};

export default api;
