import React from 'react';
import {Row, Col} from 'reactstrap';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

import 'pure-react-carousel/dist/react-carousel.es.css';


// import image1 from '../../assets/images/wedding-image-1.jpg';
// import image2 from '../../assets/images/wedding-image-2.jpg';
// import image3 from '../../assets/images/wedding-image-3.jpg';
// import image4 from '../../assets/images/wedding-image-4.jpg';
// import image5 from '../../assets/images/wedding-image-5.jpg';
// import image6 from '../../assets/images/wedding-image-6.jpg';
// import image7 from '../../assets/images/wedding-image-7.jpg';
// import image8 from '../../assets/images/wedding-image-8.jpg';
// import image9 from '../../assets/images/wedding-image-9.jpg';
// import image10 from '../../assets/images/wedding-image-10.jpg';
// import image11 from '../../assets/images/wedding-image-11.jpg';
// import image12 from '../../assets/images/wedding-image-12.jpg';
// import image13 from '../../assets/images/wedding-image-13.jpg';
// import image14 from '../../assets/images/wedding-image-14.jpg';

import image from '../../assets/images/photo-side.jpg';


const ImageWidget = (props) => {
    return (
        <div className={`c-image-vertical-slider ${props.blur?'overlay':''}`}>
            <img src={image} alt="masonry-image" className={props.blur?'blur':''}/>
        </div>
    );
}
export default ImageWidget;