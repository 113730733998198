import React, { useState } from 'react';
const InputCounter = ({ onRemove, onAdd, limit, count, min = 0 }) => {

    const [currentCount, setCurrentCount] = useState(count);

    const handleInputDecrease = () => {
        let _currentCount = currentCount - 1;
        setCurrentCount(_currentCount);
        onRemove && onRemove(_currentCount);
    }

    const handleInputIncrease = () => {
        let _currentCount = currentCount + 1;
        setCurrentCount(_currentCount);
        onAdd && onAdd(_currentCount);
    }

    return (
        <div className="c-input-counter">
            <button onClick={handleInputDecrease} disabled={currentCount === min}>
                <svg width="16" height="3" viewBox="0 0 16 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.533203" y="0.933594" width="14.9333" height="1.57193" rx="0.785964" fill="#00BFC8" />
                </svg>
            </button>
            <input type="text" min={min} max={limit} value={currentCount} disabled className="count" />
            <button onClick={handleInputIncrease} disabled={currentCount >= limit}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.99962 0.533203C7.56554 0.533203 7.21366 0.885092 7.21366 1.31917V7.21378H1.31917C0.885092 7.21378 0.533203 7.56567 0.533203 7.99975C0.533203 8.43382 0.885092 8.78571 1.31917 8.78571H7.21366V14.6806C7.21366 15.1146 7.56554 15.4665 7.99962 15.4665C8.4337 15.4665 8.78558 15.1146 8.78558 14.6806V8.78571H14.6806C15.1146 8.78571 15.4665 8.43382 15.4665 7.99975C15.4665 7.56567 15.1146 7.21378 14.6806 7.21378H8.78558V1.31917C8.78558 0.885092 8.4337 0.533203 7.99962 0.533203Z" fill="#00BFC8" />
                </svg>
            </button>
        </div>
    )
}
export default InputCounter;