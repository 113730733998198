import api from "utils/api";
import { Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import Authhelper from "../../../core/authHelper";
import Loading from "../../../components/Loading";
import ReactInput from '../../../components/InputField';

const UserProfile = (props) => {
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(true);

    const [inputs, setInputs] = useState({
        Id: 0,
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
    });

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            api.get('/api/profile/getProfileInformationByEmail?email=' + AUTHENTICATED_EMAIL).then(response => {
                let _data = response.data;
                setInputs({
                    Id: typeof _data.Id !== "undefined" ? _data.Id : 0,
                    address1: typeof _data.address1 !== "undefined" ? _data.address1 : "",
                    address2: typeof _data.address2 !== "undefined" ? _data.address2 : "",
                    city: typeof _data.city !== "undefined" ? _data.city : "",
                    state: typeof _data.state !== "undefined" ? _data.state : "",
                    zip: typeof _data.zip !== "undefined" ? _data.zip : ""
                })
                setLoader(false);
            }).catch(error => { setLoader(false); });
        })
    }, []);

    const handleInputChange = (e) => {
        e.persist();
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    }

    const handleValidation = () => {
        let errors = {};
        if (!inputs.address1) errors.address1 = "Address1 is required";
        if (!inputs.address2) errors.address2 = "Address2 is required";
        if (!inputs.state) errors.state = "State is required";
        if (!inputs.city) errors.city = "City is required";
        if (!inputs.zip) errors.zip = "Zip is required";
        setErrors(errors);
        return errors;
    }

    const handleProfileUpdate = (e) => {
        e.preventDefault();
        const errors = handleValidation();

        if (Object.keys(errors).length === 0) {
            setLoader(true);
            Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
                api.post('/api/profile/updateProfile', {
                    address1: inputs.address1,
                    address2: inputs.address2,
                    city: inputs.city,
                    state: inputs.state,
                    zip: inputs.zip,
                    email: AUTHENTICATED_EMAIL
                }).then(response => {
                    setLoader(false);
                    props.responseCallback(response.status === 200);
                }).catch(error => {
                    setLoader(false);
                    props.responseCallback(false);
                });
            })
        }
        else return false;
    }

    return (
        <div type="layout-2">
            {loader && <Loading />}
            {!loader && <>
                <div className="w-100">
                    <Row className="d-flex justify-content-center">
                        <Col lg="12">
                            <form className="c-form" onSubmit={handleProfileUpdate}>
                                <ReactInput value={inputs.address1} type="text" label="Address1" name="address1" error={errors && errors.address1} onChange={handleInputChange} />
                                <ReactInput value={inputs.address2} type="text" label="Address2" name="address2" error={errors && errors.address2} onChange={handleInputChange} />
                                <ReactInput value={inputs.city} type="text" label="City" name="city" error={errors && errors.city} onChange={handleInputChange} />
                                <ReactInput value={inputs.state} type="text" label="State" name="state" error={errors && errors.state} onChange={handleInputChange} />
                                <ReactInput value={inputs.zip} type="text" label="Zip" name="zip" error={errors && errors.zip} onChange={handleInputChange} />

                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="c-btn c-btn--ghost c-btn--ghost__primary">Submit</button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
            </>}
        </div >
    )
}

export default UserProfile;