import api from "utils/api";
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Authhelper from "../../core/authHelper";
import { ReactComponent as DashboardIcon } from '../../assets/images/icon-dashboard.svg';
import { ReactComponent as HelpIcon } from '../../assets/images/icon-help.svg';
import { ReactComponent as SettingIcon } from '../../assets/images/icon-settings.svg';
import { ReactComponent as RingIcon } from "../../assets/images/icon-ring.svg";
import { ReactComponent as DollarIcon } from "../../assets/images/icon-dollar.svg";
import { ReactComponent as UserIcon } from "../../assets/images/icon-users.svg";
import { ReactComponent as HotelIcon } from "../../assets/images/icon-hotel.svg";
import { ReactComponent as RoomIcon } from "../../assets/images/icon-room.svg";
import { ReactComponent as FlightIcon } from "../../assets/images/icon-flight.svg";
import { ReactComponent as TransferIcon } from "../../assets/images/icon-transfer.svg";
import { ReactComponent as RsvpsIcon } from "../../assets/images/icon-rsvps.svg";
import { ReactComponent as BookListIcon } from "../../assets/images/icon-book-list.svg";
import { ReactComponent as LockIcon } from "../../assets/images/icon-lock.svg";

const DefaultMenu = [
    {
        name: 'guest',
        target: "/guest",
        icon: <DashboardIcon />,
        title: "Guest Dashboard",
        parent: "/guest",
        authorized: Authhelper.IsGuestRoleAuthorized(),
        children: [
            {
                target: "/wedding",
                icon: <RingIcon />,
                title: "Wedding"
            },
            //{
            //    target: "/summary",
            //    icon: <DollarIcon />,
            //    title: "Summary"
            //},
            //{
            //    target: "/guests",
            //    icon: <UserIcon />,
            //    title: "Guests"
            //},
            //{
            //    target: "/flight",
            //    icon: <FlightIcon />,
            //    title: "Flight"
            //},
            //{
            //    target: "/resort",
            //    icon: <HotelIcon />,
            //    title: "Resort"
            //},
            //{
            //    target: "/room",
            //    icon: <RoomIcon />,
            //    title: "Room"
            //},
            //{
            //    target: "/transfer",
            //    icon: <TransferIcon />,
            //    title: "Transfer"
            //}
        ]
    },
    {
        name: 'groupLeader',
        target: "/groupleader",
        icon: <DashboardIcon />,
        title: "Wedding Dashboard",
        parent: "/groupleader",
        authorized: Authhelper.IsGroupLeaderRoleAuthorized(),
        children: [
            {
                target: "/wedding",
                icon: <RingIcon />,
                title: "Wedding"
            },
            //{
            //    target: "/rsvps",
            //    icon: <RsvpsIcon />,
            //    title: "RSVPs"
            //},
            //{
            //    target: "/guestlist",
            //    icon: <BookListIcon />,
            //    title: "Guest List"
            //},
        ]
    },
    {
        name: 'admin',
        target: "/admin",
        icon: <DashboardIcon />,
        title: "Admin Dashboard",
        parent: "/admin",
        authorized: Authhelper.IsAdminRoleAuthorized(),
        children: [
            {
                target: "/users",
                icon: <UserIcon />,
                title: "Users"
            }
        ]
    },
    {
        name: 'support',
        target: "/contact-support",
        icon: <HelpIcon />,
        title: "Support",
        authorized: true,
    },
    //{
    //    name: 'setting',
    //    target: "/",
    //    icon: <SettingIcon />,
    //    title: "Settings",
    //    authorized: true,
    //}
]

const DashboardSideMenu = (props) => {
    const [isMenuRendered, setIsMenuRendered] = useState(false);
    const [guestUnlockedMenu, setGuestUnlockedMenu] = useState(["Wedding", "Summary", "Guests", "Flight", "Resort", "Room", "Transfer"]);
    const [groupLeaderUnlockedMenu, setGroupLeaderUnlockedMenu] = useState(["Wedding", "RSVPs", "Guest List"]);

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            if (typeof AUTHENTICATED_EMAIL === "undefined" || AUTHENTICATED_EMAIL === null) {
                Authhelper.Logout('/user-notfound');
            }
            api.get('/api/room/getRoomDetailByEmail?email=' + AUTHENTICATED_EMAIL).then(response => {
                setIsMenuRendered(true);
                let _roomId = null;
                let _roomInfo = response.data.roomInfo;
                if (_roomInfo !== null && typeof _roomInfo !== 'undefined') {
                    _roomId = _roomInfo[0].id;
                }

                if (_roomId === null || typeof _roomId === 'undefined' || _roomId === "") {
                    setGuestUnlockedMenu(["Wedding", "Resort"]);
                    setGroupLeaderUnlockedMenu([]);
                }
            }).catch(error => { setIsMenuRendered(true); });
        })
    }, []);

    const renderMenuItem = (item) => {
        let isLocked = false;
        return item.children.map((child, i) => {
            if (item.name === "guest") {
                isLocked = !guestUnlockedMenu.includes(child.title);
            }

            if (item.name === "groupLeader") {
                isLocked = !groupLeaderUnlockedMenu.includes(child.title);
            }

            if (isLocked)
                return (<li key={item.title + "_" + i}>
                    <NavLink className="menu-lock" exact to={item.parent + child.target} onClick={() => { props.onMenuClicked() }}>
                        <span className="icon">
                            {<LockIcon />}
                        </span>
                        <span className="text">
                            {child.title}
                        </span>
                    </NavLink>
                </li>)
            else
                return (<li key={item.title + "_" + i}>
                    <NavLink exact to={item.parent + child.target} onClick={() => { props.onMenuClicked() }}>
                        <span className="icon">
                            {child.icon}
                        </span>
                        <span className="text">
                            {child.title} {isLocked}
                        </span>
                    </NavLink>
                </li>)
        })
    }


    return (
        <div className="c-sidemenu">
            <div className="sidemenu-links">
                <ul>
                    {isMenuRendered && DefaultMenu.map((item, i) => {
                        return (<li key={i}>
                            {item && item.authorized && item.children ?
                                <div className="sidemenu-dropdown">
                                    <NavLink to={item.target} className="menu-item">
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                        <span className="text">
                                            {item.title}
                                        </span>
                                    </NavLink>
                                    <div className="menu-list">
                                        <ul>
                                            {renderMenuItem(item)}
                                        </ul>
                                    </div>
                                </div> : item.authorized ?
                                    <NavLink exact to={item.target} className="menu-item" onClick={() => { props.onMenuClicked() }}>
                                        <span className="icon">
                                            {item.icon}
                                        </span>
                                        <span className="text" >
                                            {item.title}
                                        </span>
                                    </NavLink> : <span></span>
                            }
                        </li>);
                    })
                    }
                </ul>
            </div>
        </div>
    )
}

export default DashboardSideMenu;