//stripe payment
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const SECURE_RESERVATION = "SECURE_RESERVATION";

//error messages
export const ERROR_GENERAL = "Whoops! I'm not sure what was supposed to happen, but it definitely wasn't that! Please try again later or contact support.";
//export const ERROR_BOOKING_ROOMS_NOT_FOUND = "We're sorry, but it looks like there was no availability for the selected date range, please try again with different dates. If this error persists, the resort may be sold out or your dates may be too far in the future.";
export const ERROR_BOOKING_ROOMS_NOT_FOUND = "Rooms are not available for the date range or occupancy requested. This may be due to minimum night stay requirements from the hotel. Please try a new search. If this error persists, the resort may be sold out or your dates may be too far in the future.";

//email regex validator
// eslint-disable-next-line
// export const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Statista most popular top-level domains worldwide and a few others - https://www.statista.com/statistics/265677/number-of-internet-top-level-domains-worldwide/
export const EMAIL_VALIDATION_REGEX = /^[^\s@]+@[^\s@]+\.(?:com|net|org|edu|gov|mil|biz|info|co|au|ca|br|ht|es|kw|jm|do|us|fr|it|uk|mx|ru|de|se|biz|io)$/i;