import React from 'react';
import { Col, Row } from 'reactstrap';
import ImageWidget from './ImageWidget';

const MainScreen = (props) => {
    return(
        <div className="page-main-screen">
            <Row>
                
                <Col lg={{size:6, order:2}} >
                    <ImageWidget blur={props.blur}/>
                </Col>
                <Col lg={{size:6, order:1}} >
                    {props.children}
                </Col>
            </Row>
        </div>
    )
}

export default MainScreen;