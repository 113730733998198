import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

import api from "utils/api";

import CoreHelper from "core/coreHelper";
import { ReservationHelper } from "core/reservationHelper";

import { setTotalProgress } from "context/actions/reservationAction";
import { useStore } from "context/store";

import GuestReservation from "../reservationLayout";
import ReactInput from "components/InputField";
import Loading from "components/Loading";
import { ReactComponent as TravelersIcon } from "assets/images/icon-users.svg";

import AdultForm from "./AdultForm";
import ChildForm from "./ChildForm";

import { validateChildForm, validateAdultForm } from "./util";

const SelectReservationRoomArrangements = (props) => {
  const [, dispatch] = useStore();
  const [adults, setAdults] = useState(() => ReservationHelper.GetAdults());
  const [childs, setChilds] = useState(() => ReservationHelper.GetChilds());
  const [adultErrors, setAdultErrors] = useState([]);
  const [childErrors, setChildErrors] = useState([]);
  const [loader, setLoader] = useState(false);
  const [roomType, setRoomType] = useState("");
  const [roomTypeError, setRoomTypeError] = useState("");
  const [location, setlocation] = useState(false);

  useEffect(() => {
    // if (!Authhelper.IsAuthenticated() && ReservationHelper.GetEnableDashboardFlow() === 'true') { props.history.push('/login?returnurl=/guest/reservation/room-arrangements') };
    const location = props.location.pathname;
    setlocation(location.includes("request-room"));
  }, [props.location]);

  useEffect(() => {
    if (adults.length > 0 || childs.length > 0) {
      setTotalProgress(dispatch, 75);
    } else {
      setTotalProgress(dispatch, 50);
    }
  }, [adults, childs]);

  const handleRoomTypeChange = (event) => {
    if (!event.target.value)
      setRoomTypeError(
        "Please give us a few details about the kind of room accommodations you are looking for."
      );
    else setRoomTypeError("");
    setRoomType({ [event.target.name]: event.target.value });
  };

  const isAdultFormValid = (_dupNames) => {
    const validationErrors = validateAdultForm(adults, _dupNames);
    setAdultErrors(validationErrors);
    return validationErrors.every(
      (e) =>
        Object.keys(e).filter((k) => k.toLowerCase() !== "index").length <= 0
    );
  };

  const isChildFormValid = (_dupNames) => {
    const validationErrors = validateChildForm(childs, _dupNames);
    setChildErrors(validationErrors);
    return validationErrors.every(
      (e) =>
        Object.keys(e).filter((k) => k.toLowerCase() !== "index").length <= 0
    );
  };

  const handleNext = async (e) => {
    const location = props.location.pathname;

    clearNextSteps();
    completeRoomArrangement();

    // test for identicle names and if found provide warning
    const allTravelerNames = [
      ...adults.map(
        (a) =>
          `${(a.adultFirstName || "").toLowerCase()}${(
            a.adultMiddleName || ""
          ).toLowerCase()}${(a.adultLastName || "").toLowerCase()}`
      ),
      ...childs.map(
        (a) =>
          `${(a.childFirstName || "").toLowerCase()}${(
            a.childMiddleName || ""
          ).toLowerCase()}${(a.childLastName || "").toLowerCase()}`
      ),
    ];

    const detectedDuplicatePassengerNames = Array.from(
      new Set(allTravelerNames.filter((name, i, ar) => i !== ar.indexOf(name)))
    );
    const adultEnteredDetailsAreValid = isAdultFormValid(
      detectedDuplicatePassengerNames
    );

    //only go through validating children, if we have any. otherwise, default to valid
    const childEnteredDetailsAreValid =
      childs.length > 0
        ? isChildFormValid(detectedDuplicatePassengerNames)
        : true;

    //update state
    if (adults.length > 0) ReservationHelper.SetAdults(adults);
    if (childs.length > 0) ReservationHelper.SetChilds(childs);

    if (adultEnteredDetailsAreValid && childEnteredDetailsAreValid) {
      setLoader(true);
      if (!location.includes("request-room")) {
        props.history.push("/guest/reservation/confirmation");
        return;
      }
      if (!roomType) {
        setRoomTypeError(
          "Please give us a few details about the kind of room accommodations you are looking for."
        );
        return;
      }

      let manualRoomingRequest = {};
      //if we got here, we didnt have any availablity results, so we're going to dislpay the manual room request form
      try {
        /*
          AT 04-16-2024: change the nightmare to a more understandable payload/request for what ends up just beign a HD ticket being created
          New less schizo request object:
          public class ManualRoomingRequest
          {
            public string GroupId { get; set; }
            public string SupplierCode { get; set; }
            public string StartDate { get; set; }
            public string EndDate { get; set; }
            public string TravelDate { get; set; }
            public string RequestDetails { get; set; }
            public int DesiredTotalNightsStay { get; set; }
            public string AdultTraveler1Email { get;set; }
            public string AdultTraveler1Name { get; set; }
          }

        */

        let localStorageHotelInformation =
          ReservationHelper.GetHotelsInformationFromLocalStorage();
        if (localStorageHotelInformation) {
          try {
            localStorageHotelInformation = JSON.parse(
              localStorageHotelInformation
            );
          } catch {
            localStorageHotelInformation = null;
          }
        }

        let localStorageReservationInformation =
          ReservationHelper.GetReservation();

        manualRoomingRequest = {
          groupId:
            localStorageReservationInformation?.groupId ||
            localStorageHotelInformation?.groupId,
          supplierCode: localStorageReservationInformation?.supplierCode,
          startDate: localStorageReservationInformation?.startDate,
          endDate: localStorageReservationInformation?.endDate,
          travelDate: localStorageReservationInformation?.travelDate,
          requestDetails: null,
          desiredTotalNightsStay:
            localStorageReservationInformation?.totalNight || 0,
          adultTraveler1Email: adults[0].adultEmail,
          adultTraveler1Name: `${adults[0].adultFirstName} ${adults[0].adultMiddleName} ${adults[0].adultLastName}`,
        };
        const asteriskSeparator = `${"*".repeat(50)}\n\n`;
        manualRoomingRequest.requestDetails = asteriskSeparator;
        manualRoomingRequest.requestDetails += `Guest Message:\n${roomType.roomType}\n`;
        manualRoomingRequest.requestDetails += asteriskSeparator;
        let groupLeaderEmail = localStorage.getItem("groupLeaderEmail");
        if (groupLeaderEmail) {
          manualRoomingRequest.requestDetails += `Group Leader Email: ${groupLeaderEmail}\n\n`;
          manualRoomingRequest.requestDetails += asteriskSeparator;
        }
        manualRoomingRequest.requestDetails += `Total Adults: ${adults.length}\n`;
        manualRoomingRequest.requestDetails += `Adult Traveler Details:\n${JSON.stringify(
          adults,
          null,
          "\t".repeat(4)
        )}\n`;
        manualRoomingRequest.requestDetails += asteriskSeparator;
        if (childs?.length) {
          manualRoomingRequest.requestDetails += `\nTotal Children: ${childs.length}\n\n`;
          manualRoomingRequest.requestDetails += `Child Traveler Details:\n${JSON.stringify(
            childs,
            null,
            "\t".repeat(4)
          )}\n`;
        }
        manualRoomingRequest.requestDetails += asteriskSeparator;
        manualRoomingRequest.requestDetails += `Additional Diagnostic Details (if available):\n\n`;
        manualRoomingRequest.requestDetails += asteriskSeparator;

        if (localStorageHotelInformation) {
          manualRoomingRequest.requestDetails += `Hotel(s) Information:\n${JSON.stringify(
            localStorageHotelInformation,
            null,
            "\t".repeat(4)
          )}\n`;
          manualRoomingRequest.requestDetails += asteriskSeparator;
        }
        //strip out json serialization artifacts
        manualRoomingRequest.requestDetails =
          manualRoomingRequest.requestDetails
            .replace(/[{}[\]]/g, "")
            .replace(/,/g, "");
        ReservationHelper.SetSelectedRoom(roomType.roomType);
        await api.post("/api/payment/RequestRoom", manualRoomingRequest);
        props.history.push("/guest/reservation/confirmation/room-request");
      } catch (error) {
        try {
          const jsError = {
            ErrorMessage: "Unknown Error",
            Transaction: `Posted Payload: ${JSON.stringify(
              manualRoomingRequest
            )}\nlocalStorage contents:${JSON.stringify({ ...localStorage })}`,
            StackTrace: `${JSON.stringify(Error().stack)}`,
            ErrorName:
              "Unexpected failure invoking '/api/payment/RequestRoom' (manual room request)",
            Datetime: new Date(),
          };
          await api.post(
            "/api/JSTransactionError/postJSTransactionError",
            jsError
          );
        } catch {
          /*nothing to be done :(*/
        }

        //poor man's error handling without roping in the overhead of a non-working modal component
        alert(
          "❌ An unexpected error was encountered and we've been notified.\n\nPlease try again or if you continue having issues, please use the\n💬 live chat feature, in the bottom right of your screen.\n\nAlternatively, please reach out to\n✉️ GuestServices@destify.com\n\nWe apologize for any inconvenience 🙏."
        );
      } finally {
        setLoader(false);
      }
      return;
    }
  };

  const completeRoomArrangement = () => {
    const adultTravelers = ReservationHelper.GetAdults();
    const childTravelers = ReservationHelper.GetChilds();
    ReservationHelper.SetAdultsCount(adultTravelers.length);
    ReservationHelper.SetChildCount(childTravelers.length);
  };

  const clearNextSteps = () => {
    ReservationHelper.SetSelectedPaymentAmount(0);
  };

  return (
    <GuestReservation>
      {loader && <Loading />}
      {!loader && (
        <div className="page-room-arangement">
          <h4>Who is going to be staying in the room?</h4>
          <br />
          <p>
            <strong>
              Please enter the names and birthdates of all travelers exactly as
              it appears on the person's passport. Failure to do so can result
              in a $150 name change fee per occurrence and may result in a new
              package price should the need arise to change a traveler's name on
              the reservation. Do not enter nicknames, an alias, duplicate names
              or placeholders, as they are not accepted for international
              travel.
            </strong>
          </p>
          <div className="counter-wrapper">
            <div>
              {adults.length <= 1
                ? adults.length + " Adult "
                : adults.length + " Adults "}
              {childs.length === 1
                ? childs.length + " Child "
                : childs.length + " Children "}
              <Link
                to="/guest/reservation/select-date"
                onClick={() => setTotalProgress(dispatch, 0)}
              >
                Change number of travelers
              </Link>
            </div>
          </div>

          {location && (
            <>
              <Row>
                <Col lg={6} className="mt-3">
                  <form className="c-form m-t-20">
                    <ReactInput
                      value={roomType.roomType}
                      className="form-control col-md-6"
                      required
                      name="roomType"
                      useTextArea
                      labelStyles={{ fontWeight: "bold" }}
                      textAreaStyles={{ height: "auto", padding: "1%" }}
                      label="Please tell us a little about your desired room accommodations:"
                      error={roomTypeError}
                      onChange={handleRoomTypeChange}
                    />
                  </form>
                </Col>
              </Row>
              <hr style={{ marginTop: 0, marginBottom: "1rem" }} />
              <p style={{ fontWeight: "bold" }} className="mb-4">
                <TravelersIcon /> Traveler Information:
              </p>
            </>
          )}

          <Row className="pl-1 pr-1">
            <AdultForm
              manualRoomingRequest={props.location.pathname.includes(
                "request-room"
              )}
              adults={adults}
              setAdults={setAdults}
              adultErrors={adultErrors}
              setAdultErrors={setAdultErrors}
            />
          </Row>

          <hr />

          <Row>
            <ChildForm
              childs={childs}
              setChilds={setChilds}
              childErrors={childErrors}
              setChildErrors={setChildErrors}
            />
          </Row>

          {(adults.length > 0 || childs > 0) && (
            <button onClick={(e) => handleNext(e)} className="c-btn">
              Next
            </button>
          )}
        </div>
      )}
    </GuestReservation>
  );
};
export default SelectReservationRoomArrangements;
