import api from "utils/api";
import { Row, Col } from "reactstrap";
import React, { useEffect, useState } from 'react';
import Accordion from "../../../../components/Accordion";
import DashboardLayout from "../../../../layouts/Dashboard";
import GuestWedding from "./wedding";
import GuestResort from "./resort";
import GuestRoom from "./room";
import GuestFlight from "./flight";
import GuestTransfer from "./transfer";
import GuestSummary from "./summary";
import GuestGuests from "./guests";
import Loading from "../../../../components/Loading";

const GuestPage = (props) => {
  const [faqs, setFaqs] = useState([]);

  const [tabItem, setTabItem ] = useState(undefined);

  useEffect(()=>{
      let item;
      switch (props.type) {
        case "wedding":
          item =  <GuestWedding />;
          break;
        //case "summary":
        //  item =  <GuestSummary />;
        //  break;
        //case "guests":
        //  item = <GuestGuests/>;
        //  break;
        //case "resort":
        //  item = <GuestResort/>;
        //  break;
        //case "room":
        //  item = <GuestRoom/>;
        //  break;
        /* case 'flight':
          item=<GuestFlight/>;
          break; */
        /* case 'transfer':
          item=<GuestTransfer/>;
          break; */
        default:
          item = <Loading/>;
          break;
      }
      setTabItem(item);
  },[props.type]);

  useEffect(() => {
    api.get('/api/faq/gatFaqs')
      .then(response => {
        setFaqs(response.data);
      }).catch(error => { });
  }, []);

  return (
    <DashboardLayout>

      <Row>
        <Col xl="8">
          <div className="c-card-layout">
            {tabItem}
          </div>
        </Col>

        <Col xl="4" className="mt-5 mt-lg-0">
          <div className="c-card-layout">
            <h5 className="header">FAQ</h5>
            <Accordion lists={faqs} />
          </div>
        </Col>

      </Row>
      
    </DashboardLayout>
  );
};
export default GuestPage;
