import { useEffect, useState } from "react";

import { ReservationHelper } from "core/reservationHelper";

import { isBefore, differenceInYears } from "../utils";

const MAX_ALLOWED_AGE = 18;

const minDate = (() => {
  const date = new Date();
  date.setFullYear(new Date().getFullYear() - MAX_ALLOWED_AGE);
  return date.toISOString().split("T")[0];
})();
const maxDate = new Date().toISOString().split("T")[0];

const createChildObject = (id) => ({
  childFirstName: null,
  childLastName: null,
  childEmail: null,
  childPhone: null,
  childDob: null,
  childAge: 1,
  childGender: "",
  index: id,
});

const createAdultObject = () => ({
  adultFirstName: null,
  adultLastName: null,
  adultEmail: null,
  adultPhone: null,
  adultDob: null,
  adultGender: "",
});

export const useGuest = (MAX_ALLOWED_GUESTS) => {
  const [constraints, setConstraints] = useState({ children: [], adults: [] });
  const [children, setChildren] = useState(() => {
    return ReservationHelper.GetChilds();
  });
  const [adults, setAdults] = useState(() => {
    const result = ReservationHelper.GetAdults();
    if (!result.length) return [createAdultObject()];
    return result;
  });

  useEffect(() => {
    ReservationHelper.SetAdults(adults);
    ReservationHelper.SetAdultsCount(adults.length);
  }, [adults]);

  useEffect(() => {
    ReservationHelper.SetChilds(children);
    ReservationHelper.SetChildCount(children.length);
  }, [children]);

  const addAdult = () => {
    setAdults((prev) => [...prev, createAdultObject()]);
  };

  const removeAdult = () => {
    setAdults((prev) => {
      if (prev.length <= 1) return prev;
      return prev.slice(0, -1);
    });
  };

  const addChild = () => {
    setChildren((prev) => [...prev, createChildObject(prev.length)]);
  };

  const removeChild = () => {
    setChildren((prev) => prev.slice(0, -1));
  };

  const validateChildDoB = (child) => {
    let _minDateString = new Date(minDate).toLocaleString().split(",")[0];
    const validationError = {
      message: "",
      isValid: false,
    };
    // validationError.index = child.index;
    if (!child.childDob) {
      validationError.message = `child #${
        child.index + 1
      }: Date of Birth is required`;
    } else if (child.childDob == "Invalid Date") {
      validationError.message = `child #${child.index + 1}: invalid DOB`;
    } else if (isBefore(new Date(child.childDob), new Date(minDate))) {
      validationError.message = `child #${
        child.index + 1
      }: DOB must be after ${_minDateString}`;
    } else if (isBefore(new Date(maxDate), new Date(child.childDob))) {
      validationError.message = `child #${child.index + 1}: invalid DOB`;
    } else {
      validationError.isValid = true;
    }
    return validationError;
  };

  const validateChildAges = () => {
    const childrenErrors = children.map(validateChildDoB);
    setConstraints((prev) => ({
      ...prev,
      children: childrenErrors,
    }));
  };

  const handleChangeDate = (index) => (date) => {
    if (!(date instanceof Date)) return;
    setChildren((prev) => {
      return prev.map((child) => {
        if (child.index === index) {
          if (date == "Invalid Date") {
            child.childDob = "Invalid Date";
          } else {
            child.childDob = date.toISOString().split("T")[0];
            child.childAge = differenceInYears(date, new Date());
          }
        }
        return child;
      });
    });
  };

  useEffect(() => {
    validateChildAges();
  }, [children]);

  const hasExceededGuests = () => {
    const totalGuests = adults.length + children.length;
    return totalGuests >= MAX_ALLOWED_GUESTS;
  };

  const clearNextSteps = () => {
    ReservationHelper.SetSelectedRoom(null);
    ReservationHelper.SetChildCount(0);
    ReservationHelper.SetAdultsCount(0);
    ReservationHelper.SetSelectedPaymentAmount(0);
  };

  return {
    children,
    adults,
    addChild,
    addAdult,
    removeAdult,
    removeChild,
    minDate,
    maxDate,
    constraints,
    validateChildAges,
    handleChangeDate,
    hasExceededGuests,
    clearNextSteps,
  };
};
