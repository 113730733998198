import React, { useEffect, useState, useContext } from "react";
import { store } from "../../../../../../context/store";
import { Link } from "react-router-dom";
import AvailableRooms from "./availableRooms";
import GuestReservation from "../reservationLayout";
import { ReservationHelper } from "../../../../../../core/reservationHelper";
//import { setTotalProgress } from "../../../../../../context/actions/reservationAction";
import {
  setDates,
  setNextReservationUrl,
  setRooms,
  setTotalProgress,
} from "../../../../../../context/actions/reservationAction";
import Loading from "../../../../../../components/Loading";
import moment from "moment";

const SelectReservationRooms = (props) => {
  const [selectedRoomInfo, setSelectedRoomInfo] = useState(null);
  const [state, dispatch] = useContext(store);
  const [adults, setAdults] = useState(null);
  const [childs, setChilds] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setAdults(ReservationHelper.GetAdults());
    setChilds(ReservationHelper.GetChilds());
    setStartDate(ReservationHelper.GetStartDate());
    setEndDate(ReservationHelper.GetEndDate());
    ReservationHelper.SetSelectedRoom(null);
    setLoader(false);
  }, []);
  const getMMDDYYYFormat = (date) => {
    return moment.utc(date).format("MM-DD-YYYY");
  };

  return (
    <GuestReservation>
      {loader && <Loading />}
      {!loader && (
        <div>
          <h4>Select Room</h4>
          <br />
          <div>
            {adults.length <= 1
              ? adults.length + " Adult "
              : adults.length + " Adults "}
            {childs.length === 1
              ? childs.length + " Child "
              : childs.length + " Children "}
            <Link
              to="/guest/reservation/select-date"
              onClick={() => setTotalProgress(dispatch, 0)}
            >
              Change number of travelers
            </Link>
            <p>
              <span>
                {`${getMMDDYYYFormat(startDate)}`} -{" "}
                {`${getMMDDYYYFormat(endDate)}`}
              </span>{" "}
              <Link
                to="/guest/reservation/select-date"
                onClick={() => setTotalProgress(dispatch, 0)}
              >
                Change travel dates
              </Link>
            </p>
          </div>
          <br />
          <AvailableRooms
            {...props}
            setAdults={setAdults}
            setChilds={setChilds}
          />
        </div>
      )}
    </GuestReservation>
  );
};
export default SelectReservationRooms;
