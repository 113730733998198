//import { Row, Col } from "reactstrap";
//import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import Authhelper from "../../../../../core/authHelper";
//import Loading from "../../../../../components/Loading";
//import ArrowLeftIcon from "../../../../../assets/images/icon-chevron-left.svg";
//import ArrowRightIcon from "../../../../../assets/images/icon-chevron-right.svg";
//import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
//import { ReactComponent as PhotoIcon } from "../../../../../assets/images/icon-photo.svg";
//import { ReactComponent as ProgressIcon } from "../../../../../assets/images/icon-beach.svg";
import Logo from '../../../../../components/Logo';


const GuestWedding = () => {
  const [loader, setLoader] = useState(true);
  const [isRoomBooked, setIsRoomBooked] = useState(false);
  const [weddingDetail, setWeddingDetail] = useState({});
  const [itineraryDetail, setItineraryDetail] = useState({});

  //useEffect(async () => {
  //  Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
  //    if (typeof ROOM_ID !== "undefined" && ROOM_ID !== null) {
  //      setIsRoomBooked(true);
  //      _getWeddingDetailByRoomId(ROOM_ID);
  //    } else _getWeddingDetailByGroupId()
  //  })
  //}, []);


  //function _getWeddingDetailByRoomId(ROOM_ID) {
  //  api.all([
  //    api.get('/api/wedding/getWeddingDetailByRoomId?roomId=' + ROOM_ID),
  //    api.get('/api/Itinerary/getItineraryDetailByRoomId?roomId=' + ROOM_ID),
  //  ]).then(api.spread((room, itinerary) => {
  //    //room
  //    setWeddingDetail(room.data);

  //    //itinerary
  //    setItineraryDetail(itinerary.data);

  //    setLoader(false);
  //  })).catch(e => { setLoader(false); })
  //}

  //function _getWeddingDetailByGroupId() {
  //  Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
  //    api.get(`/api/wedding/getWeddingDetailByGroupId?email=${AUTHENTICATED_EMAIL}`)
  //      .then(response => {
  //        setWeddingDetail(response.data);
  //        setLoader(false);
  //      }).catch(error => { Authhelper.Logout("/login"); })
  //  })
  //}

  function calculateProgress() {
    if (typeof itineraryDetail.daysTillTravelDate !== "undefined") {
      let date1 = new Date();
      let date2 = new Date('01/01/2020'); //TODO:: Replace this hardcode

      let difference = date1.getTime() - date2.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      let passedDays = totalDays - itineraryDetail.daysTillTravelDate;

      let progressPercentage = Math.round((passedDays / totalDays) * 100);
      return progressPercentage + "%";
    }
  }

    return (
        <div className="c-auth-screen">
            <div className="auth-content">
                <div><Logo></Logo></div>
                <div>To get started, visit your couple's wedding website, click Book Now, and then Reserve A Room. </div>
                <br></br>
                <div>You can also contact us via email: guestservices@destify.com</div>
            </div>
        </div>
    //<div className="page-wedding">
    //  {loader && <Loading />}

    //  {!loader && <>
    //    <h5 className="header">{itineraryDetail.groupName}</h5>
    //    <Row>
    //      <Col xl="6">
    //        {weddingDetail && weddingDetail.formattedImages &&
    //          <div className="c-photo-block">
    //            <div className="photo">

    //              <CarouselProvider
    //                naturalSlideWidth={100}
    //                naturalSlideHeight={125}
    //                totalSlides={weddingDetail.formattedImages.length}
    //                isIntrinsicHeight={true}
    //                isPlaying={true}
    //                interval={3000}
    //              >
    //                <Slider>
    //                  {weddingDetail.formattedImages.map((item, i) => {
    //                    return <Slide index={i} key={i}><div className="slide-item-wrapper"> <img src={item} alt="Wedding Image" /></div></Slide>
    //                  })}


    //                </Slider>
    //                <ButtonBack className="carouselButton">
    //                  <img src={ArrowLeftIcon} alt="Back" />
    //                </ButtonBack>
    //                <ButtonNext className="carouselButton">
    //                  <img src={ArrowRightIcon} alt="Next" />
    //                </ButtonNext>
    //              </CarouselProvider>
    //            </div>
    //            <div className="icon">
    //              <PhotoIcon />
    //            </div>
    //          </div>
    //        }
    //        <div className="c-itinerary-progress">
    //          <div className="progress-bar">
    //            <div
    //              className="progress-icon"
    //              style={{ left: calculateProgress(), transform: "translateX(-30px)" }}
    //            >
    //              <ProgressIcon />
    //            </div>
    //            <div className="bar" style={{ width: calculateProgress() }}></div>
    //          </div>
    //          <div className="progress-time">
    //            <div className="counter">
    //              <div className="num">{itineraryDetail.daysTillTravelDate}</div>
    //            days left
    //          </div>
    //          </div>
    //        </div>
    //      </Col>
    //      <Col xl="6">


    //        <div className="c-border-box border-box-vertical">
    //          <div className="box-item">
    //            <div className="text-label">BRIDE'S NAME</div>
    //            <div className="text-title">{weddingDetail.brideName}</div>
    //          </div>
    //          <div className="box-item">
    //            <div className="text-label">GROOM'S NAME</div>
    //            <div className="text-title">{weddingDetail.groomName}</div>
    //          </div>
    //          <div className="box-item">
    //            <div className="text-label">WEDDING DATE</div>
    //            <div className="text-title">{weddingDetail.weddingDate?.split('T')[0]}</div>
    //          </div>
    //          <div className="box-item">
    //            <div className="text-label">WEDDING LOCATION</div>
    //            <div className="text-title">{weddingDetail.weddingLocation}</div>
    //          </div>
    //        </div>
    //      </Col>
    //    </Row>
    //    <div className="mt-2">
    //      <Row>
    //        <Col lg="6">
    //          <a href={weddingDetail.formattedWebsiteUrl} target="_blank" className="c-btn btn-block mb-2">
    //            View Website
    //        </a>
    //        </Col>
    //        <Col lg="6">
    //          {!isRoomBooked && <Link to="/guest/reservation/select-date" className="c-btn btn-block mb-2">Secure Reservation</Link>}
    //        </Col>
    //      </Row>
    //    </div>
    //  </>}
      
    //</div>
  );
};
export default GuestWedding;
