import React from 'react';
import ProgressIcon  from "../../assets/images/ptid-steps-thumb-logo.png";
import DreamLocation from '../../assets/images/location.png';
import WeddingDance from  '../../assets/images/wedding.png';
import MailBox from '../../assets/images/mail.png';
import Devices from '../../assets/images/devices.png';
import Flowers from '../../assets/images/flowers.png';
import Aeroplane from '../../assets/images/aeroplane.png';
const GroupProgress = () => {
    return(
        <div className="c-group-progress">
            <img src={ProgressIcon} alt="Progress Icon" />
            <div className="progress-bar">
                <div
                className="progress-icon"
                style={{ left: '20%', transform: "translateX(-30px)" }}
                >
                
                </div>
                <div className="bar" style={{ width: '20%' }}></div>

                
            </div>
            <div className="progress-steps">
                    <div className="step active">
                        <div className="content">
                            <div className="index">Step 1</div>
                            <img src={DreamLocation} alt="Dream Location"/>
                            Find Your Dream Location
                        </div>
                    </div>
                    <div className="step">
                        <div className="content">
                            <div className="index">Step 2</div>
                            <img src={WeddingDance} alt="Wedding"/>
                            Secure Reservation
                        </div>
                    </div>
                    <div className="step">
                        <div className="content">
                            <div className="index">Step 3</div>
                            <img src={MailBox} alt="Mail Box"/>
                            Send your RSVPs
                        </div>
                    </div>
                    <div className="step">
                        <div className="content">
                            <div className="index">Step 4</div>
                            <img src={Devices} alt="Devices"/>
                            Finalize guest bookings
                        </div>
                    </div>
                    <div className="step">
                        <div className="content">
                            <div className="index">Step 5</div>
                            <img src={Flowers} alt="Flowers"/>
                            Plan your wedding details
                        </div>
                    </div>
                    <div className="step">
                        <div className="content">
                            <div className="index">Step 6</div>
                            <img src={Aeroplane} alt="Aeroplane"/>
                            Pre-travel + Travel
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default GroupProgress;