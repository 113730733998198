import moment from "moment";

export const getMMDDYYYFormat = (date) => {
  return moment.utc(date).format("MM-DD-YYYY");
};

export const RemainingAmountDueDate = (checkInDate) => {
  const daysLeftBeforeDue = 65;
  const targetDate = moment(checkInDate)
    .subtract({ days: daysLeftBeforeDue })
    .format("MM/DD/YY");
  return targetDate;
};
