import React from 'react';
import SubMainScreen from '../../layouts/SubMainScreen'
import EnvelopeIcon from '../../assets/images/icon-envelope-success.svg';

const ErrorScreen = () => {
    return (
        <SubMainScreen>
            <div className="c-auth-screen">
                <div className="auth-content">

                    <div className="text-center mb-5">
                        <img src={EnvelopeIcon} alt="Success" />
                    </div>
                    <div className="auth-title text-center">Error</div>
                    <div className="description text-center ">
                        We have experienced an unknown error. If this occurred during booking, please check your email for confirmation. Otherwise please try your action again, or contact guestservices@destify.com .
                    </div>
                </div>
            </div>
        </SubMainScreen>
    )
}

export default ErrorScreen;