import CoreHelper from "../core/coreHelper";
import api from "utils/api";

const RESERVATION_STEP_PROGRESS = "RESERVATION_STEP_PROGRESS";
const HOTELS_INFORMATION_LOCAL_STORAGE_KEY = "HotelsInformation";
const ReservationHelper = {
  //See DWA-2918
  RESERVATION_STEPS: {
    SELECT_DATES: 0,
    SELECT_ROOMS: 25,
    ENTER_PASSENGER_INFO: 75,
  },
  GetReservationStepProgress: function () {
    const stepProgress = localStorage.getItem(RESERVATION_STEP_PROGRESS);
    if (stepProgress != null && stepProgress !== "undefined") {
      const step = +stepProgress;
      if (!isNaN(step)) {
        return step;
      }
    }
    this.SetReservationStepProgress(this.RESERVATION_STEPS.SELECT_DATES);
    return this.RESERVATION_STEPS.SELECT_DATES;
  },
  SetReservationStepProgress: function (STEP) {
    localStorage.setItem(RESERVATION_STEP_PROGRESS, STEP);
  },
  /*RemoveReservationStepProgress: function () {
    localStorage.removeItem(RESERVATION_STEP_PROGRESS);
  },*/
  ClearReservation: function () {
    localStorage.removeItem("Reservation");
  },
  GetReservation: function () {
    let reservation = JSON.parse(localStorage.getItem("Reservation"));
    return reservation === null || typeof reservation === "undefined"
      ? {}
      : reservation;
  },
  SetReservation: function (_reservation) {
    this.ClearReservation();
    localStorage.setItem("Reservation", JSON.stringify(_reservation));
  },

  SetAcceptTermsAndCondition: function (state) {
    const key = "Reservation";
    const result = localStorage.getItem(key);
    if (!result) return;
    const reservation = JSON.parse(result);
    if (!reservation) return;
    reservation.acceptedTermsAndConditions = state;
    this.SetReservation(reservation);
  },
  GetAcceptTermsAndCondition: function () {
    const key = "Reservation";
    const result = localStorage.getItem(key);
    if (!result) return false;
    const reservation = JSON.parse(result);
    if (!reservation) return false;
    return reservation.acceptedTermsAndConditions ?? false;
  },
  //select dates
  GetTotalNight: function () {
    let totalNight = JSON.parse(
      localStorage.getItem("Reservation")
    )?.totalNight;
    return totalNight !== null && typeof totalNight !== "undefined"
      ? totalNight
      : 5;
  },
  GetStartDate: function () {
    let startDate = JSON.parse(localStorage.getItem("Reservation"))?.startDate;
    //check for null or undefined (==) and ensure null or actual value.
    //just dont ever return undefined back
    return startDate == null ? null : startDate;
    //return startDate !== null && typeof startDate !== 'undefined' ? new Date(startDate) : null;
  },
  GetEndDate: function () {
    let endDate = JSON.parse(localStorage.getItem("Reservation"))?.endDate;

    //check for null or undefined (==) and ensure null or actual value.
    //just dont ever return undefined back
    return endDate == null ? null : endDate;
    //return endDate !== null && typeof endDate !== 'undefined' ? new Date(endDate) : null;
  },
  SetTotalNight: function (nights) {
    let reservation = this.GetReservation();
    reservation.totalNight = nights;
    this.SetReservation(reservation);
  },
  SetStartDate: function (date) {
    let reservation = this.GetReservation();
    if (!date) {
      reservation.startDate = null;
    } else {
      const preparedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      reservation.startDate = preparedDate.toISOString().split("T")[0];
    }
    this.SetReservation(reservation);
  },

  SetEndDate: function (date) {
    let reservation = this.GetReservation();
    if (!date) {
      reservation.endDate = null;
    } else {
      const preparedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      reservation.endDate = preparedDate.toISOString().split("T")[0];
    }
    this.SetReservation(reservation);
  },
  //set enable Dashboard Flow
  RemoveEnableDashboardFlow: function () {
    return localStorage.removeItem("enableDashboardFlow");
  },

  // Transfer Price during room availability
  GetTransferPrice: function () {
    let transferPrice = JSON.parse(
      localStorage.getItem("Reservation")
    )?.transferPrice;
    return transferPrice !== null && typeof transferPrice !== "undefined"
      ? transferPrice
      : null;
  },
  SetTransferPrice: function (_transferPrice) {
    let reservation = this.GetReservation();
    reservation.transferPrice = _transferPrice;

    this.SetReservation(reservation);
  },
  // Get/Set Selected transfer option from Static Transfer options on Conrimation Page
  GetSelectedTransferOption: function () {
    let selectedTransferOption = JSON.parse(
      localStorage.getItem("Reservation")
    )?.selectedTransferOption;
    return selectedTransferOption !== null &&
      typeof selectedTransferOption !== "undefined"
      ? selectedTransferOption
      : null;
  },
  SetSelectedTransferOption: function (_selectedTransferOption) {
    let reservation = this.GetReservation();
    reservation.selectedTransferOption = _selectedTransferOption;

    this.SetReservation(reservation);
  },
  GetHotelsInformationFromLocalStorage: function () {
    let hotelInfo = localStorage.getItem(HOTELS_INFORMATION_LOCAL_STORAGE_KEY);
    if (
      hotelInfo == null ||
      hotelInfo === "undefined" ||
      hotelInfo === "null"
    ) {
      return null;
    }
    return hotelInfo;
  },
  /**
   * AT 07/27/2022: Given a groupId, try and get its corresponding hotel information and store it in localStorage
   * @param {string} groupId
   */
  GetHotelsInformationAsync: async function (groupId, groupInfoData = null) {
    //if we don't have a groupId, this function can't do its job, so error out.
    if (!groupId || groupId === "null") {
      //print some helpful info, if we need to debug in the future
      const stack = new Error().stack;
      console.error(stack, "Missing required paramater 'groupId'");
      return null;
    }

    //declare our magic string as consts, to not mistype the keys in other places

    const ADULTS_ONLY_LOCAL_STORAGE_KEY = "AdultsOnly";

    try {
      //get group information
      if (!groupInfoData) {
        const { data } = await api.get(
          `/api/usergroup/getGroupInfoByGroupId?groupId=${groupId}`
        );
        groupInfoData = data;
      }

      //if we got group info
      if (groupInfoData) {
        //create a list of promises to get resort details
        const resortsHotelsDetailsArray = await api.all(
          groupInfoData.hotelIDs.map((resortId) =>
            api.get(
              `/api/resort/getResortDetailsByResortId?resortID=${resortId}`
            )
          )
        );

        //retrieve the hotelsInfo from the list of promises
        const hotelsInfoArray = resortsHotelsDetailsArray.map(
          ({ data, config }, idx) => {
            return {
              hotelName: data.name,
              address: data.address,
              description: data.description,
              email: data.email,
              website: data.website,
              airportCode: data.airportCode,
              algHotelCode: data.algHotelCode,
              bolHotelCode: data.bolHotelCode,
              hbsiHotelCode: data.hbsiHotelCode,
              crmHotelId: config.url.split("=")[1],
              adultsOnly: data.adultsOnly?.toLowerCase() === "yes",
              supplierCommissionList: data.supplierCommissionList,
              preferredSupplier: data.preferredSupplier,
              crmHotelsOrder: groupInfoData.hotelIDs,
              promoCode: groupInfoData.hotelPromoCodes[idx],
            };
          }
        );

        //stuff the hotels array into a wrapper object
        const hotelsInfoWrapper = { groupId, hotelsInfo: hotelsInfoArray };

        // set the hotels info wrapper into local storage, so we ge the info from there, the next time this function is invoked
        localStorage.setItem(
          HOTELS_INFORMATION_LOCAL_STORAGE_KEY,
          JSON.stringify(hotelsInfoWrapper)
        );

        // set destify flow
        this.SetEnableDashboardFlow(groupInfoData.enableDestifyDashboard);
        this.SetCategory(groupInfoData.category);
        this.SetGroupLeaderEmail(groupInfoData.groupLeaderEmail);

        //check & set to see if it's adults only
        //DWA-838: if all returned hotels are set to adults only, then consider this an adults only "set-up".
        //Otherwise, if just one is not flagged as adults only, then *dont* consider this an adults only "set-up".
        localStorage.setItem(
          ADULTS_ONLY_LOCAL_STORAGE_KEY,
          hotelsInfoArray.every((hotel) => hotel.adultsOnly)
        );

        return hotelsInfoWrapper.hotelsInfo;
      }
    } catch (e) {
      //ensure data integrity, by nerfing any stale information in localstorage
      localStorage.removeItem(HOTELS_INFORMATION_LOCAL_STORAGE_KEY);
      localStorage.removeItem(ADULTS_ONLY_LOCAL_STORAGE_KEY);

      //print some helpful info, if we need to debug in the future
      const stack = new Error().stack;
      console.error(
        e,
        stack,
        "Error fetching group information or resort details. Unable to continue invoking availability."
      );
    }
    return null;
  },
  // Get if group is adults only or not
  GetAdultsOnly: function () {
    return JSON.parse(localStorage.getItem("AdultsOnly")) ?? false;
  },

  //select room
  GetSelectedRoom: function () {
    let selectedRoom = JSON.parse(localStorage.getItem("Reservation"))?.room;
    return selectedRoom !== null && typeof selectedRoom !== "undefined"
      ? selectedRoom
      : null;
  },
  SetSelectedRoom: function (_room) {
    let reservation = this.GetReservation();
    reservation.room = _room;

    this.SetReservation(reservation);
  },
  ClearSelectedRoom: function () {
    localStorage.removeItem("room");
  },

  GetAvailableRooms: function () {
    let rooms = JSON.parse(localStorage.getItem("Reservation"))?.rooms;
    return rooms !== null && typeof rooms !== "undefined" ? rooms : null;
  },
  SetAvailableRooms: function (_rooms) {
    let reservation = this.GetReservation();
    reservation.rooms = _rooms;

    this.SetReservation(reservation);
  },

  //is pseudo book
  GetIsPseudoBook: function () {
    let isPseudoBook = JSON.parse(
      localStorage.getItem("Reservation")
    )?.isPseudoBook;
    return isPseudoBook !== null && typeof isPseudoBook !== "undefined"
      ? isPseudoBook
      : null;
  },
  SetIsPseudoBook: function (_isPseudoBook) {
    let reservation = this.GetReservation();
    reservation.isPseudoBook = _isPseudoBook;

    this.SetReservation(reservation);
  },

  //room arrangements
  GetAdultsCount: function () {
    let adultsCount = JSON.parse(
      localStorage.getItem("Reservation")
    )?.adultsCount;
    return adultsCount !== null && typeof adultsCount !== "undefined"
      ? adultsCount
      : 0;
  },
  GetAdults: function () {
    let adults = JSON.parse(localStorage.getItem("Reservation"))?.adults;
    return adults ?? [];
  },
  GetChildCount: function () {
    let childCount = JSON.parse(
      localStorage.getItem("Reservation")
    )?.childCount;
    return childCount !== null && typeof childCount !== "undefined"
      ? childCount
      : 0;
  },
  GetChilds: function () {
    let childs = JSON.parse(localStorage.getItem("Reservation"))?.childs;
    return childs ?? [];
  },
  SetAdultsCount: function (_count) {
    let reservation = this.GetReservation();
    reservation.adultsCount = _count;

    this.SetReservation(reservation);
  },
  SetAdults: function (_adults) {
    let reservation = this.GetReservation();
    reservation.adults = _adults;

    this.SetReservation(reservation);
  },
  SetChildCount: function (_count) {
    let reservation = this.GetReservation();
    reservation.childCount = _count;

    this.SetReservation(reservation);
  },
  SetChilds: function (_childs) {
    let reservation = this.GetReservation();
    reservation.childs = _childs;

    this.SetReservation(reservation);
  },

  //hotel name
  GetHotelName: function () {
    let hotelName = JSON.parse(localStorage.getItem("Reservation"))?.hotelName;
    return hotelName !== null && typeof hotelName !== "undefined"
      ? hotelName
      : null;
  },
  SetHotelName: function (_hotelName) {
    let reservation = this.GetReservation();
    reservation.hotelName = _hotelName;

    this.SetReservation(reservation);
  },

  //resort
  GetResort: function () {
    let resort = JSON.parse(localStorage.getItem("Reservation"))?.resort;
    return resort !== null && typeof resort !== "undefined" ? resort : null;
  },
  SetResort: function (_resort) {
    let reservation = this.GetReservation();
    reservation.resort = _resort;

    this.SetReservation(reservation);
  },
  GetHotelCode: function () {
    let resort = JSON.parse(localStorage.getItem("Reservation"))?.hotelCode;
    return resort !== null && typeof resort !== "undefined" ? resort : null;
  },
  SetHotelCode: function (_hotelCode) {
    let reservation = this.GetReservation();
    reservation.hotelCode = _hotelCode;

    this.SetReservation(reservation);
  },
  // crm hotel guid
  GetCRMHotelId: function () {
    let crmHotelId = JSON.parse(
      localStorage.getItem("Reservation")
    )?.crmHotelId;
    return crmHotelId !== null && typeof crmHotelId !== "undefined"
      ? crmHotelId
      : null;
  },
  SetCRMHotelId: function (_crmHotelId) {
    let reservation = this.GetReservation();
    reservation.crmHotelId = _crmHotelId;

    this.SetReservation(reservation);
  },

  //wedding
  GetWedding: function () {
    let wedding = JSON.parse(localStorage.getItem("Reservation"))?.wedding;
    return wedding !== null && typeof wedding !== "undefined" ? wedding : null;
  },
  SetWedding: function (_wedding) {
    let reservation = this.GetReservation();
    reservation.wedding = _wedding;

    this.SetReservation(reservation);
  },

  //destination resort name
  GetDestinationResortName: function () {
    let destinationResortName = JSON.parse(
      localStorage.getItem("Reservation")
    )?.destinationResortName;
    return destinationResortName !== null &&
      typeof destinationResortName !== "undefined"
      ? destinationResortName
      : {};
  },
  SetDestinationResortName: function (_resortName) {
    let reservation = this.GetReservation();
    reservation.destinationResortName = _resortName;

    this.SetReservation(reservation);
  },

  //destination full address
  GetDestinationFullAddress: function () {
    let destinationFullAddress = JSON.parse(
      localStorage.getItem("Reservation")
    )?.destinationFullAddress;
    return destinationFullAddress !== null &&
      typeof destinationFullAddress !== "undefined"
      ? destinationFullAddress
      : {};
  },
  SetDestinationfullAddress: function (_fullAddress) {
    let reservation = this.GetReservation();
    reservation.destinationFullAddress = _fullAddress;

    this.SetReservation(reservation);
  },

  //supplier code
  GetSupplierCode: function () {
    let supplierCode = JSON.parse(
      localStorage.getItem("Reservation")
    )?.supplierCode;
    return supplierCode !== null && typeof supplierCode !== "undefined"
      ? supplierCode
      : null;
  },
  SetSupplierCode: function (_supplierCode) {
    let reservation = this.GetReservation();
    reservation.supplierCode = _supplierCode;

    this.SetReservation(reservation);
  },

  //supplier price
  GetSupplierPrice: function () {
    let supplierPrice = JSON.parse(
      localStorage.getItem("Reservation")
    )?.supplierPrice;
    return supplierPrice !== null && typeof supplierPrice !== "undefined"
      ? supplierPrice
      : null;
  },
  SetSupplierPrice: function (_supplierPrice) {
    let reservation = this.GetReservation();
    reservation.supplierPrice = _supplierPrice;

    this.SetReservation(reservation);
  },
  //supplier commission
  GetSupplierCommission: function () {
    let supplierCommission = JSON.parse(
      localStorage.getItem("Reservation")
    )?.supplierCommission;
    return supplierCommission !== null &&
      typeof supplierCommission !== "undefined"
      ? supplierCommission
      : null;
  },
  SetSupplierCommission: function (_supplierCommission) {
    let reservation = this.GetReservation();
    reservation.supplierCommission = _supplierCommission;

    this.SetReservation(reservation);
  },
  //insurance price
  GetInsurancePrice: function () {
    let insurancePrice = JSON.parse(
      localStorage.getItem("Reservation")
    )?.insurancePrice;
    return insurancePrice !== null && typeof insurancePrice !== "undefined"
      ? insurancePrice
      : null;
  },
  SetInsurancePrice: function (_insurancePrice) {
    let reservation = this.GetReservation();
    reservation.insurancePrice = _insurancePrice;

    this.SetReservation(reservation);
  },
  // Insurance Purchased bool
  GetInsurancePurchased: function () {
    let insurancePurchased = JSON.parse(
      localStorage.getItem("Reservation")
    )?.insurancePurchased;
    return insurancePurchased !== null &&
      typeof insurancePurchased !== "undefined"
      ? insurancePurchased
      : false;
  },
  SetInsurancePurchased: function (_insurancePurchased) {
    let reservation = this.GetReservation();
    reservation.insurancePurchased = _insurancePurchased;

    this.SetReservation(reservation);
  },
  // Get Supplier
  GetSupplierCode: function () {
    let supplierCode = JSON.parse(
      localStorage.getItem("Reservation")
    )?.supplierCode;
    return supplierCode;
  },
  SetSupplierCode: function (_supplierCode) {
    let reservation = this.GetReservation();
    reservation.supplierCode = _supplierCode;
    this.SetReservation(reservation);
  },
  // Hotel commission Percentage
  GetHotelCommissionPercentage: function () {
    let hotelCommissionPercentage = JSON.parse(
      localStorage.getItem("Reservation")
    )?.hotelCommissionPercentage;
    return hotelCommissionPercentage;
  },
  SetHotelCommissionPercentage: function (_hotelCommissionPercentage) {
    let reservation = this.GetReservation();
    reservation.hotelCommissionPercentage = _hotelCommissionPercentage;
    this.SetReservation(reservation);
  },

  //groupid
  GetGroupId: function () {
    let groupId = JSON.parse(localStorage.getItem("Reservation"))?.groupId;
    return groupId !== null && typeof groupId !== "undefined" ? groupId : "";
  },
  SetGroupId: function (_groupId) {
    let reservation = this.GetReservation();
    reservation.groupId = _groupId;

    this.SetReservation(reservation);
  },
  //groupName
  GetGroupName: function () {
    let groupName = JSON.parse(localStorage.getItem("Reservation"))?.groupName;
    return groupName !== null && typeof groupName !== "undefined"
      ? groupName
      : "";
  },

  SetGroupName: function (_groupName) {
    let reservation = this.GetReservation();
    reservation.groupName = _groupName;

    this.SetReservation(reservation);
  },

  GetEnableDashboardFlow: function () {
    return localStorage.getItem("enableDashboardFlow");
  },
  SetEnableDashboardFlow: function (enableDestifyDashboard) {
    localStorage.setItem("enableDashboardFlow", enableDestifyDashboard);
  },
  GetGroupLeaderFlag: function () {
    return localStorage.getItem("groupLeader");
  },
  SetGroupLeaderFlag: function (enableDestifyDashboard) {
    localStorage.setItem("groupLeader", enableDestifyDashboard);
  },
  GetCategory: function () {
    return localStorage.getItem("Category");
  },
  SetCategory: function (category) {
    localStorage.setItem("Category", category);
  },

  SetGroupLeaderEmail: function (email) {
    localStorage.setItem("groupLeaderEmail", email);
  },
  GetGroupLeaderEmail: function () {
    return localStorage.getItem("groupLeaderEmail");
  },

  //travel date
  GetTravelDate: function () {
    let travelDate = JSON.parse(
      localStorage.getItem("Reservation")
    )?.travelDate;

    //check for null or undefined (==) and ensure null or actual value.
    //just dont ever return undefined back
    return travelDate == null ? null : travelDate;

    //return travelDate !== null && typeof travelDate !== 'undefined' ? travelDate : null;
  },
  SetTravelDate: function (date) {
    let reservation = this.GetReservation();
    if (!date) {
      reservation.travelDate = null;
    } else {
      const preparedDate = new Date(
        Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
      );
      reservation.travelDate = preparedDate.toISOString().split("T")[0];
    }
    this.SetReservation(reservation);
  },

  //groupVacationType
  GetGroupVacationType: function () {
    let groupVacationType = JSON.parse(
      localStorage.getItem("Reservation")
    )?.groupVacationType;
    return groupVacationType !== null &&
      typeof groupVacationType !== "undefined"
      ? groupVacationType
      : null;
  },
  SetGroupVacationType: function (_groupVacationType) {
    let reservation = this.GetReservation();
    reservation.groupVacationType = _groupVacationType;

    this.SetReservation(reservation);
  },

  //BOL rate key
  GetBolRateKey: function () {
    let bolRateKey = JSON.parse(
      localStorage.getItem("Reservation")
    )?.bolRateKey;
    return bolRateKey !== null && typeof bolRateKey !== "undefined"
      ? bolRateKey
      : null;
  },
  SetBolRateKey: function (_bolRateKey) {
    let reservation = this.GetReservation();
    reservation.bolRateKey = _bolRateKey;
    this.SetReservation(reservation);
  },
  ClearBolRateKey: function () {
    localStorage.removeItem("bolRateKey");
  },

  //ALG sessionId
  GetAlgSessionId: function () {
    let aLGsessionId = JSON.parse(
      localStorage.getItem("Reservation")
    )?.aLGsessionId;
    return aLGsessionId !== null && typeof aLGsessionId !== "undefined"
      ? aLGsessionId
      : null;
  },
  SetAlgSessionId: function (_aLGsessionId) {
    let reservation = this.GetReservation();
    reservation.aLGsessionId = _aLGsessionId;
    this.SetReservation(reservation);
  },
  ClearAlgSessionId: function () {
    localStorage.removeItem("aLGsessionId");
  },

  //Total room package amount
  GetRoomPackageAmount: function () {
    let roomPackageAmount = JSON.parse(
      localStorage.getItem("Reservation")
    )?.roomPackageAmount;
    return roomPackageAmount !== null &&
      typeof roomPackageAmount !== "undefined"
      ? roomPackageAmount
      : null;
  },
  SetRoomPackageAmount: function (_roomPackageAmount) {
    let reservation = this.GetReservation();
    reservation.roomPackageAmount = _roomPackageAmount;
    this.SetReservation(reservation);
  },

  //selected payment amount
  GetSelectedPaymentAmount: function () {
    let paymentAmount = JSON.parse(
      localStorage.getItem("Reservation")
    )?.selectedPaymentAmount;
    return paymentAmount !== null && typeof paymentAmount !== "undefined"
      ? paymentAmount
      : null;
  },
  SetSelectedPaymentAmount: function (_paymentAmount) {
    let reservation = this.GetReservation();
    reservation.selectedPaymentAmount = _paymentAmount;

    this.SetReservation(reservation);
  },

  //room deposit amount
  GetRoomDepositAmount: function () {
    let roomDepositAmount = JSON.parse(
      localStorage.getItem("Reservation")
    )?.roomDepositAmount;
    return roomDepositAmount !== null &&
      typeof roomDepositAmount !== "undefined"
      ? roomDepositAmount
      : null;
  },
  SetRoomDepositAmount: function (_roomDepositAmount) {
    let reservation = this.GetReservation();
    reservation.roomDepositAmount = _roomDepositAmount;

    this.SetReservation(reservation);
  },

  //booking reference number
  RemoveBookingReferenceNumber: function () {
    return localStorage.removeItem("BookingReferenceNumber");
  },
  GetBookingReferenceNumber: function () {
    return localStorage.getItem("BookingReferenceNumber");
  },
  SetBookingReferenceNumber: function (_refNo) {
    localStorage.setItem("BookingReferenceNumber", _refNo);
  },

  //available rooms request data
  SetAvailableRoomsRequestData: function (_requestData) {
    let reservation = this.GetReservation();
    reservation.availableRoomsRequestData = _requestData;
    this.SetReservation(reservation);
  },
  IsAvailableRoomsRequestDataChanged: function () {
    let _prevoius = JSON.parse(
      localStorage.getItem("Reservation")
    )?.availableRoomsRequestData;
    if (_prevoius === null || typeof _prevoius === "undefined") return true;

    let _adults = this.GetAdults();
    let _childs = this.GetChilds();
    let _nights = this.GetTotalNight();
    let _startDate = this.GetTravelDate();

    let _childAgesChanges = true;
    let _currentChildAges = [];
    _childs.filter((x) => _currentChildAges.push(x.childAge));

    if (
      _prevoius.childAges &&
      _currentChildAges &&
      _prevoius.childAges.sort().join(",") ===
        _currentChildAges.sort().join(",")
    ) {
      _childAgesChanges = false;
    }

    return _prevoius.travelDate !== _startDate ||
      _prevoius.numAdults !== _adults.length ||
      _childAgesChanges ||
      _prevoius.nights !== _nights
      ? true
      : false;
  },

  //reservation request data
  GetReservationRequestData: function () {
    let _passengers = [];
    let _travelDate = this.GetTravelDate();
    let _bolRateKey = this.GetBolRateKey();
    let _supplierCode = this.GetSupplierCode();
    let _algSessionId = this.GetAlgSessionId();
    let _supplierPrice = this.GetSupplierPrice();
    let _insurancePrice = this.GetInsurancePrice();
    let _supplierCommission = this.GetSupplierCommission();

    this.GetAdults().map((item) => {
      _passengers.push({
        firstName: item.adultFirstName,
        middleName: item.adultMiddleName,
        lastName: item.adultLastName,
        birthDate: new Date(item.adultDob),
        gender: item.adultGender,
      });
    });

    this.GetChilds().map((item) => {
      _passengers.push({
        firstName: item.childFirstName,
        middleName: item.childMiddleName,
        lastName: item.childLastName,
        birthDate: new Date(item.childDob),
        gender: item.childGender,
      });
    });

    return {
      bolRateKey: _bolRateKey,
      algSessionId: _algSessionId,
      travelDate: _travelDate,
      passengers: _passengers,
      supplierCode: _supplierCode,
      insurancePrice: _insurancePrice,
      supplierPrice: _supplierPrice,
      supplierCommission: _supplierCommission,
    };
  },

  //book information request data
  GetRoomInformationRequestData: function () {
    let _travelerList = [];
    let _adults = this.GetAdults();
    let _childs = this.GetChilds();
    let _checkOutDate = this.GetEndDate();
    let _checkIndate = this.GetStartDate();
    let _selectedRoom = this.GetSelectedRoom();
    let _totalNights = this.GetTotalNight();
    //let _groupVacationType = this.GetGroupVacationType();
    let _travelZapPrice = this.GetRoomPackageAmount();
    let _supplierPrice = this.GetSupplierPrice();
    let _hotelName = this.GetHotelName();
    let _resort = this.GetResort();
    let _supplierCommission = this.GetSupplierCommission();
    let _hotelId = this.GetCRMHotelId();
    let _supplierCode = _selectedRoom.supplierCode;
    //let _pricingType = _supplierCode == "static_pricing" ? "static_pricing " : "live_pricing";
    let _pricingType = _selectedRoom.pricingType;
    let _selectedTransferOption = this.GetSelectedTransferOption();
    let _resortCommissionMultiplier = _resort.resortCommissionMultiplier;
    let _groupCommissionMultiplier = _resort.groupCommissionMultiplier;

    this.GetAdults().map((item) => {
      _travelerList.push({
        firstName: item.adultFirstName,
        middleName: item.adultMiddleName,
        lastName: item.adultLastName,
        email: item.adultEmail,
        phone: CoreHelper.GetPhoneNoWithoutCountryCode(item.adultPhone),
        dob: new Date(item.adultDob),
        gender: item.adultGender,
      });
    });

    this.GetChilds().map((item) => {
      _travelerList.push({
        firstName: item.childFirstName,
        middleName: item.childMiddleName,
        lastName: item.childLastName,
        email: item.childEmail,
        phone: CoreHelper.GetPhoneNoWithoutCountryCode(item.childPhone),
        dob: new Date(item.childDob),
        gender: item.childGender,
      });
    });
    if (_pricingType == "static_pricing") {
      _resortCommissionMultiplier = 0.0;
      _supplierCommission = 0.0;
      _groupCommissionMultiplier = 0.0;
      _supplierCode = _pricingType;
    }

    return {
      checkInDate: _checkIndate,
      checkOutDate: _checkOutDate,
      roomType: _selectedRoom.roomName ?? "",
      numberofAdult: _adults.length,
      numberOfChild: _childs.length,
      totalNights: _totalNights,
      travelZapPrice: parseFloat(_travelZapPrice),
      // get the transfers from the state of the page
      travelZapTransferPrice: _selectedTransferOption.travelZapTransferPrice,
      transferType: _selectedTransferOption.transferTypeName,
      travelZapHotelPrice: _selectedRoom.travelZapHotelPrice,
      supplierPrice: parseFloat(_supplierPrice),
      //supplierTransferPrice: // not set at time of booking
      supplierHotelPrice: _selectedRoom.supplierPrice,
      //supplierCommission:
      SupplierBookingReference: "",
      travelerList: _travelerList,
      //groupVacationType: _groupVacationType,
      hotelName: _selectedRoom.hotelName,
      resortCommissionMultiplier: _resortCommissionMultiplier,
      groupCommissionMultiplier: _groupCommissionMultiplier,
      supplierCommission: _supplierCommission,
      supplier: _supplierCode,
      hotelId: _hotelId,
      pricingType: _pricingType,
      roomTypeCode: _selectedRoom.roomCode,
    };
  },

  //book information request data
  GetRequestedRoomInformationRequestData: function () {
    let _travelerList = [];
    let _adults = this.GetAdults();
    let _childs = this.GetChilds();
    let _checkOutDate = this.GetEndDate();
    let _checkIndate = this.GetStartDate();
    let _selectedRoom = this.GetSelectedRoom();
    let _totalNights = this.GetTotalNight();
    let _groupVacationType = this.GetGroupVacationType();
    let _travelZapPrice = this.GetRoomPackageAmount();
    let _supplierPrice = this.GetSupplierPrice();
    let _hotelName = this.GetHotelName();
    let _resort = this.GetResort();
    let _supplierCommission = this.GetSupplierCommission();

    this.GetAdults().map((item) => {
      _travelerList.push({
        firstName: item.adultFirstName,
        middleName: item.adultMiddleName,
        lastName: item.adultLastName,
        email: item.adultEmail,
        phone: CoreHelper.GetPhoneNoWithoutCountryCode(item.adultPhone),
        dob: new Date(item.adultDob),
        gender: item.adultGender,
      });
    });

    this.GetChilds().map((item) => {
      _travelerList.push({
        firstName: item.childFirstName,
        middleName: item.childMiddleName,
        lastName: item.childLastName,
        email: item.childEmail,
        phone: CoreHelper.GetPhoneNoWithoutCountryCode(item.childPhone),
        dob: new Date(item.childDob),
        gender: item.childGender,
      });
    });

    return {
      checkInDate: _checkIndate,
      checkOutDate: _checkOutDate,
      roomType: "1",
      numberofAdult: _adults.length,
      numberOfChild: _childs.length,
      totalNights: _totalNights,
      travelZapPrice: 0,
      supplierPrice: 0,
      SupplierBookingReference: "",
      travelerList: _travelerList,
      groupVacationType: _groupVacationType,
      hotelName: _hotelName,
      resortCommissionMultiplier: parseFloat(
        _resort?.resortCommissionMultiplier || "0"
      ),
      groupCommissionMultiplier: parseFloat(
        _resort?.groupCommissionMultiplier || "0"
      ),
      supplierCommission: 0,
      Status: "Pending",
    };
  },
};

const ALGReservationExtensionHelper = {
  // Transfer Price during room availability
  ClearALGTransferPrice: function () {
    localStorage.removeItem("ALGTransferPrice");
  },
  GetALGTransferPrice: function () {
    let alGTransferPrice = JSON.parse(localStorage.getItem("ALGTransferPrice"));
    return alGTransferPrice !== null && typeof alGTransferPrice !== "undefined"
      ? alGTransferPrice
      : null;
  },
  SetALGTransferPrice: function (_transferPrice) {
    this.ClearALGTransferPrice();
    localStorage.setItem("ALGTransferPrice", JSON.stringify(_transferPrice));
  },

  // InsuranceKey during room availability
  ClearALGInsuranceKey: function () {
    localStorage.removeItem("ALGInsuranceKey");
  },
  GetALGInsuranceKey: function () {
    let aLGInsuranceKey = JSON.parse(localStorage.getItem("ALGInsuranceKey"));
    return aLGInsuranceKey !== null && typeof aLGInsuranceKey !== "undefined"
      ? aLGInsuranceKey
      : null;
  },
  SetALGInsuranceKey: function (_insuranceKey) {
    this.ClearALGInsuranceKey();
    localStorage.setItem("ALGInsuranceKey", JSON.stringify(_insuranceKey));
  },
  // InsurancePrice during room availability
  ClearALGInsurancePrice: function () {
    localStorage.removeItem("ClearALGInsurancePrice");
  },
  GetALGInsurancePrice: function () {
    let ALGInsurancePrice = JSON.parse(
      localStorage.getItem("ALGInsurancePrice")
    );
    return ALGInsurancePrice !== null &&
      typeof ALGInsurancePrice !== "undefined"
      ? ALGInsurancePrice
      : null;
  },
  SetALGInsurancePrice: function (_insurancePrice) {
    this.ClearALGInsurancePrice();
    localStorage.setItem("ALGInsurancePrice", JSON.stringify(_insurancePrice));
  },
};

const ReservationStepsValidator = {
  IsSelectDatesStepValid: function () {
    let endDate = ReservationHelper.GetEndDate();
    let startDate = ReservationHelper.GetStartDate();
    let totalNight = ReservationHelper.GetTotalNight();
    return startDate !== null && endDate !== null && totalNight > 0
      ? true
      : false;
  },

  IsSelectRoomStepValid: function () {
    //var roomrequest=  localStorage.getItem('roomrequest');
    let selectedRoom = ReservationHelper.GetSelectedRoom();
    let isSelectDatesStepValid = this.IsSelectDatesStepValid();
    //if(roomrequest && isSelectDatesStepValid) return true;
    return isSelectDatesStepValid && selectedRoom !== null ? true : false;
  },

  IsRoomArrangementStepValid: function () {
    let adultCount = ReservationHelper.GetAdultsCount();
    let childCount = ReservationHelper.GetChildCount();
    let isSelectRoomStepValid = this.IsSelectRoomStepValid();
    let isSelectDatesStepValid = this.IsSelectDatesStepValid();
    return (adultCount > 0 || childCount > 0) &&
      isSelectRoomStepValid &&
      isSelectDatesStepValid
      ? true
      : false;
  },

  IsConfirmationStepValid: function () {
    let selectedPaymentAmount = ReservationHelper.GetSelectedPaymentAmount();
    let isSelectRoomStepValid = this.IsSelectRoomStepValid();
    let isSelectDatesStepValid = this.IsSelectDatesStepValid();
    let isRoomArrangementStepValid = this.IsRoomArrangementStepValid();
    return selectedPaymentAmount > 0 &&
      isSelectRoomStepValid &&
      isSelectDatesStepValid &&
      isRoomArrangementStepValid
      ? true
      : false;
  },
};

export {
  ReservationHelper,
  ReservationStepsValidator,
  ALGReservationExtensionHelper,
};
