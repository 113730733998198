import api from "utils/api";
import React, { Component } from 'react';
import Loading from "../../../components/Loading";
import Authhelper from '../../../core/authHelper';
import PhoneField from '../../../components/PhoneField';
import BackButton from '../../../components/BackButton';
import ReactInput from '../../../components/InputField';
import SubMainScreen from '../../../layouts/SubMainScreen';
import ReactPassword from '../../../components/PasswordField';
import { ReservationHelper } from "../../../core/reservationHelper";
import { EMAIL_VALIDATION_REGEX, ERROR_GENERAL } from 'core/globalConstant';

class SignupProfileFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            phone: "",
            errors: {},
            loader: false,
            loginError: false,
            signupErrorMessage: null,
            queryParams: new URLSearchParams(window.location.search)
        }
    }

    handlePhoneInputChange = (e) => this.setState({ phone: e });

    handleInputChange = (e) => this.setState({ [e.target.name]: e.target.value });

    handleFormSubmit = (e) => {
        e.preventDefault();
        const errors = this.handleFormValidation(this.state);
        const returnUrl = this.state.queryParams.get('returnurl');
        this.setState({ errors }, () => {
            if (Object.keys(errors).length === 0) {
                const data = {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email,
                    phone: this.state.phone,
                    password: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    groupId: ReservationHelper.GetGroupId() === 'null' ? "" : ReservationHelper.GetGroupId(),
                    returnUrl: returnUrl,
                    isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true' ? true : false,
                }
                this.setState({ loader: true });
                api.post('/api/auth/register', data)
                    .then(response => {
                        this.setState({ loader: false });
                        if (response.data.success) {
                            Authhelper.RemoveGroupId();

                             // Add Google Tag Manager dataLayer event for successful registration
                            window.dataLayer?.push({
                                event: "CreateAccountSuccess",
                                method: "Email"
                            });
                            if (returnUrl != null)
                                window.location.replace("/post-confirmation?returnurl=" + returnUrl);
                            else
                                window.location.replace("/post-confirmation");
                        } else { this.setState({ loginError: true, signupErrorMessage: response.data.error[0] }); }
                    }).catch(error => {
                        //if (error.response.status==400)
                        //    this.setState({ loader: false, loginError: true, signupErrorMessage: error.response.errors });
                        //else
                        this.setState({ loader: false, loginError: true, signupErrorMessage: ERROR_GENERAL });
                    });
            } else { return false; }
        });
        return false;
    }

    handleFormValidation = (state) => {
        let errors = {};
        const emailRegex = EMAIL_VALIDATION_REGEX;
        if (!this.state.firstName) errors.firstName = "Enter your first name";
        if (!this.state.lastName) errors.lastName = "Enter your last name";
        if (!this.state.email) errors.email = "Enter email address";
        if (!emailRegex.test(this.state.email)) errors.email = "Invalid email address";
        if (!this.state.phone) errors.phone = "Enter your phone number";
        if (!this.state.password) errors.password = "Enter your password";
        if (this.state.confirmPassword !== this.state.password) errors.confirmPassword = "ConfirmPassword and Password should match";
        return errors;
    }

    render() {
        const { errors } = this.state;

        return (

            <SubMainScreen type="layout-2">
                {this.state.loader && <Loading />}
                {!this.state.loader && <>
                    <div className="c-auth-screen">
                        <div className="w-100 d-flex justify-content-center">
                            <div className="auth-content">
                                {this.state.groupId === null || typeof this.state.groupId === 'undefined' ? <BackButton target={this.state.queryParams.get('returnurl') != null ? "/register?returnurl=" + this.state.queryParams.get('returnurl') : "/register"} />
                                    : <BackButton target={"/register?groupId=" + this.state.groupId} />}

                                <div className="auth-title ">Sign Up</div>

                                {this.state.loginError && <>
                                    < div className="error-message">
                                        <span>{this.state.signupErrorMessage}</span>
                                    </div>
                                </>}

                                <form className="c-form" onSubmit={this.handleFormSubmit}>
                                    <ReactInput type="text" label="First Name" name="firstName" placeholder="First Name" error={errors && errors.firstName} onChange={this.handleInputChange} />
                                    <ReactInput type="text" label="Last Name" name="lastName" placeholder="Last Name" error={errors && errors.lastName} onChange={this.handleInputChange} />
                                    <ReactInput type="email" label="Email" name="email" placeholder="Email" error={errors && errors.email} onChange={this.handleInputChange} />
                                    <PhoneField international label="Phone Number" name="phone" placeholder="Phone Number" error={errors && errors.phone} onChange={this.handlePhoneInputChange} />
                                    <ReactPassword label="Password" name="password" placeholder="Password" error={errors && errors.password} onChange={this.handleInputChange} />
                                    <ReactPassword label="Confirm Password" name="confirmPassword" placeholder="Confirm Password" error={errors && errors.confirmPassword} onChange={this.handleInputChange} />
                                    <button className="c-btn mt-3" type="submit" >Complete Profile</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </>}
            </SubMainScreen>
        )
    }
}

export default SignupProfileFormScreen;