import React, { useEffect, useState } from "react";

const ReactInput = ({
  index,
  label,
  type,
  min,
  name,
  placeholder,
  error,
  onChange,
  value,
  isLabelFloat,
  required,
  maxLength,
  useTextArea = false,
  labelStyles = {},
  textAreaStyles = {},
}) => {
  const [floatLabel, setFloatLabel] = useState(false);
  const handleInputFocus = () => {
    setFloatLabel(true);
  };
  const handleInputBlur = (e) => {
    if (e.target.value) {
      setFloatLabel(true);
    } else {
      setFloatLabel(false);
    }
  };
  if (!useTextArea) {
    return (
      <div
        className={`form-group ${
          isLabelFloat && floatLabel ? "floating-label" : ""
        }`}
      >
        {label && (
          <label
            className={` ${isLabelFloat ? "float-label" : ""} ${
              isLabelFloat && floatLabel ? "float-active" : "float-inactive"
            }`}
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <input
          required={required ? true : false}
          defaultValue={value}
          index={index}
          type={type}
          min={min}
          placeholder={placeholder}
          name={name}
          className={`form-control ${error ? "form-error" : ""}`}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {error && <p className="error">{error}</p>}
      </div>
    );
  } else {
    return (
      <div
        className={`form-group ${
          isLabelFloat && floatLabel ? "floating-label" : ""
        }`}
      >
        {label && (
          <label
            className={` ${isLabelFloat ? "float-label" : ""} ${
              isLabelFloat && floatLabel ? "float-active" : "float-inactive"
            }`}
            style={labelStyles}
          >
            {" "}
            {label}{" "}
          </label>
        )}
        <textarea
          cols="40"
          rows="5"
          required={required ? true : false}
          value={value}
          index={index}
          type={type}
          min={min}
          placeholder={placeholder}
          name={name}
          className={`form-control ${error ? "form-error" : ""}`}
          maxLength={maxLength}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          style={textAreaStyles}
        />
        {error && <p className="error">{error}</p>}
      </div>
    );
  }
};
export default ReactInput;
