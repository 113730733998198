import { rootReducer } from "./reducers";
import React, { createContext, useReducer, useContext } from "react";
import { ReservationHelper } from "core/reservationHelper";

const store = createContext(null);
const { Provider } = store;

export const initialState = {
  reservationState: {
    dates: true,
    rooms: false,
    roomArrangements: false,
    confirmation: false,
    //DWA-2918
    total:
      ReservationHelper.GetReservationStepProgress() ||
      ReservationHelper.RESERVATION_STEPS.SELECT_DATES,
    reservationUrlToGo: "",
  },
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  const objstore = React.useMemo(() => [state, dispatch], [state]);
  return <Provider value={objstore}>{children}</Provider>;
};

export { store, StateProvider };

export const useStore = () => useContext(store);
