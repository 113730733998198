import React, { useEffect, useMemo, useState } from "react";
import { Row, Col } from "reactstrap";
import Modal from "react-bootstrap/Modal";

import api from "utils/api";

import Authhelper from "core/authHelper";
import { ReservationHelper } from "core/reservationHelper";

import ConfirmPayment from "./payment";
import GuestReservation from "../reservationLayout";
import Loading from "components/Loading";
import InsuranceOptions from "./insurance/insurance.js";
import TransferOptions from "./Transfers/TransferOptions.js";
import Summary from "./summary";
import GuestDetails from "./guestDetails";
import PaymentOptions from "./payment/PaymentOptions";
import ConfirmationLoginForm from "./ConfirmationLoginForm";
import { useCheckMobileScreen } from "hooks";

import MobileSummaryView from "./summary/MobileView";
import MobilePaymentView from "./payment/MobileView";
import MobileGuestDetailsView from "./guestDetails/MobileView";
import MobileTransferTypesView from "./Transfers/MobileView";

const STATIC_PRICING = "static_pricing";

const NO_TRANSFER_OPTION = {
  transferTypeName: "none",
  travelZapTransferPrice: 0,
  transferTypeLabel: "I would not like to add transportation at this time",
  pricePerRoundTrip: 0.0,
};

const aLGSupplier = ["funjet", "apple", "united", "southwest", "alg"];

const SelectReservationRoomConfirmation = (props) => {
  const [loader, setLoader] = useState(true);
  const [roomDeposit, setRoomDeposit] = useState(null);
  const [fullPayment, setFullPayment] = useState(null);
  const [transferInclude, setTransferInclude] = useState(null);
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(0);
  const [showUnexpectedErrorModal, setShowUnexpectedErrorModal] =
    useState(false);
  const [adultTravelers, setAdultTravelers] = useState([]);
  const [childTravelers, setChildTravelers] = useState([]);
  const [supplierCode, setSupplierCode] = useState(null);
  const [groupInfo, setGroupInfo] = useState(null);
  const [resort, setResort] = useState(null);
  const [travelInsurance, setTravelInsurance] = useState(null);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState({});
  const [insurancePrice, setInsurancePrice] = useState(null);
  const [loadingText, setLoadingText] = useState(false);
  const [totalAmountDueToday, setTotalAmountDueToday] = useState(null);
  const [purchasedInsurance, setPurchasedInsurance] = useState(false);
  const [insurancePriceFromCRM, setInsurancePriceFromCRM] = useState(null);
  const [showDeposit, setShowDeposit] = useState(null);
  const [aLGInsuranceKey, setALGInsuranceKey] = useState(null);
  const [transfersInfo, setTransferInfo] = useState(null);
  const [selectedTransferOption, setSelectedTransferOption] = useState(null);
  const [showTransfer, setShowTransfer] = useState(false);
  const [hotelPrice, setHotelPrice] = useState(0);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(
    Authhelper.IsAuthenticated()
  );

  //const selectedTransferOption = 0;

  const adults = ReservationHelper.GetAdults();
  const childs = ReservationHelper.GetChilds();
  const checkOutDate = ReservationHelper.GetEndDate();
  const checkInDate = ReservationHelper.GetStartDate();
  const totalNights = ReservationHelper.GetTotalNight();
  const selectedRoom = ReservationHelper.GetSelectedRoom();

  const handelReservationExit = () => {
    props.history.push(`/guest/reservation/select-date`);
  };

  const paymentTypes = [
    {
      name: "Deposit",
      amount: roomDeposit,
      show: showDeposit,
    },
    {
      name: "Full Payment",
      amount: fullPayment,
      show: true,
    },
  ];

  function ShowDeposit(travelDate, _roomPackageAmount) {
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    let _travelDate = new Date(travelDate);
    let dateCutoff = new Date();
    dateCutoff = dateCutoff.addDays(65);
    if (dateCutoff < _travelDate) {
      setShowDeposit(true);
    } else {
      setShowDeposit(false);
      //setTotalAmountDueToday(_roomPackageAmount);
    }
  }

  useEffect(() => {
    let adults = ReservationHelper.GetAdults();
    let childs = ReservationHelper.GetChilds();
    let selectedPaymentAmount = ReservationHelper.GetSelectedPaymentAmount();

    setAdultTravelers(adults);
    setChildTravelers(childs);
    //setSelectedPaymentAmount(selectedPaymentAmount);
    setTotalAmountDueToday(0);
    setSelectedPaymentAmount(0);
  }, []);

  useEffect(() => {
    let GROUP_ID = ReservationHelper.GetGroupId();
    let CRMHotelID = ReservationHelper.GetCRMHotelId();
    let _numKids = ReservationHelper.GetChildCount();
    let _numAdults = ReservationHelper.GetAdultsCount();
    let _travelDate = ReservationHelper.GetTravelDate();
    let _roomPackageAmount = ReservationHelper.GetRoomPackageAmount();
    let _selectedRoom = ReservationHelper.GetSelectedRoom();
    let _supplierCode = ReservationHelper.GetSupplierCode();
    //let _ALGInurancekey = ALGReservationExtensionHelper.GetALGInsuranceKey();
    let _ALGInurancekey = _selectedRoom.algInsuranceKey;
    setALGInsuranceKey(_ALGInurancekey);
    //let _numberOfNights = ReservationHelper.Get
    //let _totalPackagePrice = (Math.round((_supplierPrice * 100) * _totalNumberofNights ))/100
    // add mouseflow script from component
    //if (GROUP_ID) {
    //    GROUP_ID = GROUP_ID.split('-')[4];
    //}
    //else {
    //    GROUP_ID = "";
    //}
    //loadMouseFlow(null, GROUP_ID);

    let _insurance =
      purchasedInsurance === "undefined" || purchasedInsurance == null
        ? purchasedInsurance
        : false;
    const _getInsuranceRequest = {
      groupId: GROUP_ID,
      numAdults: _numAdults,
      numKids: _numKids,
      totalPackagePrice: _selectedRoom.price,
      travelDate: _travelDate,
    };
    Authhelper.GetAuthenticatedEmailAddress().then((AUTHENTICATED_EMAIL) => {
      api
        .all([
          api.get(
            `/api/usergroup/getGroupInfoByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
          ),
          api.get(
            `/api/resort/getResortDetailByGroupId?groupId=${GROUP_ID}&email=${AUTHENTICATED_EMAIL}`
          ),
          //_supplierCode != "bedsonline" ? axios.post(`/api/insurance/getInsurancePriceByGroupId`, _getInsuranceRequest).catch(x => null) : null,
          api.get(
            `/api/Transfer/getAvailabilityByCRMHotelID?HotelID=${CRMHotelID}&Adults=${_numAdults}&Children=${_numKids}`
          ),
          //]).then(axios.spread((groupInfo, resort, insurance, transfers) => {
        ])
        .then(
          api.spread((groupInfo, resort, transfers) => {
            // removed insurance because it is not pulling correct prices

            ReservationHelper.SetGroupName(groupInfo.data.groupName);
            ReservationHelper.SetResort(resort.data);
            let _hotel = ReservationHelper.GetSelectedRoom().hotelName;
            ReservationHelper.SetHotelName(_hotel);
            //ReservationHelper.setTransferInfo(transfers.data);
            let _transfersOptions = transfers.data;
            console.log(resort.data);
            if (!ShowTransfers(ReservationHelper.GetTravelDate())) {
              _transfersOptions.length = 0;
            }
            _transfersOptions.push(NO_TRANSFER_OPTION);

            // sort Transfer Options by price
            _transfersOptions = _transfersOptions.sort((a, b) => {
              return a.travelZapTransferPrice - b.travelZapTransferPrice;
            });

            setTransferInfo(_transfersOptions);
            setSelectedTransferOption(NO_TRANSFER_OPTION);

            setGroupInfo(groupInfo.data);
            setResort(resort.data);

            //resort
            setTransferInclude(false);
            ReservationHelper.SetGroupVacationType("hotel");

            ReservationHelper.SetSelectedTransferOption(NO_TRANSFER_OPTION);

            recheckReservationPrice(
              groupInfo.data,
              resort.data,
              _insurance,
              null,
              null
            );
          })
        )
        .catch((e) => {
          alert(e);
          setLoader(false);
        });
    });
  }, []);

  const setInsuranceChecked = (checked) => {
    recheckReservationPrice(
      groupInfo,
      resort,
      checked,
      null,
      selectedTransferOption
    );
  };

  const toggleRecheck = (toggleRecheck) => {
    //alert(toggleRecheck);
    let _transfersInfo = transfersInfo;
    let _selectedTransferOption = _transfersInfo.find(
      (x) => x.transferTypeName == toggleRecheck.target.id
    );

    setSelectedTransferOption(_selectedTransferOption);
    ReservationHelper.SetSelectedTransferOption(_selectedTransferOption);

    if (_selectedTransferOption.transferTypeName === "none") {
      ReservationHelper.SetGroupVacationType("hotel");
    } else {
      ReservationHelper.SetGroupVacationType("hotelandtransfers");
    }

    //setSelectedTransferOption(toggleRecheck.currentTarget.checked ? selectedTransferOption : 0);
    recheckReservationPrice(
      groupInfo,
      resort,
      purchasedInsurance,
      null,
      _selectedTransferOption
    );
  };

  function ShowTransfers(travelDate) {
    Date.prototype.addDays = function (days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };

    let _travelDate = new Date(travelDate);
    let dateCutoff = new Date();
    dateCutoff = dateCutoff.addDays(14);
    if (dateCutoff < _travelDate) {
      //setShowDeposit(true);
      setShowTransfer(true);
      return true;
    } else {
      //setShowDeposit(false);
      setShowTransfer(false);
      return false;
    }
  }

  const recheckReservationPrice = (
    groupInfo,
    resort,
    insurance,
    _insurancePriceFromCRM,
    _selectedTransferOption
  ) => {
    setPurchasedInsurance(insurance);
    ReservationHelper.SetInsurancePurchased(insurance);
    setLoadingText("Updating Prices");
    setLoader(true);
    let _birthDates = [];
    let _depositPerPerson = groupInfo.depositPerPerson;
    adults.map((item) => {
      _birthDates.push(new Date(item.adultDob));
    });
    childs.map((item) => {
      _birthDates.push(new Date(item.childDob));
    });
    let _travelDate = ReservationHelper.GetTravelDate();

    let _roomDeposit =
      (parseInt(adults?.length) + parseInt(childs?.length)) *
      parseInt(_depositPerPerson);
    //setRoomDeposit(_roomDeposit);
    //set state for room deposit
    ReservationHelper.SetRoomDepositAmount(_roomDeposit);
    //var _hotelCode = aLGSupplier.includes(resort.supplierCode) ? resort.algHotelCode : resort.bolHotelCode;
    let _selectedRoom = ReservationHelper.GetSelectedRoom();
    //let _hotelCode = aLGSupplier.includes(selectedRoom.supplierCode) ? resort.algHotelCode : resort.bolHotelCode;
    let _hotelCode = _selectedRoom.hotelCode;
    let _insurancePrice = _insurancePriceFromCRM;
    if (typeof _insurancePrice === "undefined" || _insurancePrice == null) {
      _insurancePrice = insurancePrice;
    }
    // if (typeof _insurancePrice === "undefined" || _insurancePrice == null) {
    //   _insurancePrice = "Click to see insurance";
    // }
    let _isPseudoBook = ReservationHelper.GetIsPseudoBook();

    //let algInsuranceKey = !_isPseudoBook ? ALGReservationExtensionHelper.GetALGInsuranceKey() : "";
    let _supplierCode = ReservationHelper.GetSupplierCode();
    let _resortCommissionMultiplier =
      _selectedRoom.pricingType === STATIC_PRICING
        ? 0
        : parseFloat(resort.supplierCommissionList[_supplierCode]);
    //some weird stuff happens in this terrible place.
    //fallback to 0 if things arent correct, at this point.
    if (isNaN(_resortCommissionMultiplier)) {
      _resortCommissionMultiplier = 0;
    }
    ReservationHelper.SetHotelCommissionPercentage(_resortCommissionMultiplier);

    let _groupCommissionMultiplier = resort.groupCommissionMultiplier;
    let _firstName = adults[0].adultFirstName;
    let _lastName = adults[0].adultLastName;
    let _numChilds = ReservationHelper.GetChildCount();
    let _numAdults = ReservationHelper.GetAdultsCount();
    let _nights = ReservationHelper.GetTotalNight();

    // Get this field from new transfer module by Wayne
    let _travelZapTransferPrice = 0;
    if (_selectedTransferOption !== null) {
      _travelZapTransferPrice = _selectedTransferOption.travelZapTransferPrice;
    }

    let _pricingType = _selectedRoom.pricingType;

    if (_pricingType === STATIC_PRICING) {
      _supplierCode = _pricingType;
    }

    let _promoCode = resort.promoCode;

    //axios.post(`/api/reservation/recheckReservationPrice`, {
    api
      .post(`/api/reservation/V2recheckReservationPrice`, {
        //supplierCode: resort.supplierCode,
        supplierCode: _supplierCode,
        /*hotelCode: resort.bolHotelCode,*/
        hotelCode: _hotelCode,
        aLGRatePlan: _selectedRoom.algRatePlan,
        aLGInsuranceKey: insurance ? _selectedRoom.algInsuranceKey : "",
        travelDate: _travelDate,
        nights: _nights,
        birthDates: _birthDates,
        destination: resort.airportCode,
        //bolRoomCode: _selectedRoom.roomCode,
        roomCode: _selectedRoom.roomCode,
        //hotelChainCommissonPercentage: resort.resortCommissionMultiplier,
        hotelChainCommissonPercentage: _resortCommissionMultiplier,
        groupCommissionPercentage: _groupCommissionMultiplier,

        isPseudoBook: _isPseudoBook,
        firstName: _firstName,
        lastName: _lastName,
        // v2 fields
        supplierPrice: _selectedRoom.supplierPrice,
        travelZapPrice: _selectedRoom.travelZapHotelPrice,
        //supplierCommission: _selectedRoom.supplierCommission,
        numAdults: _numAdults,
        numChilds: _numChilds,
        travelZapTransferPrice: _travelZapTransferPrice,
        pricingType: _pricingType,
        promoCode: _promoCode,
      })
      .then((response) => {
        setLoader(false);
        setLoadingText("");
        if (!_isPseudoBook && response.data.insurancePrice > 0) {
          _insurancePrice = response.data.insurancePrice;
        }
        let _purchaseInsurance = _insurancePrice;
        //AT 01.22.2024: this may alter behavior
        if (!insurance) {
          _purchaseInsurance = 0.0;
        }

        setHotelPrice(response.data.price);
        //let _transferPrice = 0;
        // since we are not using Alg for the transfer service
        // transfer price is not in the recheck price from hotel service
        // we are using bol for transfers and simply getting market rate
        // from alg so for alg suppliers set the transfer price from the
        // stored transfer price.
        //if (aLGSupplier.includes(resort.supplierCode)) {
        // This will be changing with Waynes updates.
        //let _transferInclude = ReservationHelper.GetGroupVacationType() === "hotelandtransfers" ? true : false;
        //if (_transferInclude) {
        //    if (aLGSupplier.includes(_supplierCode)) {
        //        _transferPrice = ALGReservationExtensionHelper.GetALGTransferPrice();
        //        // _aLGInsuranceKey = ALGReservationExtensionHelper.GetALGInsuranceKey();
        //    }
        //    else // for bol or anybody other than alg get price from the recheck request
        //    {
        //        _transferPrice = response.data.transferPrice;
        //    }
        //}

        // new transfer logic

        let _transferPrice = response.data.travelZapTransferPrice;

        //let _totalAmountDueToday = (Math.round((_roomDeposit * 100) + (_purchaseInsurance * 100))) / 100;
        //let _roomPackageAmount = (Math.round((response.data.price * 100) + (_transferPrice * 100) + (_purchaseInsurance * 100))) / 100;
        // the amount of the deposit per traveler + insurance amount if insurance from alg is selected.
        let _totalAmountDueToday = response.data.depositWithInsurance;
        // the total amount/travelZapPrice for the entire package amount including insurance and transfer price
        let _roomPackageAmount = response.data.totalAmount;

        if (
          typeof selectedPaymentOption !== "undefined" &&
          selectedPaymentOption.name == "Full Payment"
        ) {
          selectedPaymentOption.amount = _roomPackageAmount;
          _totalAmountDueToday = _roomPackageAmount;
        }
        setSelectedPaymentOption(selectedPaymentOption);
        // fixed in the backend so that on ALG recheck the insurance price (if selected) will be added to the supplier price
        //let _supplierPrice = (Math.round((response.data.supplierPrice * 100) + (_purchaseInsurance * 100))) / 100;
        let _supplierPrice = response.data.supplierPrice;

        let _supplierCommission = response.data.supplierCommission;
        setFullPayment(_roomPackageAmount);
        setRoomDeposit(_roomDeposit);
        setInsurancePrice(response.data.insurancePrice);
        setTravelInsurance(insurance ? response.data.insurancePrice : null);
        ReservationHelper.SetSupplierCommission(_supplierCommission);
        //setSupplierCode(resort.supplierCode);
        setSupplierCode(_supplierCode);

        setTotalAmountDueToday(_totalAmountDueToday);
        ShowDeposit(_travelDate, _roomPackageAmount);
        ReservationHelper.SetSelectedPaymentAmount(_totalAmountDueToday);
        if (_roomPackageAmount <= 0 || !response.data.success)
          setShowUnexpectedErrorModal(true);
        else {
          //set states for selected room package amount and rate key
          ReservationHelper.SetSupplierPrice(_supplierPrice);
          ReservationHelper.SetTransferPrice(_transferPrice);
          ReservationHelper.SetInsurancePrice(_purchaseInsurance);
          ReservationHelper.SetRoomPackageAmount(_roomPackageAmount);
          ReservationHelper.SetBolRateKey(response.data.rateKey);
          ReservationHelper.SetAlgSessionId(response.data.algSessionId);
          ReservationHelper.SetRoomDepositAmount(_roomDeposit);
          ReservationHelper.SetSupplierCommission(_supplierCommission);
          ReservationHelper.SetInsurancePurchased(insurance);
        }
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const onPaymentOptionSelect = (item) => {
    //set state for selected payment Amount
    let _purchaseInsurance = ReservationHelper.GetInsurancePrice();
    let _totalAmountDueToday = item.amount;
    if (item.name == "Deposit") {
      _totalAmountDueToday =
        Math.round(item.amount * 100 + _purchaseInsurance * 100) / 100;
    }
    setSelectedPaymentOption(item);
    setSelectedPaymentAmount(_totalAmountDueToday);
    setTotalAmountDueToday(_totalAmountDueToday);
    //setSelectedPaymentAmount(_totalAmountDueToday);
    ReservationHelper.SetSelectedPaymentAmount(_totalAmountDueToday);
  };

  const isMobile = useCheckMobileScreen();

  const transferOptions = useMemo(() => {
    return (
      transfersInfo?.map((transfer) => {
        const [label, description] = transfer.transferTypeLabel.split(":");
        return {
          label,
          description,
          name: transfer.transferTypeName,
          value: transfer.travelZapTransferPrice,
        };
      }) || []
    );
  }, [transfersInfo]);

  return (
    <GuestReservation>
      <div className="position-relative">
        {loader && (
          <div
            style={{
              backgroundColor: "white",
              zIndex: 1,
              margin: "0 -15px",
              width: "calc(100% + 30px)",
            }}
            className="position-absolute z-1 h-100 justify-content-center align-items-start"
          >
            <div className="position-sticky" style={{ top: 0 }}>
              <Loading loadingText={loadingText} />
            </div>
          </div>
        )}
        {isMobile ? (
          <>
            <Row className="row-cols-1">
              <div className="d-flex flex-column align-items-center">
                <h2>Secure Your Booking</h2>
                <div className="room-title">{selectedRoom?.hotelName}</div>
              </div>
            </Row>
            <Row className="row-cols-1 mt-4">
              <MobileSummaryView
                checkInDate={checkInDate}
                checkOutDate={checkOutDate}
                totalNights={totalNights}
                roomName={selectedRoom?.roomName}
              />
            </Row>
            <Row className="row-cols-1">
              <MobileGuestDetailsView
                adultTravelers={adultTravelers}
                childTravelers={childTravelers}
              />
            </Row>
            <Row className="row-cols-1 mt-4">
              {/* <TransferOptions
                    {...props}
                    {...resort}
                    //TravelZapTransferPrice={supplierCode}
                    TransferOptions={transfersInfo}
                    toggleRecheck={toggleRecheck}
                    selectedTransferOption={selectedTransferOption}
                  /> */}
              <MobileTransferTypesView
                selectedTransferOption={selectedTransferOption}
                transferTypes={transferOptions}
                toggleRecheck={toggleRecheck}
              />
            </Row>
            <Row className="row-cols-1 mt-4">
              <InsuranceOptions
                {...props}
                supplierCode={supplierCode}
                setInsuranceChecked={setInsuranceChecked}
                insurancePrice={insurancePrice}
                travelInsurance={travelInsurance}
                aLGInsuranceKey={aLGInsuranceKey}
              />
            </Row>
            <Row className="row-cols-1 mt-4">
              <MobilePaymentView
              showDeposit={showDeposit}
                roomDeposit={roomDeposit}
                fullPayment={fullPayment}
                selectPayment={onPaymentOptionSelect}
                selectedPayment={selectedPaymentOption.name}
                checkInDate={checkInDate}
              />
            </Row>
            <Row className="row-cols-1 mt-4 text-center">
              <ConfirmPayment
                {...props}
                paymentAmount={selectedPaymentAmount}
                selectedTransferOption={selectedTransferOption}
                label={"Submit Your Payment"}
              />
            </Row>
          </>
        ) : (
          <>
            <Row>
              <Col lg={8}>
                <div className="page-reservation-confirmation">
                  <h2 className="section-header">Confirm your booking</h2>
                  <div className="room-title">{selectedRoom?.hotelName}</div>
                  <div className="room-title">{selectedRoom?.roomName}</div>
                  <div className="total-nights">{totalNights} nights</div>
                  <GuestDetails
                    adultTravelers={adultTravelers}
                    childTravelers={childTravelers}
                  />
                  <div
                    className="c-payment-options"
                    style={{ margin: "2rem 0" }}
                  >
                    <PaymentOptions
                      paymentTypes={paymentTypes}
                      onPaymentOptionSelect={onPaymentOptionSelect}
                      selectedPaymentOption={selectedPaymentOption}
                    />
                  </div>
                </div>
                <br />
                <TransferOptions
                  {...props}
                  {...resort}
                  //TravelZapTransferPrice={supplierCode}
                  TransferOptions={transfersInfo}
                  toggleRecheck={toggleRecheck}
                  selectedTransferOption={selectedTransferOption}
                />
                <br />
                <InsuranceOptions
                  {...props}
                  supplierCode={supplierCode}
                  setInsuranceChecked={setInsuranceChecked}
                  insurancePrice={insurancePrice}
                  travelInsurance={travelInsurance}
                  aLGInsuranceKey={aLGInsuranceKey}
                />
              </Col>
              <Col lg={4}>
                <Summary
                  adults={adults}
                  checkInDate={checkInDate}
                  checkOutDate={checkOutDate}
                  childs={childs}
                  // travelInsurance={travelInsurance}
                  travelInsurance={insurancePrice}
                  fullPayment={fullPayment}
                  showRemainingBalance={
                    selectedPaymentOption.name === "Deposit"
                  }
                  hasSupplierCode={aLGSupplier.includes(supplierCode)}
                  hotelPrice={hotelPrice}
                  roomDeposit={roomDeposit}
                  selectedTransferOption={selectedTransferOption}
                  totalNights={totalNights}
                  totalAmountDueToday={totalAmountDueToday}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div style={{ marginTop: "1rem" }}>
                  <ConfirmPayment
                    {...props}
                    paymentAmount={selectedPaymentAmount}
                    selectedTransferOption={selectedTransferOption}
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <Modal
          show={
            !isAuthenticated &&
            !showUnexpectedErrorModal &&
            ReservationHelper.GetEnableDashboardFlow() === "true"
          }
        >
          <Modal.Header>
            <Modal.Title>
              Login or Create a Destify Account to Book your Room
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ConfirmationLoginForm
              onSubmit={() => {
                setIsAuthenticated(Authhelper.IsAuthenticated());
              }}
              {...props}
            />
          </Modal.Body>
        </Modal>
        <Modal show={showUnexpectedErrorModal} backdrop="static" size="md">
          <Modal.Header>
            <Modal.Title>This Room Is No Longer Available.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <p>
                Looks like the room you selected is no longer available. Please
                select another room to continue your reservation process.
              </p>
              <button
                type="button"
                onClick={handelReservationExit}
                className="c-btn c-btn--ghost c-btn--ghost__primary"
              >
                Ok
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </GuestReservation>
  );
};
export default SelectReservationRoomConfirmation;
