import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SubMainScreen from '../../../layouts/SubMainScreen';

class UserNotfound extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <SubMainScreen>
                <div className="c-auth-screen">
                    <div className="auth-content">
                        <div className="auth-title">Information</div>
                        <div>We are not able to find your information in our system. Either sign up using a different email or call our guest services.</div>
                    </div>
                </div>
            </SubMainScreen>
        )
    }
}

export default withRouter(UserNotfound);