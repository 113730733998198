import React from 'react';

const ReactInputArea = ({ label, rows, cols, name, placeholder, error, onChange, className }) => {
    return (
        <div className="form-group">
            {label && <label> {label} </label>}
            <textarea rows={rows} cols={cols} placeholder={placeholder} name={name} className={`form-control ${className} ${error ? "form-error" : ""}`} onChange={onChange}></textarea>
            {error && <p className="error">{error}</p>}
        </div>
    )
}
export default ReactInputArea;