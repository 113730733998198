import api from "utils/api";

let ROOM_ID = localStorage.roomId;
let TRAVELER_ID = localStorage.travelerId;
const USER_INFO = localStorage.user_info;

const Authhelper = {
    GetAuthenticatedEmailAddress: async function () {
        let email = null;        
        try { email = await JSON.parse(USER_INFO).authenticatedEmail; } catch { }        
        return email;
    },

    GetAuthenticatedRoomId: async function () {
        let _roomId = null;
        if (typeof ROOM_ID !== 'undefined' && ROOM_ID !== null) {
            _roomId = ROOM_ID;
        } else {
            const AUTHENTICATED_EMAIL = await this.GetAuthenticatedEmailAddress();
            await api.get('/api/room/getRoomDetailByEmail?email=' + AUTHENTICATED_EMAIL)
                .then(response => {
                    if (response.data !== "") {
                        _roomId = response.data.roomInfo[0].id;
                        if (typeof _roomId !== 'undefined' && _roomId !== null) {
                            localStorage.setItem('roomId', _roomId);
                        }
                    }
                }).catch(error => { });
        }

        return _roomId;
    },

    GetAuthenticatedTravelerId: async function () {
        let _travelerId = null;
        if (typeof TRAVELER_ID !== 'undefined' && TRAVELER_ID !== null) {
            _travelerId = TRAVELER_ID;
        } else {
            const AUTHENTICATED_EMAIL = await this.GetAuthenticatedEmailAddress();
            await api.get('/api/room/getRoomDetailByEmail?email=' + AUTHENTICATED_EMAIL)
                .then(response => {
                    let guests = [];
                    response.data?.roomInfo.map((room) => { room.guestInfo.find((item) => { if (item.email === AUTHENTICATED_EMAIL) guests.push(item); }) })
                    _travelerId = guests[0]?.id;
                }).catch(error => { });
        }
        return _travelerId;
    },

    // Call for appSettings if only null
    InitializeAppSettings: async function () {
        const appSetting = this.GetAppSettingData();
        if (appSetting === null) {
            this.SetAppSettingData({});
            api.get('/api/appSetting/getSettings').then(setting => {
                this.SetAppSettingData(setting.data)
            }).catch(e => { this.SetAppSettingData(null) });
        }
    },

    GetAuthorizedJwt: () => {
        try {
            return JSON.parse(localStorage.user_info).jwtToken;
        } catch { return false; }
    },

    GetGroupId: () => {
        let _groupid = localStorage.group_id;
        return typeof _groupid !== 'undefined' ? _groupid : null;
    },
    RemoveGroupId: () => localStorage.removeItem('group_id')
    ,
    GetAuthenticatedFullName: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.fullName : null;
    }
    ,
    GetAuthenticatedInitials: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.initials : null;
    }
    ,
    GetAuthenticatedFirstName: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.firstName : null;
    }
    ,
    GetAuthenticatedLastName: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.lastName : null;
    }
    ,
    GetAuthenticatedPhoneNumber: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.phoneNo : null;
    }
    ,
    GetAssignedRoles: () => {
        let userInfo = localStorage.user_info;
        return typeof userInfo !== 'undefined' ? JSON.parse(userInfo)?.userRole : null;
    }
    ,
    IsGuestRoleAuthorized: () => {
        try {
            let roles = JSON.parse(localStorage.user_info)?.userRole;
            return roles.includes('Guest');
        } catch { return false; }
    }
    ,
    IsGroupLeaderRoleAuthorized: () => {
        try {
            let roles = JSON.parse(localStorage.user_info)?.userRole;
            return roles.includes('GroupLeader');
        } catch { return false; }
    },

    IsAdminRoleAuthorized: () => {
        try {
            let roles = JSON.parse(localStorage.user_info)?.userRole;
            return roles.includes('Admin');
        } catch { return false; }
    },

    IsAuthenticated: function () {
        if (typeof localStorage.user_info === "undefined" || localStorage.user_info === null) {
            return false;
        } else return true;
    },

    Logout: function (redirectUrl) {
        localStorage.clear();

        if (typeof redirectUrl === 'undefined' || redirectUrl === null)
            window.location.replace("/");

        if (typeof redirectUrl !== 'undefined' && redirectUrl !== null)
            window.location.replace(redirectUrl);
    },

    GetAppSettingData: () => {
        let appSetting = JSON.parse(localStorage.getItem("AppSetting"));
        return typeof appSetting !== 'undefined' || appSetting !== null ? appSetting : null;
    },

    SetAppSettingData: (_appSetting) => {
        localStorage.setItem("AppSetting", JSON.stringify(_appSetting))
    },

    GetExistingGuestDashboardGuestUrl: () => {
        let appSetting = localStorage.AppSetting;
        return typeof appSetting !== 'undefined' ? JSON.parse(appSetting)?.existingGuestDashboardGuestUrl : null;
    },

    GetSkipPreflightCheck: () => {
        let appSetting = localStorage.AppSetting;
        return typeof appSetting !== 'undefined' ? JSON.parse(appSetting)?.skipPreflightCheck : null;
    },
}


export default Authhelper;