import React, { useState } from "react";

import { getDateFormat, getFullName } from "./utils";

import plusIcon from "assets/images/icon-plus-square.svg";
import minusIcon from "assets/images/icon-minus-square.svg";

const GuestAccordion = ({
  firstName,
  middleName,
  lastName,
  dob,
  email,
  phone,
}) => {
  const [collapse, setCollpase] = useState(true);
  const fullName = getFullName(firstName, middleName, lastName);
  const formattedDob = getDateFormat(dob);
  return (
    <div className="p-1">
      <div
        className={`d-flex p-2 gap-md-1 align-items-center ${
          collapse ? "bg-light" : "bg-info text-white"
        }`}
      >
        <button
        type="button"
          className="outline-none bg-transparent border-0 pl-100"
          onClick={() => setCollpase((prev) => !prev)}
        >
          <img
            style={{ width: "1.5em" }}
            src={collapse ? plusIcon : minusIcon}
            alt={collapse ? "Expand" : "Collapse"}
          />
        </button>
        <div>{fullName}</div>
        <span> - {formattedDob}</span>
      </div>
      {!collapse && (
        <div className="px-4 py-2">
          <div>
            {fullName} - {formattedDob}
          </div>
          <div>{email}</div>
          <div>{phone}</div>
        </div>
      )}
    </div>
  );
};

const GuestDetails = (props) => {
  return (
    <div className="mt-4">
      <h4 className="text-center mb-2">Verify your traveler info now</h4>
      <div className="border">
        {props.adultTravelers.map((adult, i) => {
          const firstName = adult.adultFirstName;
          const middleName = adult.adultMiddleName;
          const lastName = adult.adultLastName;
          const dob = adult.adultDob;
          const email = adult.adultEmail;
          const phone = adult.adultPhone;
          const data = { firstName, middleName, lastName, dob, email, phone };
          return <GuestAccordion key={i} {...data} />;
        })}
        {props.childTravelers.map((child, i) => {
          const firstName = child.childFirstName;
          const middleName = child.childMiddleName;
          const lastName = child.childLastName;
          const dob = child.childDob;
          const data = { firstName, middleName, lastName, dob };
          return <GuestAccordion key={i} {...data} />;
        })}
      </div>
    </div>
  );
};

export default GuestDetails;
