import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";

const ReactInputDateField = ({ label, name, placeholder, error, onChange, value, maxDate, minDate, validate, disabled }) => {
    const [selectedDate, setSelectedDate] = useState("");

    useEffect(() => {
        if (value !== null && typeof value !== 'undefined') {
            var date = value.split('T')[0];
            setSelectedDate(date);
        }
    }, []);

    const onDateChange = (e) => {
        let rawDate = e.target.value;
        setSelectedDate(rawDate);
        let date = new Date(rawDate);
        onChange(date);
    };

    return (
        <div className="form-group">
            {label && <label> {label} </label>}
            <div>
                {/*onChange={onDateChange}*/}
                <input
                    value={selectedDate}
                    onChange={onDateChange}
                    onBlur={validate}
                    name={name}
                    placeholder={placeholder}
                    className="form-control"
                    type="date"
                    min={minDate}
                    max={maxDate}
                    disabled={disabled}
                />
                {error && <p className="error">{error}</p>}
            </div>            
        </div>
    )
}
export default ReactInputDateField;