import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactInputArea from '../../../../../../components/InputAreaField';


const RoomreservationHelp = (props) => {
    const onReturn = () => {
        props.callback(false);
    }

    return (
        <div className="modify-reservation">
            <div></div>
            <div>
                <a className="c-btn btn-block mt-2 " onClick={onReturn}>Return To Request Menu</a>
            </div>
        </div>
    );
}
export default withRouter(RoomreservationHelp);