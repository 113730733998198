import { Col, Container, Row } from "reactstrap";
import { store } from "../../../../../../context/store";
import Loading from "../../../../../../components/Loading";
import CoreHelper from "../../../../../../core/coreHelper";
import React, { useEffect, useState, useContext } from "react";
import CloseButton from "../../../../../../components/CloseButton";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { ReservationHelper } from "../../../../../../core/reservationHelper";
import { setTotalProgress } from "../../../../../../context/actions/reservationAction";
import renderHTML from "react-render-html";

const SelectedReservationRooms = (props) => {
  const [loader, setLoader] = useState(true);
  const [state, dispatch] = useContext(store);
  const [selectedRoomInfo, setSelectedRoomInfo] = useState(null);
  const [totalNightsSelected, setTotalNightsSelected] = useState(
    props.totalNightsSelected
  );
  useEffect(() => {
    let _room = props;
    setSelectedRoomInfo(_room.selectedRoom);
    setTotalNightsSelected(_room.totalNightsSelected);
    setLoader(false);
  }, []);

  const handleRoomSelect = async () => {
    //clear next steps
    clearNextSteps();

    let GROUP_ID = ReservationHelper.GetGroupId();

    let _hotelsInformation = await ReservationHelper.GetHotelsInformationAsync(
      GROUP_ID
    );

    let _CRMHotelInfo = "";

    _CRMHotelInfo = _hotelsInformation.find(
      (x) => x.crmHotelId === selectedRoomInfo.crmHotelCode
    );
    selectedRoomInfo.address = _CRMHotelInfo.address;

    ReservationHelper.SetSelectedRoom(selectedRoomInfo);
    ReservationHelper.SetSupplierCode(selectedRoomInfo.supplierCode);
    ReservationHelper.SetHotelCode(selectedRoomInfo.hotelCode);
    ReservationHelper.SetCRMHotelId(selectedRoomInfo.crmHotelCode);
    ReservationHelper.SetHotelName(selectedRoomInfo.hotelName);
    setTotalProgress(dispatch, 50);

    props.history.push("/guest/reservation/room-arrangements");
  };

  const clearNextSteps = () => {
    ReservationHelper.SetChildCount(0);
    ReservationHelper.SetAdultsCount(0);
    ReservationHelper.SetSelectedPaymentAmount(0);
  };

  return (
    <div className="relative c-selected-room">
      {loader && <Loading />}
      {!loader && selectedRoomInfo && (
        <>
          <Container>
            <div className="image-wrapper relative">
              <Row className="d-flex justify-content-center">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="image">
                      <a
                        href={
                          selectedRoomInfo.roomTypeContentDetailDTO
                            .roomImages[0].imageURL
                        }
                      >
                        <img
                          src={
                            selectedRoomInfo.roomTypeContentDetailDTO
                              .roomImages[0].imageURL
                          }
                        />
                      </a>
                    </div>

                    <Col lg={6}>
                      <Row>
                        {selectedRoomInfo.roomTypeContentDetailDTO.roomImages
                          .slice(1)
                          .map((image, i) => {
                            return (
                              <Col lg={6} key={i}>
                                <div
                                  className="image"
                                  style={{ display: "none" }}
                                >
                                  <a href={image.imageURL}>
                                    <img
                                      src={image.imageURL}
                                      alt={image.altTagText}
                                    />
                                  </a>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </Col>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </Row>
            </div>
            <div className="text-wrapper">
              <Row className="d-flex justify-content-center">
                {
                  <Col lg={12}>
                    <h3 className="title">{selectedRoomInfo.roomName}</h3>
                    <div className="description">
                      {renderHTML(
                        selectedRoomInfo.roomTypeContentDetailDTO.descriptions
                      )}
                    </div>
                    <ul className="c-check-list">
                      {selectedRoomInfo.roomTypeContentDetailDTO.roomFeatures
                        .length > 0 &&
                        selectedRoomInfo.roomTypeContentDetailDTO.roomFeatures
                          .split(",")
                          .map((list, i) => {
                            return <li key={i}>{list}</li>;
                          })}
                    </ul>
                  </Col>
                }

                <div
                  className="text-footer"
                  style={{
                    width: "100%",
                    padding: "1rem",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  {selectedRoomInfo.isSoldOut ? (
                    <></>
                  ) : (
                    <>
                      <div className="price">
                        <div className="price-section">
                          <div className="per-night">
                            <div className="number">
                              {CoreHelper.GetUsFormattedAmount(
                                selectedRoomInfo.pricePerNight
                              )}
                            </div>
                            <label> per night </label>
                          </div>
                          <div className="total">
                            <strong>
                              {CoreHelper.GetUsFormattedAmount(
                                selectedRoomInfo.travelZapHotelPrice
                              )}{" "}
                              for {totalNightsSelected} nights
                            </strong>
                          </div>
                          <div>(Includes taxes and fees)</div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectedRoomInfo.isSoldOut ? (
                    <>
                      <div
                        className="button"
                        style={{ width: "100%", marginTop: "1rem" }}
                      >
                        <a
                          onClick={handleRoomSelect}
                          className={`c-btn c-btn-disabled ${
                            !selectedRoomInfo.selected
                              ? "c-btn--ghost c-btn--ghost__primary"
                              : ""
                          }`}
                        >
                          {selectedRoomInfo.selected ? "SOLD OUT" : "SOLD OUT"}
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="button"
                        style={{
                          width: "100%",
                          marginTop: "2rem",
                          display: "flex",
                        }}
                      >
                        <a
                          onClick={handleRoomSelect}
                          className={`c-btn ${
                            !selectedRoomInfo.selected
                              ? "c-btn--ghost c-btn--ghost__primary btn-block"
                              : ""
                          }`}
                        >
                          {selectedRoomInfo.selected
                            ? "Selected"
                            : "Select Room"}
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </Row>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};
export default SelectedReservationRooms;
