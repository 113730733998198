import {
  useStripe,
  useElements,
  CardCvcElement,
  ElementsConsumer,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import Select from "react-select";
import SignatureCanvas from "react-signature-canvas";
import CoreHelper from "../../../../../../core/coreHelper";
import { EMAIL_VALIDATION_REGEX } from "../../../../../../core/globalConstant";
import React, { useEffect, useState, useRef } from "react";
import Authhelper from "../../../../../../core/authHelper";
import ReactInput from "../../../../../../components/InputField";
import { CREATE_PAYMENT } from "../../../../../../core/globalConstant";
import { DropDownStyleConstant } from "../../../../../../core/dropdownStyleConstant";

import { useCheckMobileScreen } from "hooks";

//DWA-522
const bermudaTerritories = [
  { value: "Devonshire", label: "Devonshire" },
  { value: "Hamilton", label: "Hamilton" },
  { value: "Paget", label: "Paget" },
  { value: "Pembroke", label: "Pembroke" },
  { value: "Sandys", label: "Sandys" },
  { value: "Smith's", label: "Smith's" },
  { value: "Southampton", label: "Southampton" },
  { value: "St George's", label: "St George's" },
  { value: "Warwick", label: "Warwick" },
  { value: "Flatts Village", label: "Flatts Village" },
  { value: "Somerset Village", label: "Somerset Village" },
];

const unitedKingdomTerritories = [
  { value: "England", label: "England" },
  { value: "Scotland", label: "Scotland" },
  { value: "Wales", label: "Wales" },
  { value: "Northern Ireland", label: "Northern Ireland" },
];

const costaRicaTerritories = [
  { value: "Alajuela", label: "Alajuela" },
  { value: "Cartago", label: "Cartago" },
  { value: "Guanacaste", label: "Guanacaste" },
  { value: "Heredia", label: "Heredia" },
  { value: "Limon", label: "Limon" },
  { value: "Puntarenas", label: "Puntarenas" },
  { value: "San José", label: "San José" },
];

const germanyTerritories = [
  { value: "Berlin", label: "Berlin" },
  { value: "Bayern (Bavaria)", label: "Bayern (Bavaria)" },
  {
    value: "Niedersachsen (Lower Saxony)",
    label: "Niedersachsen (Lower Saxony)",
  },
  { value: "Baden - Württemberg", label: "Baden - Württemberg" },
  {
    value: "Rheinland - Pfalz (Rhineland - Palatinate)",
    label: "Rheinland - Pfalz (Rhineland - Palatinate)",
  },
  { value: "Sachsen (Saxony)", label: "Sachsen (Saxony)" },
  { value: "Thüringen (Thuringia)", label: "Thüringen (Thuringia)" },
  { value: "Hessen", label: "Hessen" },
  {
    value: "Nordrhein - Westfalen (North Rhine - Westphalia)",
    label: "Nordrhein - Westfalen (North Rhine - Westphalia)",
  },
  {
    value: "Sachsen - Anhalt (Saxony - Anhalt)",
    label: "Sachsen - Anhalt (Saxony - Anhalt)",
  },
  { value: "Brandenburg", label: "Brandenburg" },
  { value: "Mecklenburg - Vorpommern", label: "Mecklenburg - Vorpommern" },
  { value: "Hamburg", label: "Hamburg" },
  { value: "Schleswig - Holstein", label: "Schleswig - Holstein" },
  { value: "Saarland", label: "Saarland" },
  { value: "Bremen", label: "Bremen" },
];

const australiaTerritories = [
  { value: "New South Wales", label: "New South Wales" },
  { value: "Queensland", label: "Queensland" },
  { value: "South Australia", label: "South Australia" },
  { value: "Tasmania", label: "Tasmania" },
  { value: "Victoria", label: "Victoria" },
  { value: "Western Australia", label: "Western Australia" },
];

const jamaicaTerritories = [
  { value: "Clarendon", label: "Clarendon" },
  { value: "Hanover", label: "Hanover" },
  { value: "Kingston", label: "Kingston" },
  { value: "Manchester", label: "Manchester" },
  { value: "Portland", label: "Portland" },
  { value: "Saint Andrew", label: "Saint Andrew" },
  { value: "Saint Ann", label: "Saint Ann" },
  { value: "Saint Catherine", label: "Saint Catherine" },
  { value: "Saint Elizabeth", label: "Saint Elizabeth" },
  { value: "Saint James", label: "Saint James" },
  { value: "Saint Mary", label: "Saint Mary" },
  { value: "Saint Thomas", label: "Saint Thomas" },
  { value: "Trelawny", label: "Trelawny" },
  { value: "Westmoreland", label: "Westmoreland" },
];

const usStates = [
  { value: "Alabama", label: "Alabama" },
  { value: "Alaska", label: "Alaska" },
  { value: "Arizona", label: "Arizona" },
  { value: "Arkansas", label: "Arkansas" },
  { value: "California", label: "California" },
  { value: "Colorado", label: "Colorado" },
  { value: "Connecticut", label: "Connecticut" },
  { value: "Delaware", label: "Delaware" },
  { value: "District of Columbia", label: "District of Columbia" },
  { value: "Florida", label: "Florida" },
  { value: "Georgia", label: "Georgia" },
  { value: "Hawaii", label: "Hawaii" },
  { value: "Idaho", label: "Idaho" },
  { value: "Illinois", label: "Illinois" },
  { value: "Indiana", label: "Indiana" },
  { value: "Iowa", label: "Iowa" },
  { value: "Kansas", label: "Kansas" },
  { value: "Kentucky", label: "Kentucky" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Maine", label: "Maine" },
  { value: "Maryland", label: "Maryland" },
  { value: "Massachusetts", label: "Massachusetts" },
  { value: "Michigan", label: "Michigan" },
  { value: "Minnesota", label: "Minnesota" },
  { value: "Mississippi", label: "Mississippi" },
  { value: "Missouri", label: "Missouri" },
  { value: "Montana", label: "Montana" },
  { value: "Nebraska", label: "Nebraska" },
  { value: "Nevada", label: "Nevada" },
  { value: "New Hampshire", label: "New Hampshire" },
  { value: "New Jersey", label: "New Jersey" },
  { value: "New Mexico", label: "New Mexico" },
  { value: "New York", label: "New York" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "North Dakota", label: "North Dakota" },
  { value: "Ohio", label: "Ohio" },
  { value: "Oklahoma", label: "Oklahoma" },
  { value: "Oregon", label: "Oregon" },
  { value: "Pennsylvania", label: "Pennsylvania" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Rhode Island", label: "Rhode Island" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "South Dakota", label: "South Dakota" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
  { value: "Utah", label: "Utah" },
  { value: "Vermont", label: "Vermont" },
  { value: "Virginia", label: "Virginia" },
  { value: "Washington", label: "Washington" },
  { value: "West Virginia", label: "West Virginia" },
  { value: "Wisconsin", label: "Wisconsin" },
  { value: "Wyoming", label: "Wyoming" },
];

const canadianProvinces = [
  { value: "Alberta", label: "Alberta" },
  { value: "British Columbia", label: "British Columbia" },
  { value: "Manitoba", label: "Manitoba" },
  { value: "New Brunswick", label: "New Brunswick" },
  { value: "Newfoundland and Labrador", label: "Newfoundland and Labrador" },
  { value: "Northwest Territories", label: "Northwest Territories" },
  { value: "Nova Scotia", label: "Nova Scotia" },
  { value: "Nunavut", label: "Nunavut" },
  { value: "Ontario", label: "Ontario" },
  { value: "Prince Edward Island", label: "Prince Edward Island" },
  { value: "Quebec", label: "Quebec" },
  { value: "Saskatchewan", label: "Saskatchewan" },
  { value: "Yukon", label: "Yukon" },
];
const mexicanStates = [
  {
    value: "Distrito Federal",
    label: "Distrito Federal",
  },
  {
    value: "Aguascalientes",
    label: "Aguascalientes",
  },
  {
    value: "Baja California",
    label: "Baja California",
  },
  {
    value: "Baja California Sur",
    label: "Baja California Sur",
  },
  {
    value: "Campeche",
    label: "Campeche",
  },
  {
    value: "Chiapas",
    label: "Chiapas",
  },
  {
    value: "Chihuahua",
    label: "Chihuahua",
  },
  {
    value: "Coahuila",
    label: "Coahuila",
  },
  {
    value: "Colima",
    label: "Colima",
  },
  {
    value: "Durango",
    label: "Durango",
  },
  {
    value: "Guanajuato",
    label: "Guanajuato",
  },
  {
    value: "Guerrero",
    label: "Guerrero",
  },
  {
    value: "Hidalgo",
    label: "Hidalgo",
  },
  {
    value: "Jalisco",
    label: "Jalisco",
  },
  {
    value: "Michoacán",
    label: "Michoacán",
  },
  {
    value: "Morelos",
    label: "Morelos",
  },
  {
    value: "México",
    label: "México",
  },
  {
    value: "Nayarit",
    label: "Nayarit",
  },
  {
    value: "Nuevo León",
    label: "Nuevo León",
  },
  {
    value: "Oaxaca",
    label: "Oaxaca",
  },
  {
    value: "Puebla",
    label: "Puebla",
  },
  {
    value: "Querétaro",
    label: "Querétaro",
  },
  {
    value: "Quintana Roo",
    label: "Quintana Roo",
  },
  {
    value: "San Luis Potosí",
    label: "San Luis Potosí",
  },
  {
    value: "Sinaloa",
    label: "Sinaloa",
  },
  {
    value: "Sonora",
    label: "Sonora",
  },
  {
    value: "Tabasco",
    label: "Tabasco",
  },
  {
    value: "Tamaulipas",
    label: "Tamaulipas",
  },
  {
    value: "Tlaxcala",
    label: "Tlaxcala",
  },
  {
    value: "Veracruz",
    label: "Veracruz",
  },
  {
    value: "Yucatán",
    label: "Yucatán",
  },
  {
    value: "Zacatecas",
    label: "Zacatecas",
  },
];

const statesOrProvinces = {
  CA: canadianProvinces,
  MX: mexicanStates,
  US: usStates,
  BM: bermudaTerritories,
  UK: unitedKingdomTerritories,
  CR: costaRicaTerritories,
  DE: germanyTerritories,
  AU: australiaTerritories,
  JM: jamaicaTerritories,
};

const countries = [
  { value: "US", label: "United States" },
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "BM", label: "Bermuda" },
  { value: "UK", label: "United Kingdom" },
  { value: "CR", label: "Costa Rica" },
  { value: "DE", label: "Germany" },
  { value: "AU", label: "Australia" },
  { value: "JM", label: "Jamaica" },
];
const CARD_OPTIONS = {
  iconStyle: "solid",
  showIcon: true,
  placeholder: "Card Number",
  style: {
    base: {
      iconColor: "#000",
      color: "#000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#dadada" },
    },
    invalid: {
      iconColor: "#B01C1E",
      color: "#B01C1E",
      "::placeholder": { color: "#B01C1E" },
    },
  },
};

const termsAndConditions = (
  <>
    By signing above I accept the following terms, including the cancellation
    policy: I hereby authorize Destify to charge the amounts above and
    acknowledge the charges described herein. My signature represents the
    authorization of the amount to be charged when it is billed and my
    acceptance of the{" "}
    <a href="https://destify.com/terms/" target="_blank">
      Fair Trade Agreement
    </a>
    , including the cancellation policy.{" "}
    <strong>I understand that deposits are nonrefundable</strong> and payment in
    full is due by the full payment deadline. My booking will not be processed
    until this completed authorization form is received.
    <span> I also acknowledge that these rates do not include airfare.</span>
    <br />
    <br />
    <a href="https://destify.com/privacy-policy/" target="_blank">
      Privacy Policy
    </a>
  </>
);

const CheckoutForm = (props) => {
  let signaturePad = {};
  const buttonRef = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({
    cardNumber: null,
  });

  // form fields
  const [zip, setZip] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [travelerId, setTravelerId] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);
  const [paymentAgreement, setPaymentAgreement] = useState(false);

  const handleStateChange = (e) => setState(e.value);
  const handleCountryChange = (e) => {
    setState(null);
    setCountry(e.value);
  };
  const onSignatureClear = () => signaturePad.clear();
  const onPayinFullClick = () => {
    setAmountToPay(props.e?.itineraryDetail?.roomBalanceDue);
  };

  useEffect(async () => {
    Authhelper.GetAuthenticatedTravelerId().then((TRAVELER_ID) => {
      setTravelerId(TRAVELER_ID);
    });
  }, []);

  const handleInputChange = (e) => {
    if (e.target.name === "zip") setZip(e.target.value);
    if (e.target.name === "city") setCity(e.target.value);
    if (e.target.name === "email") setEmail(e.target.value);
    if (e.target.name === "address") setAddress(e.target.value);
    if (e.target.name === "lastName") setLastName(e.target.value);
    if (e.target.name === "amount") setAmountToPay(e.target.value);
    if (e.target.name === "firstName") setFirstName(e.target.value);
    if (e.target.name === "agreement") setPaymentAgreement(e.target.checked);
  };

  const handleFormValidation = (cardNumber, expiryDate, cvcNumber) => {
    let formErrors = {};
    let t = 0;

    const emailRegex = EMAIL_VALIDATION_REGEX;
    if (zip === null || zip.trim().length === 0)
      formErrors.zip = "Zip/Postal-Code is required";
    if (city === null || city.trim().length === 0)
      formErrors.city = "City is required";
    if (!emailRegex.test(email)) formErrors.email = "Invalid email address";
    if (email === null || email.trim().length === 0)
      formErrors.email = "Email is required";
    if (state === null)
      formErrors.state = "State/Province/Territory is required";
    if (country === null) formErrors.country = "Country is required";
    if (address === null || address.trim().length === 0)
      formErrors.address = "Address is required";
    if (lastName === null || lastName.trim().length === 0)
      formErrors.lastName = "Lastname is required";
    if (firstName === null || firstName.trim().length === 0)
      formErrors.firstName = "Firstname is required";
    if (signaturePad.isEmpty()) formErrors.signature = "Signature is required";
    if (!paymentAgreement)
      formErrors.paymentAgreement = "Payment agreement is required";

    for (let i = 0; i < signaturePad._sigPad._data.length; i++) {
      t = t + signaturePad._sigPad._data[i].length;
    }

    if (t < 30)
      formErrors.signature =
        "Signature is too short. Please click clear and try again.";

    //for stripe components
    if (cardNumber !== null) formErrors.cardNumber = cardNumber;
    if (expiryDate !== null) formErrors.cardExpiry = expiryDate;
    if (cvcNumber !== null) formErrors.cvcNumber = cvcNumber;

    if (props.e.formType === CREATE_PAYMENT) {
      if (amountToPay === null) formErrors.amount = "Amount is required";
    }
    setFormErrors(formErrors);
    return Object.keys(formErrors).length === 0 &&
      cardNumber === null &&
      expiryDate === null &&
      cvcNumber === null
      ? true
      : false;
  };

  const handlePaymentSubmit = async (event) => {
    event.preventDefault();
    let _signature = signaturePad.getTrimmedCanvas().toDataURL("image/png");
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    const errors = handleFormValidation(
      !cardNumberElement._complete ? "Card Number is invalid" : null,
      !cardExpiryElement._complete ? "Card Expiry is invalid" : null,
      !cardCvcElement._complete ? "CVC Number is invalid" : null
    );

    if (errors) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          address: {
            city: city,
            country: country,
            state: state,
            postal_code: zip,
            line1: address,
          },
          email: email,
          name: `${firstName} ${lastName}`,
        },
      });

      const { token } = await stripe.createToken(
        elements.getElement(CardNumberElement),
        {
          address_city: city,
          address_country: country,
          address_state: state,
          address_zip: zip,
          address_line1: address,
        }
      );

      if (payload.error) {
        setError(payload.error.message);
      } else {
        buttonRef.current.innerHTML = "Processing";
        setError(null);
        cardCvcElement.clear();
        cardExpiryElement.clear();
        cardNumberElement.clear();

        callPaymentApi(token, _signature);
      }
    } else {
      return false;
    }
  };

  async function callPaymentApi(token, signature) {
    const payload = {
      zip: zip,
      city: city,
      country: country,
      address: address,
      email: email,
      firstName: firstName,
      lastName: lastName,
      travelerId: travelerId,
      paymentAmount: parseFloat(amountToPay),
      paymentToken: token.id,
      customerToken: token.card.id,
      signature: signature,
      iP: token.client_ip,
      device: CoreHelper.GetDeviceType(),
      last4: token.card.last4,
      insuranceAmount: 0,
      insurancePurchased:
        typeof props.e.guestDetail !== "undefined"
          ? props.e.guestDetail.purchasedInsurance === "1"
            ? true
            : false
          : false,
      paymentType:
        typeof props.e.roomDetail !== "undefined"
          ? props.e.roomDetail.totalAmountPaid === 0
            ? "Deposit"
            : "BalancePayment"
          : null,
    };
    props.e.callback(payload);
  }

  const isMobile = useCheckMobileScreen();

  return (
    <form onSubmit={handlePaymentSubmit} className="c-form">
      {props.e.formType === CREATE_PAYMENT && (
        <>
          <div>
            <strong>Total Package Amount: </strong>
            <span>
              {props.e?.itineraryDetail?.formattedTotalPackageAmountforRoom}
            </span>
            <br></br>
            <strong>
              Final Payment Due in{" "}
              {props.e?.itineraryDetail?.daysTillFinalPayment} Days:{" "}
            </strong>
            <span>
              {props?.itineraryDetail?.finalPaymentDeadline?.split("T")[0]}
            </span>
            <br></br>
            <strong>Remaining Balance: </strong>
            <span>{props.e?.itineraryDetail?.formattedBalanceRemaining}</span>
          </div>
          <br></br>
          <div className="row">
            <div className="col-md-7">
              <div className="form-group">
                <ReactInput
                  value={amountToPay}
                  name="amount"
                  type="number"
                  label="Amount to Pay"
                  error={formErrors && formErrors.amount}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-md-1">
              <div className="form-group">
                <div className="or">Or</div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group pay-in-full">
                <a className="c-btn btn-block mt-4" onClick={onPayinFullClick}>
                  Pay in Full
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <ReactInput
              name="firstName"
              type="text"
              placeholder={"First Name"}
              error={formErrors && formErrors.firstName}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <ReactInput
              name="lastName"
              type="text"
              placeholder="Last Name"
              error={formErrors && formErrors.lastName}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <ReactInput
              name="email"
              type="text"
              placeholder="Email"
              error={formErrors && formErrors.email}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="form-group card-number">
            <CardNumberElement
              className={
                formErrors.cardNumber
                  ? "StripeElement stripe-error"
                  : "StripeElement"
              }
              options={CARD_OPTIONS}
            />
            <span className="error">{formErrors && formErrors.cardNumber}</span>
          </div>
        </div>
        <div className="col-md-6 col-sm-12">
          <div className="row row-cols-md-2">
            <div className="col">
              <div className="form-group card-expiry">
                <CardExpiryElement
                  className={
                    formErrors.cardExpiry
                      ? "StripeElement stripe-error"
                      : "StripeElement"
                  }
                  options={{
                    style: { base: { "::placeholder": { color: "#dadada" } } },
                  }}
                />
                <span className="error">
                  {formErrors && formErrors.cardExpiry}
                </span>
              </div>
            </div>
            <div className="col">
              <div className="form-group card-cvc">
                <CardCvcElement
                  className={
                    formErrors.cvcNumber
                      ? "StripeElement stripe-error"
                      : "StripeElement"
                  }
                  options={{
                    style: { base: { "::placeholder": { color: "#dadada" } } },
                  }}
                />
                <span className="error">
                  {formErrors && formErrors.cvcNumber}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h5 className="mb-4">Billing Address</h5>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <Select
                isSearchable={false}
                styles={DropDownStyleConstant}
                classNamePrefix="c-select"
                name="country"
                placeholder="Select Country"
                options={countries}
                onChange={handleCountryChange}
              />
              <p className="error">{formErrors && formErrors.country}</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <ReactInput
                name="address"
                type="text"
                placeholder="Address"
                error={formErrors && formErrors.address}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <ReactInput
                name="city"
                type="text"
                placeholder="City"
                error={formErrors && formErrors.city}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <Select
              isSearchable={false}
              styles={DropDownStyleConstant}
              classNamePrefix="c-select"
              name="state"
              disabled={!country}
              options={country ? statesOrProvinces[country] : []}
              placeholder="Select State/Province/Territory"
              onChange={handleStateChange}
            />
            <p className="error">{formErrors && formErrors.state}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <ReactInput
              name="zip"
              type="text"
              placeholder="Zip/Postal-Code"
              error={formErrors && formErrors.zip}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <div className="sigCanvas">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5>Electronic Signature</h5>
                <a onClick={onSignatureClear}>Clear Signature</a>
              </div>
              <SignatureCanvas
                penColor="black"
                ref={(ref) => {
                  signaturePad = ref;
                }}
                canvasProps={{
                  width: 600,
                  height: 200,
                  style: {
                    border: "1px solid #e8e9ea",
                    borderRadius: "8px",
                  },
                }}
              />
            </div>
            <p className="error">{formErrors && formErrors.signature}</p>
            <div className="sigpad-info">
              Use your mouse (or your finger on mobile devices) to draw your
              signature.
            </div>
          </div>
        </div>
      </div>

      <div className="form-group">
        <p className="error">
          {formErrors.paymentAgreement && formErrors.paymentAgreement}
        </p>
        <div className="row">
          <div className="col">
            <label className="text-left">
              <div
                style={{ marginBottom: "-1.5em" }}
                className={`float-left mr-2 ${isMobile ? "col-2" : "col-md-1"}`}
              >
                <ReactInput
                  name="agreement"
                  type="checkbox"
                  onChange={handleInputChange}
                />
              </div>
              {termsAndConditions}
            </label>
          </div>
        </div>
      </div>

      {error && (
        <div className="col-12">
          <div className="form-group">
            <div className="input">
              <p className="error text-sm alert alert-danger">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div className="col-md-12">
        <div className="form-group mb-0" style={{ textAlign: "center" }}>
          <button
            className="c-btn c-btn--black c-btn--lg text-white"
            ref={buttonRef}
            type="submit"
            disabled={!stripe}
          >
            Submit Your Payment
          </button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm
