
import {
    SET_CONFIRMATION_STATUS,
    SET_DATE_STATUS,
    SET_NEXT_RESERVATION_URL,
    SET_PREV_RESERVATION_URL,
    SET_ROOMS_STATUS,
    SET_ROOM_ARRANGEMENTS_STATUS,
    SET_TOTAL_PROGRESS
} from "../actionTypes";

const setDateStatus = (status) => ({ type: SET_DATE_STATUS, payload: status });
const setRoomsStatus = (status) => ({ type: SET_ROOMS_STATUS, payload: status });
const setTotalProgressStatus = (per) => ({ type: SET_TOTAL_PROGRESS, payload: per });
const setNextReservationStateUrl = (url) => ({ type: SET_NEXT_RESERVATION_URL, payload: url })
const setPrevReservationStateUrl = (url) => ({ type: SET_PREV_RESERVATION_URL, payload: url })
const setConfirmationStatus = (status) => ({ type: SET_CONFIRMATION_STATUS, payload: status });
const setRoomArrangementsStatus = (status) => ({ type: SET_ROOM_ARRANGEMENTS_STATUS, payload: status });


export const setDates = (dispatch, status) => {
    dispatch(setDateStatus(status));
}

export const setRooms = (dispatch, status) => {
    dispatch(setRoomsStatus(status));
}

export const setRoomArrangements = (dispatch, status) => {
    dispatch(setRoomArrangementsStatus(status));
}

export const setConfirmation = (dispatch, status) => {
    dispatch(setConfirmationStatus(status));
}

export const setTotalProgress = (dispatch, per) => {
    dispatch(setTotalProgressStatus(per));
}

export const setNextReservationUrl = (dispatch, url) => {
    dispatch(setNextReservationStateUrl(url));
}

export const setPreviousReservationUrl = (dispatch, url) => {
    dispatch(setPrevReservationStateUrl(url));
}