import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactInput from '../../../../../../components/InputField';


const UpgradeMyRoom = (props) => {
    const onCancel = () => {
        props.callback(false);
    }

    const onSubmit = () => {
        //TODO:call API to submit request
        props.callback(false);
    }

    return (
        <div className="modify-reservation">
            <form className="c-form">
                <div>
                    <span>Please choose a room category from the dropdown menu below. You can see more details by visiting <a href="https://partytilhardy.com/" target="_blank">partytillhardy.com</a></span>
                    <br></br>
                    <br></br>
                    <ReactInput type="text" label="I want to upgrade to the" name="" />
                    <div className="agreement">
                        <input name="agreement" type="checkbox" />
                        <span>Required - By checking this box I understand that I am requesting to upgrade my room.
                        Upgrading your room may change your current price. A Guest Services team member will
                        contact you to confirm any price changes before any change in booking will be made.</span>
                    </div>
                </div>
                <div className="button-group">
                    <a className="c-btn btn-block mt-2 " onClick={onCancel}>Cancel</a>
                    <a className="c-btn btn-block mt-2 reservation-submit-btn" onClick={onSubmit}>Submit</a>
                </div>
            </form>
        </div>
    );
}
export default withRouter(UpgradeMyRoom);