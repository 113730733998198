import React from "react";

import CoreHelper from "core/coreHelper";

import { getDateFormat, getFullName } from "./utils";

const GuestDetails = ({ adultTravelers, childTravelers }) => {
  return (
    <>
      <h4 className="guest-title">Guest Information</h4>
      {adultTravelers?.length > 0 && (
        <div className="adults-info">
          <h4 className="adults-info-title">Adults:</h4>

          <div className="adults">
            {adultTravelers.map((adult, i) => {
              const firstName = adult.adultFirstName;
              const middleName = adult.adultMiddleName;
              const lastName = adult.adultLastName;
              return (
                <div className="guest-information" key={i}>
                  <p className="guest-item">
                    <span className="guest-item-title">Name:</span>{" "}
                    {getFullName(firstName, middleName, lastName)}
                  </p>
                  <p className="guest-item">
                    <span className="guest-item-title">Date of Birth:</span>{" "}
                    {getDateFormat(adult.adultDob)}
                  </p>
                  <p className="guest-item">
                    <span className="guest-item-title"> Email:</span>{" "}
                    <span>{adult.adultEmail}</span>
                  </p>
                  <p className="guest-item">
                    <span className="guest-item-title">Phone:</span>{" "}
                    <span>
                      {CoreHelper.GetFormatPhoneNumber(adult.adultPhone)}
                    </span>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {childTravelers?.length > 0 && (
        <div className="children-info">
          <h4 className="children-info-title">Children:</h4>

          <div className="children">
            {childTravelers.map((child, i) => {
              const firstName = child.childFirstName;
              const middleName = child.childMiddleName;
              const lastName = child.childLastName;
              return (
                <div className="guest-information" key={i}>
                  <p className="guest-item">
                    <span className="guest-item-title">Name:</span>{" "}
                    {getFullName(firstName, middleName, lastName)}
                  </p>
                  <p className="guest-item">
                    <span className="guest-item-title">Date of Birth:</span>{" "}
                    {getDateFormat(child.childDob)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default GuestDetails;
