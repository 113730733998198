import React, { useContext, useEffect, useState } from "react";
import SubMainScreen from "../../../../../../layouts/SubMainScreen";
import { Link, useLocation, useHistory } from "react-router-dom";
import BackButton from "../../../../../../components/BackButton";
import MenuIcon from "../../../../../../assets/images/icon-menu.svg";
import { StateProvider, store } from "../../../../../../context/store";
import { Row, Col } from "reactstrap";
import { ReactComponent as ProgressIcon } from "../../../../../../assets/images/icon-beach.svg";
import { ReservationStepsValidator } from "../../../../../../core/reservationHelper";
import { setTotalProgress } from "../../../../../../context/actions/reservationAction";

const STEP_1_SELECT_DATES = "/guest/reservation/select-date";
const STEP_2_SELECT_ROOMS = "/guest/reservation/rooms";
const STEP_3_ENTER_PASSENGER_INFO = "/guest/reservation/room-arrangements";
const STEP_4_MANUAL_ROOM_REQUEST = "/guest/reservation/request-room";
const STEP_5_CONFIRMATION_PAYMENT_PAGE = "/guest/reservation/confirmation";

const GuestReservation = (props) => {
  const [state, dispatch] = useContext(store);
  let location = useLocation();
  let history = useHistory();

  const { reservationState } = state;
  const [progress, setProgress] = useState(0);
  const [menuActive, setMenuActive] = useState(false);

  const enableStep1 = ReservationStepsValidator.IsSelectDatesStepValid();

  const enableStep2 =
    ReservationStepsValidator.IsSelectRoomStepValid() &&
    reservationState.total >= 25; //DWA-2918
  const enableStep3 =
    ReservationStepsValidator.IsRoomArrangementStepValid() &&
    reservationState.total >= 75; //DWA-2918
  const enableStep4 =
    ReservationStepsValidator.IsConfirmationStepValid() &&
    reservationState.total >= 100; //DWA-2918

  useEffect(() => {
    setProgress(reservationState.total);
  }, [reservationState.total]);

  const handleMenuClick = () => {
    setMenuActive(!menuActive);
  };

  //DWA-2918
  const updateStepProgress = (progress) => {
    setTotalProgress(dispatch, progress);
  };

  const handleBackButton = (e) => {
    e.preventDefault();
    const currentPath = location.pathname?.toLowerCase();
    if (currentPath === STEP_5_CONFIRMATION_PAYMENT_PAGE)
      history.push(STEP_3_ENTER_PASSENGER_INFO);
    if (
      currentPath === STEP_3_ENTER_PASSENGER_INFO ||
      currentPath === STEP_4_MANUAL_ROOM_REQUEST
    )
      history.push(STEP_2_SELECT_ROOMS);
    if (currentPath === STEP_2_SELECT_ROOMS) history.push(STEP_1_SELECT_DATES);
  };

  return (
    <SubMainScreen type="layout-2">
      <div className="page-reservation">
        <div className="c-itinerary-progress">
          <div className="progress-bar">
            <div
              className="progress-icon"
              style={{ left: `${progress}%`, transform: "translateX(-30px)" }}
            >
              <ProgressIcon />
            </div>
            <div className="bar" style={{ width: `${progress}%` }}></div>
          </div>
        </div>

        <div className="page-reservation__content">
          <div
            className="reservation-menu--toggler d-lg-none"
            onClick={handleMenuClick}
          >
            <img src={MenuIcon} alt="Menu" />
          </div>
          <Row>
            <Col lg={3}>
              <div
                className={`sticky top-30 reservation-menu ${
                  menuActive ? "active" : ""
                }`}
              >
                <ul className="c-numbered-list">
                  <li
                    className={`${
                      location.pathname === "/guest/reservation/select-date"
                        ? "selected"
                        : ""
                    }`}
                  >
                    <Link
                      onClick={() => updateStepProgress(0)}
                      to="/guest/reservation/select-date"
                      className={
                        `${!enableStep1 ? "disable-link " : ""}` +
                        `${
                          enableStep1 &&
                          location.pathname != "/guest/reservation/select-date"
                            ? "filled"
                            : ""
                        }`
                      }
                    >
                      <span className="number">1</span>
                      <span className="text">Select Dates</span>
                    </Link>
                  </li>

                  <li
                    className={`${
                      location.pathname === "/guest/reservation/rooms"
                        ? "selected"
                        : ""
                    }`}
                  >
                    <Link
                      onClick={() => updateStepProgress(25)}
                      to="/guest/reservation/rooms"
                      className={
                        `${!enableStep2 ? "disable-link " : ""}` +
                        `${
                          enableStep2 &&
                          location.pathname != "/guest/reservation/rooms"
                            ? "filled"
                            : ""
                        }`
                      }
                    >
                      <span className="number">2</span>
                      <span className="text">Select Room</span>
                    </Link>
                  </li>

                  <li
                    className={`${
                      location.pathname ===
                        "/guest/reservation/room-arrangements" ||
                      location.pathname === "/guest/reservation/request-room"
                        ? "selected"
                        : ""
                    }`}
                  >
                    <Link
                      onClick={() => updateStepProgress(75)}
                      to="/guest/reservation/room-arrangements"
                      className={
                        `${!enableStep3 ? "disable-link " : ""}` +
                        `${
                          enableStep3 &&
                          (location.pathname !=
                            "/guest/reservation/room-arrangements" ||
                            location.pathname ===
                              "/guest/reservation/request-room")
                            ? "filled"
                            : ""
                        }`
                      }
                    >
                      <span className="number">3</span>
                      <span className="text">Room Arrangements</span>
                    </Link>
                  </li>

                  <li
                    className={`${
                      location.pathname === "/guest/reservation/confirmation"
                        ? "selected"
                        : ""
                    }`}
                  >
                    <Link
                      onClick={() => updateStepProgress(100)}
                      to="/guest/reservation/confirmation"
                      className={
                        `${!enableStep4 ? "disable-link " : ""}` +
                        `${enableStep4 ? "filled" : ""}`
                      }
                    >
                      <span className="number">4</span>
                      <span className="text">Confirmation</span>
                    </Link>
                  </li>
                </ul>
                <div className="d-flex justify-content-between button-wrapper">
                  {location.pathname !== "/guest/reservation/select-date" && (
                    <BackButton onClick={handleBackButton} />
                  )}
                </div>
              </div>
              <div
                className={`reservation-menu--overlay ${
                  menuActive ? "active" : ""
                }`}
                onClick={handleMenuClick}
              ></div>
            </Col>
            <Col lg={9}>
              <div className="section-content">{props.children}</div>
            </Col>
          </Row>
        </div>
      </div>
    </SubMainScreen>
  );
};
export default GuestReservation;
