import api from "utils/api";
import { Row, Col } from 'reactstrap';
import React, { useState } from 'react';
import Loading from "../../../../../../components/Loading";
import ReactInput from '../../../../../../components/InputField';
import PhoneField from '../../../../../../components/PhoneField';
import { EMAIL_VALIDATION_REGEX } from 'core/globalConstant';

const UserForm = (props) => {
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);

    const [inputs, setInputs] = useState({
        firstName: props.user !== null ? props.user.firstName : '',
        lastName: props.user !== null ? props.user.lastName : '',
        email: props.user !== null ? props.user.email : '',
        phone: props.user !== null ? props.user.phone : ''
    });

    const handlePhoneInputChange = (e) => {
        let _inputs = inputs;
        _inputs.phone = e;
        setInputs(_inputs);
    }

    const handleInputChange = (e) => {
        e.persist();
        setInputs(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    }

    const handleValidation = () => {
        let errors = {};
        const emailRegex = EMAIL_VALIDATION_REGEX;
        if (!inputs.firstName) errors.firstName = "Firstname is required";
        if (!inputs.lastName) errors.lastName = "Lastname is required";

        if (props.user === null) {
            if (!inputs.email) errors.email = "Email is required";
            if (!emailRegex.test(inputs.email)) errors.email = "Invalid email address";
        }
        setErrors(errors);
        return errors;
    }

    const onUserInformationSubmit = (e) => {
        e.preventDefault();
        const errors = handleValidation();

        if (Object.keys(errors).length === 0) {
            setLoader(true);
            let _userData = {
                firstName: inputs.firstName,
                lastName: inputs.lastName,
                email: inputs.email,
                phone: inputs.phoneNo,
            }

            if (props.user === null) {
                _userData.id = null;
                api.post("/api/manageuser/createUser", _userData).then(response => {
                    setLoader(false);
                    if (response.data.success) {
                        props.callback("User Created", "New user created successfully.");
                    } else props.callback("Error Occured", response.data.error[0] ?? "Oops! Encountered an error occured while creating new user.");
                }).catch(error => {
                    setLoader(false);
                    props.callback("Error Occured", "Oops! Encountered an error occured while creating new user.");
                });
            } else {
                _userData.id = props.user.id;
                api.put("/api/manageuser/updateUser", _userData).then(response => {
                    setLoader(false);
                    if (response) {
                        props.callback("Information Updated", "User information updated successfully.");
                    } else props.callback("Error Occured", "Oops! Encountered an error occured while updating the user information.");
                }).catch(error => {
                    setLoader(false);
                    props.callback("Error Occured", "Oops! Encountered an error occured while updating the user information.");
                });
            }
        }
        else return false;
    }

    return (
        <div type="layout-2">
            {loader && <Loading />}
            {!loader && <>
                <div className="w-100">
                    <Row className="d-flex justify-content-center">
                        <Col lg="12">
                            <form className="c-form" onSubmit={onUserInformationSubmit}>
                                <ReactInput value={inputs.firstName} type="text" label="First Name" name="firstName" error={errors && errors.firstName} onChange={handleInputChange} />
                                <ReactInput value={inputs.lastName} type="text" label="Last Name" name="lastName" error={errors && errors.lastName} onChange={handleInputChange} />
                                {props.user === null &&
                                    <ReactInput value={inputs.email} type="text" label="Email" name="email" error={errors && errors.email} onChange={handleInputChange} />
                                }
                                <PhoneField value={inputs.phone} international label="Phone Number" name="phone" error={errors && errors.phone} onChange={handlePhoneInputChange} />

                                <div className="d-flex justify-content-center">
                                    <button type="submit" className="c-btn c-btn--ghost c-btn--ghost__primary">Submit</button>
                                </div>
                            </form>
                        </Col>
                    </Row>
                </div>
            </>}
        </div >
    )
}

export default UserForm;