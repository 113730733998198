import api from "utils/api";
import CheckoutForm from "./checkoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";

const Stripe = (props) => {
  const [stripe, setStripe] = useState(null);

  const loadStripeInstance = async () => {
    api
      .get("/api/appSetting/getSettings")
      .then((response) => response.data.stripePublicKey)
      .then(loadStripe)
      .then(setStripe)
      .catch(console.error);
  };

  useEffect(() => {
    loadStripeInstance();
  }, []);

  return (
    <div style={{ minHeight: "100vh" }}>
      {stripe && (
        <Elements stripe={stripe}>
          <div className="c-stripe">
            <CheckoutForm e={props} />
          </div>
        </Elements>
      )}
    </div>
  );
};

export default Stripe;
