import React, { useState } from 'react';
import ClosedEyeIcon from '../../assets/images/icon-eye-close.svg';
import OpenedEyeIcon from '../../assets/images/icon-eye-open.svg';
const ReactPassword = ({label, name,  placeholder, error, onChange}) => {
    const [isPasswordVisible, setPasswordVisiblity] = useState(false);
    const handlePasswordVisiblityToggle = () => {
        setPasswordVisiblity(!isPasswordVisible);
    }
    return (
        <div className="form-group">
           {label && <label> {label} </label> }
           <div className="password-block">
            <input type={isPasswordVisible?"text":"password"} placeholder={placeholder} name={name} className={`form-control ${error?"form-error":""}`} onChange={onChange}/>
            <div className="toggle-icon" onClick={handlePasswordVisiblityToggle}>
                {!isPasswordVisible && <img src={ClosedEyeIcon} alt="Eye"/>}
                {isPasswordVisible && <img src={OpenedEyeIcon} alt="Eye"/>}
            </div>
           </div>
           {error && <p className="error">{error}</p>}
        </div>
    )
}
export default ReactPassword;