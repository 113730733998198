import React from 'react';
import Authhelper from "./authHelper";
import { Redirect, Route } from "react-router-dom";

function PrivateRoute({ auth, ...props }) {
    const isAllowed = Authhelper.IsAuthenticated();
    return isAllowed
        ? (<Route {...props} />)
        : (<Redirect to="/login" />)

};

export default PrivateRoute;