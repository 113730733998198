import api from "utils/api";
import TransferForm from './form';
import Modal from 'react-bootstrap/Modal';
import GuestFlight from '../flight/index';
import { Row, Col, Container } from "reactstrap";
import React, { useEffect, useState } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import FlightImage from '../../../../../assets/images/flight.svg';
import AMSTARLOGO from '../../../../../assets/images/logo-amstar.png';

const GuestTransfer = () => {
  const [loader, setLoader] = useState(true);
  const [flightInfo, setFlightInfo] = useState(false);
  const [vacationtype, setVacationType] = useState(null);
  const [transferDetail, setTransferDetail] = useState(false);
  const [loggedInTravelerId, setLoggedInTravelerId] = useState(null);

  const [showTransferModal, setShowTransferModal] = useState(false);
  const handleTransferModalClose = () => setShowTransferModal(false);

  const [responseBody, setResponseBody] = useState(null);
  const [responseTitle, setResponseTitle] = useState(null);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const handleResponseModalClose = () => setShowResponseModal(false);

  useEffect(async () => {
    Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
      Authhelper.GetAuthenticatedTravelerId().then(TRAVELER_ID => {
        Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
          api.all([
            api.get(`/api/room/getRoomDetailByEmail?email=${AUTHENTICATED_EMAIL}`),
            api.get(`/api/flight/getflightInformationByTravelerId?travelerId=${TRAVELER_ID}`),
            api.get(`/api/transfer/getTransferDetailByRoomId?roomId=${ROOM_ID}`),
          ]).then(api.spread((room, flight, transfer) => {

            if (typeof ROOM_ID === "undefined" || ROOM_ID === null) Authhelper.Logout('/login');
            else {
              setVacationType(room.data.roomInfo[0].vacationType);
              setFlightInfo(flight.data === "not available" ? false : true);
              if (transfer.data !== "not available") setTransferDetail(transfer.data);

              setLoggedInTravelerId(TRAVELER_ID);
              setLoader(false);
            }
          })).catch(e => { setLoader(false); })
        })
      })
    })
  }, []);

  const handelAddTransferClick = () => {
    setShowTransferModal(true);
  }

  const handelTransferModelResponseCallback = (e) => {
    setShowResponseModal(true);
    setShowTransferModal(false);
    if (e) {
      setResponseTitle("Success");
      setResponseBody("Selected transfer submitted successfully.");
    } else {
      setResponseTitle("Error !!");
      setResponseBody("Error occured while submitting the selected transfer. Please contact to the administrator.");
    }
  }

  const renderView = () => {
    if (vacationtype !== "hotelandtransfers") {
      return <Container fluid={true}>
        <Row className="justify-content-center">
          <strong>Transfer Information Not Available</strong>
        </Row>
      </Container>
    } else if (!flightInfo) {
      return <GuestFlight />
    } else if (!transferDetail) {
      return <Container fluid={true}>
        <Row className="justify-content-center">
          <Col lg="4">
            <div className="image">
              <img src={FlightImage} alt="Flight" />
            </div>
            <div className="text">
              <div className="text-title">No transfer information</div>
              <div className="text-description">
                You have not added the transfer information yet. Let’s do it now!
             </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="6">
            <a className="c-btn btn-block mt-2" onClick={() => handelAddTransferClick()}>Add Transfer Information</a>
          </Col>
        </Row>
      </Container>
    } else {
      return <div>
        <h5 className="header">Transfer Information </h5>
        <div className="company-logo">
          <img src={AMSTARLOGO} alt="AMSTAR" />
        </div>
        <div className="c-border-box border-box-vertical border-box-no-border">
          <div className="box-item">
            <div className="text-label">COMPANY NAME</div>
            <div className="text-title">{transferDetail?.supplierName}</div>
          </div>
          <div className="box-item">
            <div className="text-label">TO RESORT</div>
            <div className="text-title">
              {transferDetail?.toResortDateTime?.split('T')[0]}
              <br></br>
              {transferDetail?.toResortDescription}</div>
          </div>
          <div className="box-item">
            <div className="text-label">TO AIRPORT</div>
            <div className="text-title">
              {transferDetail?.toAirportDateTime?.split('T')[0]}
              <br></br>
              {transferDetail?.toAirportDescription}</div>
          </div>

          <div className="box-item">
            <div className="text-label">INSTRUCTIONS</div>
            <div className="text-title">{transferDetail?.instruction}</div>
          </div>
          <a className="c-btn btn-block mt-2" onClick={() => handelAddTransferClick()}>Update Transfer Information</a>
        </div>
      </div>
    }
  }

  return (
    <div className="page-transfer">
      {loader && <Loading />}
      {!loader && renderView()}

      <Modal show={showTransferModal} onHide={handleTransferModalClose} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Transfer Availability</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransferForm transferDetail={transferDetail} travelerId={loggedInTravelerId} transferModelResponseCallback={handelTransferModelResponseCallback} />
        </Modal.Body>
      </Modal>

      <Modal show={showResponseModal} onHide={handleResponseModalClose} backdrop="static" size="sm">
        <Modal.Header closeButton>
          <Modal.Title>{responseTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{responseBody}</p>
          <button className="c-btn c-btn--black c-btn--lg" type="button" onClick={handleResponseModalClose}>Ok</button>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default GuestTransfer;
