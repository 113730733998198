import axios from "axios";
import Authhelper from "./core/authHelper";
import PublicRoute from "./core/publicRoute";
import PrivateRoute from "./core/privateRoute";
import ErrorScreen from "./pages/Error/index";
import DataDeletion from "./pages/General/DataDeletion";
import PrivacyPolicy from "./pages/General/PrivacyPolicy";
import UserNotfound from "./pages/General/UserNotFound";
import GuestViewPage from "./pages/Guests/Dashboard/GuestView";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Landing Page
import Landing from "./pages/General/Landing";

//forget & reset password
import RouteNotfound from "./pages/General/RouteNotFound";
import ForgotPasswordScreen from "./pages/AuthScreen/ForgotPassword";
import ChangePasswordScreen from "./pages/AuthScreen/ChangePassword/index";
import ForgetPasswordPreConfirmationScreen from "./pages/AuthScreen/ForgotPassword/Confirmation/preConfirmation";
import ForgetPasswordPostConfirmationScreen from "./pages/AuthScreen/ForgotPassword/Confirmation/postConfirmation";

// login & registration
import LoginScreen from "./pages/AuthScreen/Login";
import SignupScreen from "./pages/AuthScreen/Signup";
import LoginFormScreen from "./pages/AuthScreen/Login/form";
import ContactSupport from "./pages/General/ContactSupport/index";
import SignupProfileFormScreen from "./pages/AuthScreen/Signup/form";
import TransferForm from "./pages/Guests/Dashboard/GuestView/transfer/form";
import PreConfirmationScreen from "./pages/AuthScreen/Confirmation/preConfirmationIndex";
import PostConfirmationScreen from "./pages/AuthScreen/Confirmation/postConfirmationIndex";

//Guest
import AvailableRooms from "./pages/Guests/Dashboard/GuestView/reservation/selectRooms/availableRooms";

//GroupLeader
import GroupLeader from "./pages/Guests/Dashboard/GroupLeaderView";
import SelectReservationDates from "./pages/Guests/Dashboard/GuestView/reservation/selectDates";
import SelectReservationRooms from "./pages/Guests/Dashboard/GuestView/reservation/selectRooms";
import SelectedReservationRooms from "./pages/Guests/Dashboard/GuestView/reservation/selectRooms/selectedRoom";
import SelectReservationRoomArrangements from "./pages/Guests/Dashboard/GuestView/reservation/roomArrangements";
import SelectReservationRoomConfirmation from "./pages/Guests/Dashboard/GuestView/reservation/confirmation";
import RoomRequestConfirmation from "./pages/Guests/Dashboard/GuestView/reservation/confirmation/room-request";
import ReservationBookingCompleted from "./pages/Guests/Dashboard/GuestView/reservation/confirmation/complete";

//admin
import AdminPage from "./pages/Guests/Dashboard/AdminView";

function App() {
  //request interceptor
  axios.interceptors.request.use(
    function (config) {
      Authhelper.InitializeAppSettings();

      let jwt = Authhelper.GetAuthorizedJwt();
      config.headers = { Authorization: `Bearer ${jwt}` };
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  //response interceptor
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      let status = error.response.status;
      //if token expired
      if (status === 401) Authhelper.Logout("/login");
      return Promise.reject(error);
    }
  );

  return (
    <Router>
      <div className="App">
        <div>
          <Switch>
            <Route path="/error" exact component={ErrorScreen} />
            <PublicRoute path="/" exact component={Landing} />
            <PublicRoute path="/login" exact component={LoginScreen} />
            <Route path="/data-deletion" exact component={DataDeletion} />
            <Route path="/privacy-policy" exact component={PrivacyPolicy} />
            <Route path="/user-notfound" exact component={UserNotfound} />
            <Route path="/contact-support" exact component={ContactSupport} />

            <PublicRoute path="/register" exact component={SignupScreen} />
            <PublicRoute path="/login/form" exact component={LoginFormScreen} />
            <PublicRoute
              path="/pre-confirmation"
              exact
              component={PreConfirmationScreen}
            />
            <PublicRoute
              path="/post-confirmation"
              exact
              component={PostConfirmationScreen}
            />
            <PublicRoute
              path="/register/profile"
              exact
              component={SignupProfileFormScreen}
            />

            <PublicRoute
              path="/forgot-password"
              exact
              component={ForgotPasswordScreen}
            />
            <PublicRoute
              path="/change-password"
              exact
              component={ChangePasswordScreen}
            />
            <PublicRoute
              path="/forgot-password/pre-confirmation"
              exact
              component={ForgetPasswordPreConfirmationScreen}
            />
            <PublicRoute
              path="/forgot-password/post-confirmation"
              exact
              component={ForgetPasswordPostConfirmationScreen}
            />

            <Route
              path="/guest/transfer/add/:travelerId"
              render={(props) => <TransferForm {...props} />}
            />
            <Route
              path="/guest/transfer/update/:travelerId"
              render={(props) => <TransferForm {...props} />}
            />

            {/* //should be public access */}
            <Route path="/guest/room/booking" component={AvailableRooms} />

            {/* //Guest Routes */}
            {/*<PrivateRoute*/}
            {/*  path="/guest/wedding"*/}
            {/*  render={(props) => <GuestViewPage {...props} type="wedding" />}*/}
            {/*/>*/}
            <PrivateRoute
              path="/guest/groupleader-wedding"
              render={(props) => (
                <GuestViewPage {...props} type="groupleader-wedding" />
              )}
            />
            <PrivateRoute
              path="/guest/summary"
              render={(props) => <GuestViewPage {...props} type="summary" />}
            />
            <PrivateRoute
              path="/guest/guests"
              render={(props) => <GuestViewPage {...props} type="guests" />}
            />
            <PrivateRoute
              path="/guest/resort"
              render={(props) => <GuestViewPage {...props} type="resort" />}
            />
            <PrivateRoute
              path="/guest/room"
              render={(props) => <GuestViewPage {...props} type="room" />}
            />

            <PrivateRoute
              path="/guest/flight"
              render={(props) => <GuestViewPage {...props} type="flight" />}
            />
            <PrivateRoute
              path="/guest/transfer"
              render={(props) => <GuestViewPage {...props} type="transfer" />}
            />

            {/* Reservation */}
            <Route
              exact
              path="/guest/reservation/select-date/:groupId"
              render={(props) => <SelectReservationDates {...props} />}
            />

            <Route
              exact
              path="/guest/reservation/select-date"
              render={(props) => <SelectReservationDates {...props} />}
            />
            <Route
              exact
              path="/guest/reservation/rooms"
              render={(props) => <SelectReservationRooms {...props} />}
            />

            <Route
              path="/guest/reservation/rooms/:id"
              render={(props) => <SelectedReservationRooms {...props} />}
            />

            <Route
              path="/guest/reservation/room-arrangements"
              render={(props) => (
                <SelectReservationRoomArrangements {...props} />
              )}
            />

            <Route
              path="/guest/reservation/request-room"
              render={(props) => (
                <SelectReservationRoomArrangements {...props} />
              )}
            />

            <Route
              path="/guest/reservation/confirmation/room-request"
              render={(props) => <RoomRequestConfirmation {...props} />}
            />
            <Route
              path="/guest/reservation/confirmation"
              render={(props) => (
                <SelectReservationRoomConfirmation {...props} />
              )}
            />

            <Route
              path="/guest/reservation/completed"
              render={(props) => <ReservationBookingCompleted {...props} />}
            />

            {/* //Groupleader Routes */}
            <PrivateRoute
              path="/groupleader/wedding"
              render={(props) => <GroupLeader {...props} type="wedding" />}
            />

            <PrivateRoute
              path="/groupleader/rsvps"
              render={(props) => <GroupLeader {...props} type="rsvps" />}
            />

            <PrivateRoute
              path="/groupleader/guestlist"
              render={(props) => <GroupLeader {...props} type="guestlist" />}
            />

            {/* //Admin Routes */}
            <PrivateRoute
              path="/admin/users"
              render={(props) => <AdminPage {...props} type="users" />}
            />

            {/* NotFound Route */}
            <Route path="*" component={RouteNotfound} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
