import React from 'react';
import Navigation from './navigation';
const SubMainScreen = (props) => {
    return (
        <div className={`page-sub-main-screen ${props.type}`}>
            <Navigation/>
            <div className="sub-screen-content">
                {props.children}
            </div>
        </div>
    )
}

export default SubMainScreen;