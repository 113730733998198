import React, { useMemo } from "react";

import CoreHelper from "core/coreHelper";
import TransferImage from "assets/images/transfer.svg";

const TransferOption = (props) => {
  const isChecked = () => {
    const { value, selectedTransferOption } = props;
    if (!selectedTransferOption) return false;
    if (value === null) {
      return selectedTransferOption.transferTypeName === "none";
    }
    return (
      selectedTransferOption.transferTypeName === props.name &&
      selectedTransferOption?.travelZapTransferPrice === value
    );
  };

  return (
    <div>
      <input
        id={props.name}
        type="radio"
        onChange={props.handleChange}
        value={props.value || ""}
        checked={isChecked()}
      />
      <label className="w-100" htmlFor={props.name}>
        {props.value !== null ? (
          <span>+{CoreHelper.GetUsFormattedAmount(props.value)}</span>
        ) : null}
        <span className="ml-2">{props.label}</span>
      </label>
    </div>
  );
};

const MobileView = (props) => {
  const options = useMemo(() => {
    return [
      {
        name: "none",
        value: null,
        label: "Not at this time",
      },
      ...props.transferTypes.filter((t) => t.name !== "none"),
    ];
  }, [props.transferTypes]);
  return (
    <div className="c-payment-options">
      <div className="d-flex align-items-center payment-header">
        <img src={TransferImage} alt="Insurance Icon" />
        <h4>Add transportation to and from your hotel</h4>
      </div>
      <div className="py-4">
        {options.map((item) => {
          return (
            <React.Fragment key={item.name}>
              <div className="payment-option">
                <TransferOption
                  {...item}
                  handleChange={props.toggleRecheck}
                  selectedTransferOption={props.selectedTransferOption}
                />
                {item.name !== "none" &&
                  props.selectedTransferOption?.transferTypeName ===
                    item.name && (
                    <div
                      className=""
                      style={{
                        padding: "20px",
                      }}
                    >
                      <div className="payment-option">
                        {item.label}: {item.description}
                      </div>
                    </div>
                  )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default MobileView;
