import React from 'react';
import { Link } from 'react-router-dom';
import ArrowLeft from '../../assets/images/icon-chevron-left.svg';

const BackButton = ({ target, onClick }) => {
    return (
        <Link to={typeof target !== 'undefined' ? target : ''} className="c-back" onClick={onClick}>
            <img src={ArrowLeft} alt="Left" /> Back
        </Link>);
}
export default BackButton