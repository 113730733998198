import api from "utils/api";
import { Row, Col } from "reactstrap";
import React, { useEffect, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icon-pen.svg";
import ArrowRightIcon from "../../../../../assets/images/icon-chevron-right.svg";
import ArrowLeftIcon from "../../../../../assets/images/icon-chevron-left.svg";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

const GuestResort = () => {
  const [loader, setLoader] = useState(true);
  const [resortDetail, setResortDetail] = useState({});

  useEffect(async () => {
    Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
      if (typeof ROOM_ID !== "undefined" && ROOM_ID !== null) {
        _getResortDetailByRoomId(ROOM_ID);
      } else _getResortDetailByGroupId();
    });
  }, []);

  function _getResortDetailByRoomId(ROOM_ID) {
    api.get('/api/resort/getResortDetailByRoomId?roomId=' + ROOM_ID).then(response => {
      setResortDetail(response.data);
      setLoader(false);
    }).catch(error => { setLoader(false); });
  }

  function _getResortDetailByGroupId() {
    Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
      api.get(`/api/resort/getResortDetailByGroupId?email=${AUTHENTICATED_EMAIL}`).then(response => {
        setResortDetail(response.data);
        setLoader(false);
      }).catch(error => { Authhelper.Logout("/login"); })
    })
  }

  return (
    <div className="page-resort">
      {loader && <Loading />}
      {!loader && <>
        <h5 className="header">Resort Information</h5>
        <Row>
          <Col xl="6">

            {resortDetail && resortDetail.images &&
              <div className="c-photo-block">
                <div className="photo">
                  <CarouselProvider
                    naturalSlideWidth={100}
                    naturalSlideHeight={125}
                    totalSlides={resortDetail.images.length}
                    isIntrinsicHeight={true}
                    isPlaying={true}
                    interval={3000}
                  >
                    <Slider>
                      {resortDetail.images.map((item, i) => {
                        return <Slide index={i} key={i}> <div className="slide-item-wrapper"><img src={item} alt="Resort Image" /></div></Slide>
                      })}


                    </Slider>
                    <ButtonBack className="carouselButton">
                      <img src={ArrowLeftIcon} alt="Back" />
                    </ButtonBack>
                    <ButtonNext className="carouselButton">
                      <img src={ArrowRightIcon} alt="Next" />
                    </ButtonNext>
                  </CarouselProvider>

                </div>
                <div className="icon">
                  <EditIcon />
                </div>
              </div>
            }

            <h5 className="header header-lg">{resortDetail.name}</h5>
            <div className="c-border-box border-box-no-border">
              <div className="box-item">
                <div className="text-label">LOCATION</div>
                <div className="text-title">
                  {resortDetail.address}
                </div>
              </div>
            </div>

          </Col>
          <Col xl="6">
            <div className="c-border-box border-box-vertical">
              <div className="box-item">
                <div className="text-label">ABOUT THE RESORT</div>
                <div className="text-title">
                  {resortDetail.description}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <a href={resortDetail.website} target="_blank" className="c-btn btn-block mt-2">Visit Website</a>
      </>}
    </div>
  );
};
export default GuestResort;
