import React from 'react';
import SubMainScreen from '../../../layouts/SubMainScreen';
import EnvelopeIcon from '../../../assets/images/icon-envelope-success.svg';
const PostConfirmationScreen = () => {
    const queryParams = new URLSearchParams(window.location.search);
    return (
        <SubMainScreen>
            <div className="c-auth-screen">
                <div className="auth-content">

                    <div className="text-center mb-5">
                        <img src={EnvelopeIcon} alt="Success" />
                    </div>
                    <div className="auth-title text-center">Success</div>
                    <div className="description text-center ">
                       User Registered Successfully.
                    </div>
                    <div className="text-note text-center">
                        <a href={queryParams.get('returnurl') != null ? "/login/form?returnurl=" + queryParams.get('returnurl') : "/login/form"  }><strong className="text-primary text-primary--hover">Login</strong></a> to enter into the portal.
                    </div>
                </div>
            </div>
        </SubMainScreen>
    )
}

export default PostConfirmationScreen;