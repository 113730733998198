import React from "react";

import PaymentImage from "assets/images/icon-payment.svg";
import CoreHelper from "core/coreHelper";

const PaymentOptions = ({
  selectedPaymentOption,
  onPaymentOptionSelect,
  paymentTypes,
}) => {
  return (
    <>
      <div className="d-flex align-items-center payment-header">
        <img src={PaymentImage} alt="Payment Icon" />
        <h4>Payment Options</h4>
      </div>
      <div style={{ minHeight: "24px" }}>
        {!selectedPaymentOption?.amount && (
          <p className="error text-danger m-0">
            Please Select a Payment Option
          </p>
        )}
      </div>
      {paymentTypes &&
        paymentTypes
          .filter((x) => x.show)
          .map((item, i) => {
            return (
              <div className="payment-option" key={i}>
                <input
                  checked={selectedPaymentOption.amount === item.amount}
                  type="radio"
                  name="payment-opt"
                  id={`opt-${i}`}
                  value={`${item.amount}`}
                  onChange={() => onPaymentOptionSelect(item)}
                />
                <label
                  className="d-flex w-100 align-items-center justify-content-between"
                  htmlFor={`opt-${i}`}
                >
                  <span>{item.name}</span>
                  <span className="payment-price">
                    {CoreHelper.GetUsFormattedAmount(item.amount)}
                  </span>
                </label>
              </div>
            );
          })}
    </>
  );
};

export default PaymentOptions;
