import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChangetravelDate from "./changeTravelDate";
import AddGuest from "./addGuest";
import Modal from 'react-bootstrap/Modal';
import UpgradeMyRoom from './upgradeMyRoom';
import BeddingRequest from './beddingRequest';
import CustomRequest from './customRequest';
import RoomreservationHelp from './help';


const ModifyReservation = (props) => {
    const [error, setError] = useState(null);
    const [showSubModal, setShowSubModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const handleOnSubModalClose = () => setShowSubModal(false);
    const [selectedRequest, setSelectedRequest] = useState(null);

    const _options = ["Change travel dates", "Add a guest", "Upgrade my room", "Bedding request", "Custom request"];

    const onRequestSelection = (e) => {
        setSelectedRequest(e.value);
    }

    const onContinue = (e) => {
        if (selectedRequest === null) {
            setError("Please select request menu.");
        } else setShowSubModal(true);
    }

    const onSubModuleCallback = (e) => {
        setShowSubModal(e);
    }

    const onHelp = () => {
        setShowHelpModal(true);
    }

    const handleOnHelpModalClose = () => {
        setShowHelpModal(false);
    }


    return (
        <div className="modify-reservation">
            <h4 className="subtitle"> Please select one of the requests from the dropdown below, then click continue.</h4>
            <div>
                <Dropdown options={_options} placeholder="Select a request menu" onChange={onRequestSelection} />
                <div className="request-selection-error">{error}</div>
                <a className="c-btn btn-block mt-2 continue" onClick={onContinue}>Continue</a>
                <p className="help-text">For more information on the requests, click below</p>
                <a className="c-btn btn-block mt-2 help-btn" target="_blank" onClick={onHelp}>Need Help?</a>
            </div>

            <Modal show={showSubModal} onHide={handleOnSubModalClose} backdrop="static" size="md">
                <Modal.Header closeButton>
                    {selectedRequest === "Change travel dates" &&
                        <div className="modal-title">Change Travel Dates</div>
                    }
                    {selectedRequest === "Add a guest" &&
                        <div className="modal-title">Guest Information</div>
                    }
                    {selectedRequest === "Upgrade my room" &&
                        <div className="modal-title">Select Room Category</div>
                    }
                    {selectedRequest === "Bedding request" &&
                        <div className="modal-title">Bedding Request</div>
                    }
                    {selectedRequest === "Custom request" &&
                        <div className="modal-title">Custom Request</div>
                    }
                </Modal.Header>
                <Modal.Body>
                    {selectedRequest === "Change travel dates" &&
                        <ChangetravelDate callback={onSubModuleCallback} />
                    }
                    {selectedRequest === "Add a guest" &&
                        <AddGuest callback={onSubModuleCallback} />
                    }
                    {selectedRequest === "Upgrade my room" &&
                        <UpgradeMyRoom callback={onSubModuleCallback} />
                    }
                    {selectedRequest === "Bedding request" &&
                        <BeddingRequest callback={onSubModuleCallback} />
                    }
                    {selectedRequest === "Custom request" &&
                        <CustomRequest callback={onSubModuleCallback} />
                    }
                </Modal.Body>
            </Modal>

            <Modal show={showHelpModal} onHide={handleOnHelpModalClose} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <div className="modal-title">Room Modifications and Request Menu Guide</div>
                </Modal.Header>
                <Modal.Body>
                    <p>Change Travel Dates - Use this request to change your check in/check out dates, add or remove a night, or reschedule your trip.</p>
                    <p>Add A Guest - Use this request to add an extra person to your room.</p>
                    <p>Upgrade My Room - Use this request to change to a higher room category.</p>
                    <p>Cancel My Reservation - Use this request to cancel your trip.</p>
                    <p>Bedding Request - Use this request to change the type of bed (usually 1 king or 2 doubles.)</p>
                    <p>Custom Request - Use this request for all other needs. We'll do our best to accommodate.</p>
                    <p>All requests are subject to availability and may change the cost of your reservations. All changes must be confirmed by a member of our team before they will take place.</p>
                    <button className="c-btn btn-block mt-2 help-btn" onClick={handleOnHelpModalClose}>Return To The Request Menu</button>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default withRouter(ModifyReservation);