import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { ReservationHelper } from '../../../../../../../core/reservationHelper';

const RoomRequestConfirmation = (props) => {
    const componentRef = useRef();
    let adultTravelers = ReservationHelper.GetAdults();
    let childTravelers = ReservationHelper.GetChilds();
    let _numKids = ReservationHelper.GetChildCount();
    let _numAdults = ReservationHelper.GetAdultsCount();
    let _totalNight = ReservationHelper.GetTotalNight();
    let _roomName = ReservationHelper.GetSelectedRoom();
    let travelDate = ReservationHelper.GetTravelDate();
    const getMMDDYYYFormate = (date) => {
        return moment.utc(date).format('MM-DD-YYYY');
    }
    return (
        <div className="page-booking-completed">
        <div className="booking-left-screen">
            <div className="content text-center">
                <svg width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M38.0091 6.27259e-07C55.7032 0.00636437 71.0535 12.2245 75.0263 29.4678C78.9991 46.711 70.5423 64.4167 54.6363 72.1633C38.7282 79.9121 19.5769 75.6527 8.45186 61.8946C-2.67533 48.1343 -2.83017 28.5153 8.07431 14.5812C15.2796 5.37513 26.3199 -0.00212044 38.0091 6.27259e-07Z" fill="white" />
                        <path opacity="0.2" fillRule="evenodd" clipRule="evenodd" d="M13.1895 9.21777C11.33 10.8206 9.61452 12.6135 8.07431 14.5814C-2.83017 28.5156 -2.67533 48.1346 8.45186 61.8949C19.5769 75.653 38.7282 79.9123 54.6363 72.1636C59.3932 69.8469 63.4838 66.6395 66.7749 62.8201C64.3392 64.9185 61.6054 66.7328 58.6068 68.1932C42.6987 75.942 23.5474 71.6826 12.4224 57.9245C1.29516 44.1642 1.14032 24.5452 12.0448 10.6111C12.4163 10.1364 12.798 9.67188 13.1895 9.21777Z" fill="#136070" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M31.5703 51.6505C30.4567 51.6505 29.3983 51.1647 28.6771 50.3162L18.2138 38.0599C17.306 37.0311 17.0218 35.593 17.4736 34.2948C17.9254 32.9987 19.041 32.0463 20.3922 31.8045C21.7433 31.5606 23.1199 32.0654 23.9938 33.1239L32.0475 42.5569L53.5447 25.0718C54.5925 24.1809 56.0412 23.9285 57.3308 24.4079C58.6204 24.8894 59.5495 26.0306 59.7595 27.3903C59.9695 28.7521 59.4265 30.1181 58.3426 30.9666L33.9671 50.7977C33.2905 51.3493 32.4442 51.6505 31.5703 51.6505Z" fill="#00BFC8" />
                </svg>
                <div>
                        <h4 className="text-md text-600 mb-4">Room Request Submitted.</h4>
                        <div className="confirmation-number">
                           
                        <p>Check your email for our confirmation and reply. Please allow up to 48 business hours
                            for response.</p>
                       
                            <p>If you have not heard back within 48 business hours, please reply to the confirmation email or email
                            guestservices@destify.com
                             
                            </p>
                           
                        </div>
                </div>
            </div>
        </div>
            <div className="booking-right-screen">
                <div className="content">
                    <div ref={componentRef}>
                        <h2 className="section-header text-600 mb-4">Your Rooming Request</h2>
                        <div className="c-border-box border-box-vertical border-box-filled">
                            <div className="box-item">
                                <div className="align-items-center justify-content-between">
                                    <div className="text-label text-400 text-md text-black mb-0">Desired Room:</div>
                                    <div className="text-title">
                                        {_roomName ?? "N/A"}
                                    </div>
                                </div>
                                <br />
                                <div className="align-items-center justify-content-between">
                                    <div className="text-label text-400 text-md text-black mb-0">Requested Travelers</div>
                                    
                                    <div className="text-title">
                                        {adultTravelers?.length > 0 &&
                                            <div className="adults-info">
                                                <strong>Adults:</strong><br />
                                            <div className="adults text-400">
                                                    {
                                                        adultTravelers.map((adult, i) => {
                                                            return (<span key={i} className="full-name" style={{ 'display':'block'}}>
                                                               {`${adult.adultFirstName} ${adult.adultMiddleName ?? "" } ${adult.adultLastName} `}
                                                            </span>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }

                                        {childTravelers?.length > 0 &&
                                            <div className="children-info">
                                                <strong>Children:</strong><br />
                                            <div className="children text-400">
                                                    {
                                                        childTravelers.map((child, i) => {
                                                            return (<span key={i} className="full-name" style={{ 'display': 'block' }}>
                                                                {`${child.childFirstName} ${child.childMiddleName ?? "" } ${child.childLastName} `}
                                                            </span>);
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div><br />
                                <div className="text-gray-400">
                                    • {_numAdults > 1 ? _numAdults + ' adults ' : _numAdults + ' adult '}
                                      { _numKids > 0 ? (_numKids > 1 ? _numKids + ' childs ' : _numKids + ' child ') :''}
                                    • {_totalNight > 1 ? _totalNight + ' nights ' : _totalNight + ' night '}
                                </div><br />
                                <div className="align-items-center justify-content-between">
                                    <div className="text-label text-400 text-md text-black mb-0">Travel Date:</div>
                                    <div className="text-title">
                                        { getMMDDYYYFormate(travelDate)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>
    </div>
    )
}
export default RoomRequestConfirmation