import _ from 'lodash';
import api from "utils/api";
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Loading from "../../../../../components/Loading";
import Authhelper from "../../../../../core/authHelper";
import CoreHelper from "../../../../../core/coreHelper";

const TransferForm = (props) => {
    let loggedInTravelerId = props.travelerId;
    const [loader, setLoader] = useState(true);
    const [roomId, setRoomId] = useState(null);
    const [supplierCode, setSupplierCode] = useState(null);
    const [availabilities, setAvailabilities] = useState([]);
    const [selectedInbound, setSelectedInbound] = useState({});
    const [selectedOutbound, setSelectedOutbound] = useState({});
    const [arrivalFlightDetail, setArrivalFlightDetail] = useState({});
    const [departureFlightDetail, setDepartureFlightDetail] = useState({});

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => {
                setRoomId(ROOM_ID);
                api.all([
                    api.get(`/api/flight/getflightInformationByTravelerId?travelerId=${loggedInTravelerId}`),
                    api.get(`/api/room/getRoomDetailByEmail?email=${AUTHENTICATED_EMAIL}`),
                    api.get(`/api/resort/getResortDetailByGroupId?groupId=${''}&email=${AUTHENTICATED_EMAIL}`),
                ]).then(api.spread((flight, room, resort) => {
                    let _returnFlight = flight.data.departureFlight[0]
                    let _arrivalFlight = flight.data.arrivalFlight[flight.data.arrivalFlight.length - 1];

                    setArrivalFlightDetail(_arrivalFlight);
                    setDepartureFlightDetail(_returnFlight);

                    //get available transfers
                    let _outboundDate = _returnFlight.arrivalDateTime.split('T')[0];
                    let _inboundDate = _arrivalFlight.departureDateTime.split('T')[0];
                    let _supplierCode = resort.data.supplierCode === "bedsonline" ? "BOL" : resort.data.supplierCode
                    setSupplierCode(_supplierCode);

                    let _checkInDate = getCheckInDate(room.data);
                    let _adultCount = getTotalNumberOfAdults(room.data);

                    getAvailableTransfers({
                        NumAdults: _adultCount,
                        TravelDate: _checkInDate,
                        supplierCode: _supplierCode,
                        HotelCode: resort.data.bolHotelCode,
                        InboundTime: new Date(_inboundDate),
                        OutboundTime: new Date(_outboundDate),
                        Destination: resort.data.airportCode,
                        GroupCommissionPercentage: resort.data.groupCommissionMultiplier,
                        HotelChainCommissonPercentage: resort.data.resortCommissionMultiplier,
                    });
                })).catch(e => { setLoader(false); })
            }).catch(e => { setLoader(false); });
        }).catch(e => { setLoader(false); });
    }, [])


    const getAvailableTransfers = (requestData) => {
        api.post(`/api/transfer/getAvailability`, requestData).then(availability => {
            //availability
            let _availabilities = availability.data.transfers?.map((item, i) => {
                item.id = i + 1;
                item.checked = false;
                return item;
            })

            setAvailabilities(_availabilities);
            setLoader(false);
        }).catch(error => { setLoader(false); })
    }

    const getCheckInDate = (rooms) => {
        let checkInDateTimes = [];
        rooms.roomInfo.map((room) => {
            let checkInTime = CoreHelper.ConvertTimeFrom12To24Format(room.checkInTime);
            let checkInDateTime = room.checkInDate.split('T')[0] + 'T' + checkInTime;
            checkInDateTimes.push(checkInDateTime);
        })
        var dates = _.map(checkInDateTimes, function (date) { return moment(date) });
        return _.min(dates);
    }

    const getTotalNumberOfAdults = (rooms) => {
        let _adultCount = 0;
        rooms.roomInfo.map((room) => {
            let adults = room.guestInfo.filter(item => !item.isChild).length;
            _adultCount += adults;
        })
        return _adultCount;
    }

    const onOutboundSelect = (data, e) => {
        validateCheckBox(data, e.target.checked, 'DEPARTURE');
        if (e.target.checked) setSelectedOutbound(data); else setSelectedOutbound({});
    }

    const onInboundSelect = (data, e) => {
        validateCheckBox(data, e.target.checked, 'ARRIVAL');
        if (e.target.checked) setSelectedInbound(data); else setSelectedInbound({});
    }

    const validateCheckBox = (data, checked, flag) => {
        availabilities.map(item => {
            if (item.rateKey.includes(flag) === data.rateKey.includes(flag)) {
                item.checked = item.id === data.id ? checked : false;
            }
            return item;
        })

    }

    const renderOutbounds = () => {
        return availabilities?.map((item, i) => {
            if (item.rateKey.includes('DEPARTURE')) {
                return (
                    <tr key={i}>
                        <td>
                            <div className="c-checkbox">
                                <input checked={item.checked} type="checkbox" name="outbound" id={`outbound_${i}`} onChange={(e) => onOutboundSelect(item, e)} />
                                <label htmlFor={`outbound_${i}`}></label>
                            </div>
                        </td>
                        <td>
                            <img src={item.imageLink} alt={item.name} />
                        </td>
                        <td>
                            <h5>{item.name}</h5>
                            <p>
                                <span>Capacity:{item.capacity}</span>
                            </p>
                        </td>
                        <td>
                            <h5>Pickup Time</h5>
                            <p>
                                <span>{item.pickupDate}</span>
                                <br></br>
                                <span>{item.pickupTime}</span>
                            </p>
                        </td>
                        <td>
                            <h5>Rate</h5>
                            <p>{item.currencyId + ' ' + item.rate.toFixed(2)}</p>
                        </td>
                    </tr>
                )
            }
        })
    }

    const renderInbounds = () => {
        return availabilities?.map((item, i) => {
            if (item.rateKey.includes('ARRIVAL')) {
                return (
                    <tr key={i}>
                        <td>
                            <div className="c-checkbox">
                                <input checked={item.checked} type="checkbox" name="inbound" id={`inbound_${i}`} onChange={(e) => onInboundSelect(item, e)} />
                                <label htmlFor={`inbound_${i}`}></label>
                            </div>
                        </td>
                        <td>
                            <img src={item.imageLink} alt={item.name} />
                        </td>
                        <td>
                            <h5>{item.name}</h5>
                            <p>
                                <span>Capacity:{item.capacity}</span>
                            </p>
                        </td>
                        <td>
                            <h5>Pickup Time</h5>
                            <p>
                                <span>{item.pickupDate}</span>
                                <br></br>
                                <span>{item.pickupTime}</span>
                            </p>
                        </td>
                        <td>
                            <h5>Rate</h5>
                            <p>{item.currencyId + ' ' + item.rate.toFixed(2)}</p>
                        </td>
                    </tr>
                )
            }
        })
    }

    const handleTransferSubmit = (e) => {
        Authhelper.GetAuthenticatedEmailAddress().then(_email => {
            let _inbound = selectedInbound;
            let _outbound = selectedOutbound;
            let _firstName = Authhelper.GetAuthenticatedFirstName();
            let _lastName = Authhelper.GetAuthenticatedLastName();
            let _phoneNo = Authhelper.GetAuthenticatedPhoneNumber();
            let _fullName = Authhelper.GetAuthenticatedFullName();

            if (props.transferDetail) {
                let _transfers = [];
                //inbound
                if (Object.keys(_inbound).length > 0) {
                    _transfers.push({
                        rateKey: _inbound.rateKey,
                        transferDetails: [{
                            code: "",
                            type: "",
                            direction: "",
                            companyName: ""
                        }]
                    })
                }

                //outbound
                if (Object.keys(_outbound).length > 0) {
                    _transfers.push({
                        rateKey: _outbound.rateKey,
                        transferDetails: [{
                            code: "",
                            type: "",
                            direction: "",
                            companyName: ""
                        }]
                    })
                }

                updateTransfer({
                    language: "en",
                    remark: "",
                    clientReference: "TravelZap",
                    supplierCode: supplierCode,
                    holder: {
                        title: "",
                        name: _fullName,
                        email: _email,
                        phone: _phoneNo
                    },
                    transfers: _transfers
                });
            } else addTransfer({
                email: _email,
                phone: _phoneNo,
                lastName: _lastName,
                firstName: _firstName,
                supplierCode: supplierCode,
                rateKey: [_inbound.rateKey, _outbound.rateKey]
            });
        })

    }

    const addTransfer = (requestData) => {
        setLoader(true);
        api.post('/api/transfer/bookTransfer', requestData).then((res) => {
            if (res.data.success) {
                updateCRMWithBookedTransfer();
            } else {
                setLoader(false);
                props.transferModelResponseCallback(false);
            }
        }).catch((error) => {
            setLoader(false);
            props.transferModelResponseCallback(false);
        });
    }

    const updateTransfer = (requestData) => {
        setLoader(true);
        api.post('/api/transfer/updateTransfer', requestData).then((res) => {
            if (res.data.success) {
                updateCRMWithBookedTransfer();
            } else {
                setLoader(false);
                props.transferModelResponseCallback(false);
            }
        }).catch((error) => {
            setLoader(false);
            props.transferModelResponseCallback(false);
        });
    }

    const updateCRMWithBookedTransfer = () => {
        let _inboundRate = typeof selectedInbound.rate !== 'undefined' ? selectedInbound.rate : 0;
        let _outboundRate = typeof selectedOutbound.rate !== 'undefined' ? selectedOutbound.rate : 0;
        let _totalAmount = _inboundRate + _outboundRate;

        api.post('/api/transfer/updateCRMWithBookedTransfer', {
            RoomId: roomId,
            OutboundConfirmationNumber: arrivalFlightDetail.airlineConfirmationNo,
            InboundConfirmationNumber: departureFlightDetail.airlineConfirmationNo,
            TotalAmount: _totalAmount
        }).then((res) => {
            setLoader(false);

            if (res.data) {
                props.transferModelResponseCallback(true);
            } else props.transferModelResponseCallback(false);

        }).catch((error) => {
            setLoader(false);
            props.transferModelResponseCallback(false);
        });
    }

    return (
        <div>
            {loader && <Loading />}
            {!loader && <>
                { availabilities !== null ?
                    <div className="w-100">
                        <Row>
                            <Col lg="12" xl="12">
                                <form className="c-form" onSubmit={handleTransferSubmit}>
                                    <div className="mb-5">
                                        <h5 className="auth-sub-title mb-3">Inbound</h5>
                                        <div className="c-table-responsive">
                                            <table className="c-table availability-table">
                                                <tbody>{renderInbounds()}</tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="mb-5">
                                        <h5 className="auth-sub-title mb-3">Outbound</h5>
                                        <div className="c-table-responsive">
                                            <table className="c-table availability-table">
                                                <tbody>{renderOutbounds()}</tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {Object.keys(selectedInbound).length === 0 && Object.keys(selectedOutbound).length === 0 ?
                                            <button type="submit" className="c-btn c-btn-disabled c-btn--ghost c-btn--ghost__primary">Submit</button> :
                                            <button type="submit" className="c-btn c-btn--ghost c-btn--ghost__primary">Submit</button>
                                        }
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </div>
                    : <div className="d-flex justify-content-center">
                        <strong>Transfers not found. Please contact to the administrator.</strong>
                    </div>
                }
            </>}
        </div>
    );
}
export default withRouter(TransferForm);