import React, { useEffect } from "react";
import { Col } from "reactstrap";
import Select from "react-select";

import ReactInput from "components/InputField";
import PhoneField from "components/PhoneField";
import ReactInputDateField from "components/InputDateField";

import { DropDownStyleConstant } from "core/dropdownStyleConstant";
import {
  filterParens,
  filterWhiteSpace,
  debounce,
  getAdultAgeRange,
} from "./util";
import { ReservationHelper } from "core/reservationHelper";

const genders = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const { maxDate, minDate } = getAdultAgeRange();

const AdultForm = ({
  adults,
  setAdults,
  setAdultErrors,
  adultErrors,
  manualRoomingRequest = false,
}) => {
  const resetError = (index, key) => {
    setAdultErrors((errors) => {
      return errors.map((error) => {
        if (error.index === index) {
          return {
            ...error,
            [key]: "",
          };
        }
        return error;
      });
    });
  };

  const updateAdult = (index, key, value) => {
    setAdults((prev) => {
      return prev.map((adult, id) => {
        if (id === index) {
          return {
            ...adult,
            [key]: value,
          };
        }
        return adult;
      });
    });
  };

  useEffect(() => {
    const handler = debounce(() => {
      ReservationHelper.SetAdults(adults);
    });
    return handler();
  }, [adults]);

  const handleFirstnameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    resetError(index, key);
    updateAdult(index, key, filterParens(value));
  };

  const handleMiddlenameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    updateAdult(index, key, filterParens(value));
  };

  const handleLastnameChange = (e, index) => {
    const key = e.target.name;
    const value = filterWhiteSpace(e.target.value);
    resetError(index, key);
    updateAdult(index, key, filterParens(value));
  };

  const handleEmailChange = (e, index) => {
    const key = e.target.name;
    const value = e.target.value;
    resetError(index, key);
    updateAdult(index, key, value);
  };

  const handlePhoneChange = (e, index) => {
    const key = "adultPhone";
    resetError(index, key);
    updateAdult(index, key, e);
  };

  const handleDOBChange = (e, index) => {
    const key = "adultDob";
    resetError(index, key);

    if (e.toString() !== "Invalid Date") {
      const value = e.toISOString().split("T")[0];
      updateAdult(index, key, value);
    }
  };
  const handleGenderChange = (e, index) => {
    const key = "adultGender";
    const value = e.value;
    updateAdult(index, key, value);
  };
  return (
    <>
      {adults
        ? adults.map((adult, i) => {
            return (
              <Col lg={adults.length > 3 ? 4 : 6} key={i}>
                <form className="c-form">
                  <h3>
                    {i === 0 && manualRoomingRequest
                      ? `Your Information`
                      : `Adult Traveler #${i + 1}`}
                  </h3>
                  <ReactInput
                    value={adult.adultFirstName}
                    required
                    name="adultFirstName"
                    label="First Name"
                    error={adultErrors[i]?.adultFirstName}
                    onChange={(e) => handleFirstnameChange(e, i)}
                  />
                  <ReactInput
                    value={adult.adultMiddleName}
                    required
                    name="adultMiddleName"
                    label="Middle Name"
                    onChange={(e) => handleMiddlenameChange(e, i)}
                  />
                  <ReactInput
                    value={adult.adultLastName}
                    required
                    name="adultLastName"
                    label="Last Name"
                    error={adultErrors[i]?.adultLastName}
                    onChange={(e) => handleLastnameChange(e, i)}
                  />
                  <ReactInput
                    value={adult.adultEmail}
                    required
                    name="adultEmail"
                    label="Email"
                    error={adultErrors[i]?.adultEmail}
                    onChange={(e) => handleEmailChange(e, i)}
                  />
                  <PhoneField
                    value={adult.adultPhone}
                    international
                    name="adultPhone"
                    label="Phone Number"
                    error={adultErrors[i]?.adultPhone}
                    onChange={(e) => handlePhoneChange(e, i)}
                  />
                  <ReactInputDateField
                    value={adult.adultDob}
                    name="adultDob"
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    error={adultErrors[i]?.adultDob}
                    range={false}
                    onChange={(e) => handleDOBChange(e, i)}
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                  <div className="form-group">
                    <label>Gender</label>
                    <Select
                      value={{
                        value: `${adult.adultGender}`,
                        label: `${adult.adultGender ?? ""}`,
                      }}
                      styles={DropDownStyleConstant}
                      classNamePrefix="c-select"
                      options={genders}
                      placeholder="Select Gender"
                      onChange={(e) => handleGenderChange(e, i)}
                    />
                    {adultErrors[i]?.adultGender !== "" ? (
                      <p className="error">{adultErrors[i]?.adultGender}</p>
                    ) : (
                      <></>
                    )}
                  </div>
                </form>
              </Col>
            );
          })
        : null}
    </>
  );
};

export default AdultForm;
