import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactInput from '../../../../../../components/InputField';
import ReactInputDateField from '../../../../../../components/InputDateField';


const AddGuest = (props) => {

    const onCancel = () => {
        props.callback(false);
    }

    const onSubmit = () => {
        //TODO:call API to submit request
        props.callback(false);
    }

    return (
        <div className="modify-reservation">
            <form className="c-form">
                <div>
                    <ReactInput type="text" label="First Name" name="firstName" />
                    <ReactInput type="text" label="Last Name" name="lastName" />
                    <ReactInputDateField label="Birth Date" placeholder="mm/dd/yyyy" />
                    <ReactInput type="text" label="Email Address" name="emailAddress" />
                    <span>We will mail the guest to collect their deposit and give them more information on the room.</span>
                    <div className="agreement">
                        <input name="agreement" type="checkbox" />
                        <span>By checking this box, I understand that this is a request to change my travel dates, 
                            changing travel dates may change my package price, guest services will reach out to me, 
                            and I must confirm this change with them before the change will be made.</span>
                    </div>
                </div>
                <div className="button-group">
                    <a className="c-btn btn-block mt-2 " onClick={onCancel}>Cancel</a>
                    <a className="c-btn btn-block mt-2 reservation-submit-btn" onClick={onSubmit}>Submit</a>
                </div>
            </form>
        </div>
    );
}
export default withRouter(AddGuest);