import { Row, Col } from 'reactstrap';
import UsersManagement from './users';
import React, { useState, useEffect } from 'react';
import Loading from '../../../../components/Loading';
import DashboardLayout from '../../../../layouts/Dashboard';

const AdminPage = (props) => {
  const [tabItem, setTabItem] = useState(undefined);

  useEffect(() => {
    let item;
    switch (props.type) {
      case "users":
        item = <UsersManagement />;
        break;

      default:
        item = <Loading />;

    }
    setTabItem(item);
  }, [props.type]);

  return (
    <DashboardLayout>
      <Row>
        <Col xl="12">
          <div className="c-card-layout">
            {tabItem}
          </div>
        </Col>
      </Row>
    </DashboardLayout>
  )
}
export default AdminPage;