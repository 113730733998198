import api from "utils/api";
import Table from 'react-bootstrap/Table';
import { Row, Col } from 'reactstrap';
import React, { useState, useEffect } from 'react';
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";

const Rsvp = (props) => {
    const [loader, setLoader] = useState(true);
    const [rsvpList, setRsvpList] = useState({});

    useEffect(async () => {
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            api.get('/api/rsvp/getRsvpList')
                .then(response => {
                    setRsvpList(response.data);
                    setLoader(false);
                }).catch(error => { setLoader(false); });
        })
    }, []);

    const renderTable = () => {
        return rsvpList.map((item, index) => {
            return (<tr key={index}>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.noOfGuest}</td>
                <td>
                    <span className={item.status + " status"}> {item.status}</span>
                </td>
            </tr>)
        });
    }

    return (
        <div className="w-100">
            {loader && <Loading />}
            {rsvpList && !loader && <>
                <div>
                    <h5 className="header">RSVPs </h5>
                    <div className="body">
                        <Table responsive="lg">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>No. of Guests</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderTable()}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </>}
        </div >
    )
}

export default Rsvp;