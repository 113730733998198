import React from 'react';
import 'react-phone-number-input/style.css'
import Input from 'react-phone-number-input/input'

function PhoneField({ label, error, value, onChange, placeholder }) {

  return (
    <div className="form-group">
      {label && <label> {label} </label>}
      <Input
        maxLength={14}
        defaultCountry="US"
        placeholder={placeholder}
        value={value}
        className={`form-control ${error ? "form-error" : ""}`}
        onChange={onChange} />
      {error && <p className="error">{error}</p>}
    </div>
  )
}
export default PhoneField;