const CoreHelper = {
    IsIframeDetected: () => {
        return window.location !== window.parent.location;
    },

    GetEmailRegex: () => {
        return new RegExp(/^[^\s@]+@[^\s@]+\.(?:com|net|org|edu|gov|mil|biz|info|co|au|ca|br|ht|es|kw|jm|do|us|fr|it|uk|mx|ru|de|se|biz|io)$/i);
    },

    GetFormatedDate: (date) => {
        return (new Date(date)).toISOString().slice(0, 10).replace(/-/g, "-") ?? "00-00-0000"
    },

    GetFormatedTimeFromTimePicker: (time) => {
        return time.replace('am', '').replace('pm', '').trim().concat(':00') ?? "00:00:00"
    },

    GetDeviceType: () => {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) return "tablet";
        else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) return "mobile";
        else return "desktop";
    },

    ConvertTimeFrom24To12Format: (time24) => {
        if (time24 !== null && typeof time24 !== 'undefined') {
            const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
            const period = +sHours < 12 ? 'AM' : 'PM';
            const hours = +sHours % 12 || 12;

            return `${hours}:${minutes} ${period}`;
        } else return null;
    },

    ConvertTimeFrom12To24Format: (time12) => {
        if (time12 !== null && typeof time12 !== 'undefined') {
            const [sHours, minutes, period] = time12.match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/).slice(1);
            const PM = period === 'PM';
            const hours = (+sHours % 12) + (PM ? 12 : 0);

            return `${('0' + hours).slice(-2)}:${minutes}`;
        } else return null;
    },

    GetUsFormattedAmount: (amount) => {
        amount = parseFloat(amount ?? 0);
        return (amount).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
    },

    GetFormatPhoneNumber(phoneNo) {
        //remove US extension +1
        if (phoneNo && phoneNo.indexOf('+1') === 0) {
            phoneNo = phoneNo.substring(2);
        }
        let cleaned = ('' + phoneNo).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        else return "Invalid PhoneNo";
    },

    GetPhoneNoWithoutCountryCode(phoneNo) {
        if (phoneNo && phoneNo.indexOf('+1') === 0) {
            phoneNo = phoneNo.substring(2);
        }
        return phoneNo;
    },

    GetValid18AtTravelDate(travelDate) {
        let _travelDate = new Date(travelDate);
        let _adultAtTravelDateDOB = new Date(_travelDate.getFullYear() - 18, _travelDate.getMonth(), _travelDate.getDate()).toISOString().split('T')[0];
        return _adultAtTravelDateDOB;
    }
}


export default CoreHelper;