import React from 'react';
import SubMainScreen from '../../../layouts/SubMainScreen';
import EnvelopeIcon from '../../../assets/images/icon-envelope-success.svg';
const PreConfirmationScreen = () => {
    return (
        <SubMainScreen>
            <div className="c-auth-screen">
                <div className="auth-content">

                    <div className="text-center mb-5">
                        <img src={EnvelopeIcon} alt="Success" />
                    </div>
                    <div className="auth-title text-center">Information</div>
                    <div className="description text-center ">
                        We've sent a link to confirm your email address. Please check your inbox.
                    </div>
                    <div className="text-note text-center">
                        Didn't get the email? <a href="#"><strong className="text-primary text-primary--hover">Resend Confirmation</strong></a>
                    </div>
                </div>
            </div>
        </SubMainScreen>
    )
}

export default PreConfirmationScreen;