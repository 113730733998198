import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowIcon } from "../../assets/images/icon-arrow-down.svg";
const AccordionItem = (props) => {
  const [data, setData] = useState({});
  const [currentIndex, setCurrentIndex] = useState(undefined);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  useEffect(() => {
    setCurrentIndex(props.selected);
  }, [props.selected]);

  const index = props.index;

  return (
    <div className={`accordion-item ${index === currentIndex ? "active" : ""}`}>
      <div
        className="accordion-header d-flex items-center"
        onClick={() => props.handleClick(index)}
      >
        <div className="accordion-title">{data.title}</div>
        <div className="accordion-toggler">
          <ArrowIcon />
        </div>
      </div>
      <div className="accordion-body">
        <div className="content">{data.body}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
