import api from "utils/api";
import { Link } from 'react-router-dom';
import Logo from '../../../components/Logo';
import GoogleLogin from 'react-google-login';
import Authhelper from '../../../core/authHelper';
import React, { useState, useEffect } from 'react';
import MainScreen from '../../../layouts/MainScreen';
import IconButton from '../../../components/IconButton';
import GoogleIcon from '../../../assets/images/icon-google.svg';
import EmailIcon from '../../../assets/images/icon-envelope.svg';
import FacebookIcon from '../../../assets/images/icon-facebook.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { ReservationHelper } from "core/reservationHelper";

const AuthScreen = () => {
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [facebookApiKey, setFacebookApiKey] = useState(null);
    const [toggleMobilePopup, setToggleMobilePopup] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        api.get('/api/appSetting/getSettings').then(response => {
            setGoogleApiKey(response.data.googleClientId);
            setFacebookApiKey(response.data.facebookApiKey);
        }).catch(e => { Authhelper.SetAppSettingData(null); });
    }, []);

    const handleToggleMobilePopup = () => {
        setToggleMobilePopup(!toggleMobilePopup);
    }

    const responseFacebook = (response) => {
        api.post('/api/auth/facebook', {
            userId: response.userID,
            email: response.email,
            name: response.name,
            groupId: Authhelper.GetGroupId(),
            isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true' ? true : false,
        }).then(response => {
            if (response.data.success) {
                Authhelper.RemoveGroupId();
                localStorage.setItem("user_info", JSON.stringify(response.data));

                if (Authhelper.IsGuestRoleAuthorized()) {
                    if (queryParams.get('returnurl') != null)
                        window.location.replace(queryParams.get('returnurl'))
                    else
                        window.location.replace("/guest/wedding");
                } else if (Authhelper.IsGroupLeaderRoleAuthorized()) {
                    if (queryParams.get('returnurl') != null)
                        window.location.replace(queryParams.get('returnurl'))
                    else
                        window.location.replace("/groupleader/wedding");
                } else if (Authhelper.IsAdminRoleAuthorized()) {
                    if (queryParams.get('returnurl') != null)
                        window.location.replace(queryParams.get('returnurl'))
                    else
                        window.location.replace("/admin/users");
                } else Authhelper.Logout();
            }
        }).catch(error => { });
    }

    const responseGoogle = (response) => {
        api.post('/api/auth/google', {
            email: response.profileObj.email,
            FirstName: response.profileObj.givenName,
            LastName: response.profileObj.familyName,
            GoogleId: response.profileObj.googleId,
            ImageUrl: response.profileObj.ImageUrl,
            Name: response.profileObj.name,
            groupId: Authhelper.GetGroupId(),
            isGroupLeader: ReservationHelper.GetGroupLeaderFlag() === 'true' ? true : false,
        }).then(response => {
            if (response.data.success) {
                Authhelper.RemoveGroupId();
                localStorage.setItem("user_info", JSON.stringify(response.data));
                if (Authhelper.IsGuestRoleAuthorized()) {
                    if (queryParams.get('returnurl') != null)
                        window.location.replace(queryParams.get('returnurl'))
                    else
                        window.location.replace("/guest/wedding");
                } else if (Authhelper.IsGroupLeaderRoleAuthorized()) {
                    if (queryParams.get('returnurl') != null)
                        window.location.replace(queryParams.get('returnurl'))
                    else
                        window.location.replace("/groupleader/wedding");
                } else Authhelper.Logout();
            }
        }).catch(error => { });
    }

    return (
        <MainScreen blur={toggleMobilePopup}>
            <div className={`c-auth-screen ${toggleMobilePopup ? 'mobile-popup' : ''}`}>
                <div className="content text-center">
                    <Logo />
                    <div className="description">
                        Log in to the DestifyApp by clicking the button below.
                    </div>
                    <div className={`mobile-auth-trigger d-lg-none ${toggleMobilePopup ? 'opacity-0' : ''}`}>
                        <div className="d-flex justify-content-center">
                            <a className="c-btn mb-5" href="https://www.app.destify.com">Log in</a>
                        </div>
                    </div>

                    <div className={`mobile-auth-popup ${toggleMobilePopup ? 'active' : ''}`}>
                        <div className="mobile-auth-title d-lg-none">Log In</div>
                        <div className="mobile-auth-popup-toggle d-lg-none" onClick={handleToggleMobilePopup}>+</div>
                        <div className="auth-buttons">
                            <a className="c-btn mb-5" href="https://www.app.destify.com">Log in</a>

                        </div>
                    </div>
                </div>
            </div>
        </MainScreen >
    )
}

export default AuthScreen;