import api from "utils/api";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import React, { useRef, useState, useEffect } from "react";
import Authhelper from "../../../../../../../core/authHelper";
import CoreHelper from "../../../../../../../core/coreHelper";
import Loading from "../../../../../../../components/Loading";
import PrintIcon from "../../../../../../../assets/images/icon-print.svg";
import DownloadIcon from "../../../../../../../assets/images/icon-download.svg";

const ReservationBookingCompleted = (props) => {
  const componentRef = useRef();
  const [loader, setLoader] = useState(false);
  const completionData = props.history.location.state?.completionData;

  if (typeof completionData === "undefined" || completionData === null)
    props.history.push("/guest/reservation/select-date");

  const downloadInvoice = () => {
    setLoader(true);
    api
      .post(
        "/api/report/getReservationInvoicePdf",
        {
          totalRoomPackage: CoreHelper.GetUsFormattedAmount(
            completionData.roomPackageAmount
          ),
          adultCount: completionData.adults,
          childCount: completionData.childs,
          nightCount: completionData.nights,
          totalPaidToday: CoreHelper.GetUsFormattedAmount(
            completionData.totalPaidToday
          ),
          insurance: CoreHelper.GetUsFormattedAmount(completionData.insurance),
          summary: CoreHelper.GetUsFormattedAmount(
            completionData.summaryAmount
          ),
          balance: CoreHelper.GetUsFormattedAmount(
            completionData.balanceAmount
          ),
          roomType: completionData.roomType,
          transfersIncluded: completionData.transfersIncluded,
        },
        { responseType: "blob" }
      )
      .then((response) => {
        download(response, "reservation-invoice.pdf");
        setLoader(false);
      })
      .catch((error) => {
        //report service windows only dll workaround
        alert(
          "We're sorry, that part of our site is not currently working (we've been alerted to the problem!).\nIn the meantime, please try using your browser's print dialog, to save a copy.\nWe sincerely apologize for any inconvenience!"
        );
        setLoader(false);
      });
  };

  const download = (response, fileName) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  };

  const isProd = !window.location.href.includes("reservationsstage");
  const guestDashboardUrl = isProd
    ? "https://app.destify.com"
    : "https://app.zapgas.dev";

  return (
    <div className="page-booking-completed">
      <div className="booking-left-screen">
        <div className="content text-center">
          <svg
            width="76"
            height="77"
            viewBox="0 0 76 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M38.0091 6.27259e-07C55.7032 0.00636437 71.0535 12.2245 75.0263 29.4678C78.9991 46.711 70.5423 64.4167 54.6363 72.1633C38.7282 79.9121 19.5769 75.6527 8.45186 61.8946C-2.67533 48.1343 -2.83017 28.5153 8.07431 14.5812C15.2796 5.37513 26.3199 -0.00212044 38.0091 6.27259e-07Z"
              fill="white"
            />
            <path
              opacity="0.2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.1895 9.21777C11.33 10.8206 9.61452 12.6135 8.07431 14.5814C-2.83017 28.5156 -2.67533 48.1346 8.45186 61.8949C19.5769 75.653 38.7282 79.9123 54.6363 72.1636C59.3932 69.8469 63.4838 66.6395 66.7749 62.8201C64.3392 64.9185 61.6054 66.7328 58.6068 68.1932C42.6987 75.942 23.5474 71.6826 12.4224 57.9245C1.29516 44.1642 1.14032 24.5452 12.0448 10.6111C12.4163 10.1364 12.798 9.67188 13.1895 9.21777Z"
              fill="#136070"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.5703 51.6505C30.4567 51.6505 29.3983 51.1647 28.6771 50.3162L18.2138 38.0599C17.306 37.0311 17.0218 35.593 17.4736 34.2948C17.9254 32.9987 19.041 32.0463 20.3922 31.8045C21.7433 31.5606 23.1199 32.0654 23.9938 33.1239L32.0475 42.5569L53.5447 25.0718C54.5925 24.1809 56.0412 23.9285 57.3308 24.4079C58.6204 24.8894 59.5495 26.0306 59.7595 27.3903C59.9695 28.7521 59.4265 30.1181 58.3426 30.9666L33.9671 50.7977C33.2905 51.3493 32.4442 51.6505 31.5703 51.6505Z"
              fill="#00BFC8"
            />
          </svg>
          <div>
            <h4 className="text-md text-600 mb-4">Booking Completed</h4>
            {/*{completionData && completionData.groupId &&*/}
            {/*    <div className="confirmation-number">*/}
            {/*        <p>Group number</p>*/}
            {/*    <div className='text-600 text-lg'>#{completionData.groupId}</div>*/}
            {/*    </div>*/}
            {/*}*/}
            <br />
            <div>
              Please add{" "}
              <a
                style={{ color: "white" }}
                href="mailto:guestservices@destify.com"
              >
                guestservices@destify.com
              </a>{" "}
              to your contacts to ensure you receive important emails from us
              regarding your reservation.
            </div>
          </div>
        </div>
      </div>
      <div className="booking-right-screen">
        {loader && <Loading />}
        {!loader && completionData && (
          <>
            <div className="content">
              <div ref={componentRef}>
                <h2 className="section-header text-600 mb-4">
                  Your Trip Invoice
                </h2>
                <div className="c-border-box border-box-vertical border-box-filled">
                  <div className="box-item">
                    <div className="align-items-center justify-content-between">
                      <div className="text-label text-400 text-md text-black mb-0">
                        Hotel:
                      </div>
                      <div className="text-title">
                        {completionData.hotelName}
                      </div>
                      <div className="text-label text-400 text-md text-black mb-0">
                        Room Type:
                      </div>
                      <div className="text-title">
                        {completionData.roomType}
                      </div>
                    </div>
                    <br />
                    {completionData.transfersIncluded ? (
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="text-label text-400 text-md text-black mb-0">
                            Transfers
                          </div>
                          <div className="text-title">Included</div>
                        </div>
                        <br />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-label text-400 text-md text-black mb-0">
                        Total Room Package
                      </div>
                      <div className="text-title">
                        {CoreHelper.GetUsFormattedAmount(
                          completionData.roomPackageAmount
                        )}
                      </div>
                    </div>
                    <div className="text-gray-300 ">
                      •{" "}
                      {completionData.adults > 1
                        ? completionData.adults + " adults "
                        : completionData.adults + " adult "}
                      {completionData.childs > 1
                        ? completionData.childs + " childs "
                        : completionData.childs + " child "}
                      •{" "}
                      {completionData.nights > 1
                        ? completionData.nights + " nights "
                        : completionData.nights + " night "}
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between">
                                        <div className="text-label text-400 text-md text-black mb-0">Insurance</div>
                                        <div className="text-title">
                                            + ${completionData.insurance}
                                        </div>
                                    </div> */}
                  </div>

                  <hr />

                  <div className="box-item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-label text-400 text-md text-black mb-3">
                        Summary
                      </div>
                      <div className="text-title">
                        {CoreHelper.GetUsFormattedAmount(
                          completionData.summaryAmount
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-label text-400 text-md text-black mb-0">
                        Total Paid Today
                      </div>
                      <div className="text-title">
                        -{" "}
                        {CoreHelper.GetUsFormattedAmount(
                          completionData.totalPaidToday
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="box-footer box-item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="text-label text-600 text-lg text-md text-black mb-0">
                        Balance
                      </div>
                      <div className="text-title text-600 text-lg">
                        {CoreHelper.GetUsFormattedAmount(
                          completionData.balanceAmount
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ReactToPrint
                trigger={() => (
                  <a className="c-btn c-btn--ghost c-btn--icon c-btn--icon--lg mb-3">
                    <span className="icon">
                      <img src={PrintIcon} alt="Print" />
                    </span>
                    <span className="label">Print</span>
                  </a>
                )}
                content={() => componentRef.current}
              />
              {/* Backend is current broken and cannot produce the necessary pdf. */}
              {/* <a onClick={downloadInvoice} className="c-btn c-btn--ghost c-btn--icon c-btn--icon--lg mb-3">
                            <span className="icon">
                                <img src={DownloadIcon} alt="Print" />
                            </span>
                            <span className="label">Download PDF</span>
                        </a> */}

              {localStorage.enableDashboardFlow === "true" && (
                <a href={guestDashboardUrl} className="c-btn mb-3">
                  <span className="label">View Your Reservation</span>
                </a>
              )}

              {/*<GoToTripDashboardLink />*/}
              <div>
                Please check your email for your payment receipt and guest
                dashboard link from no-reply@destify.com.
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ReservationBookingCompleted;
