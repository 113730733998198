import Dropdown from 'react-dropdown';
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import ReactInputArea from '../../../../../../components/InputAreaField';


const CustomRequest = (props) => {
    const onCancel = () => {
        props.callback(false);
    }

    const onSubmit = () => {
        //TODO:call API to submit request
        props.callback(false);
    }

    return (
        <div className="modify-reservation">
            <form className="c-form">
                <div>
                    <ReactInputArea rows="6" type="text" label="Type in your request below:" name="" />
                    <br></br>
                    <div className="agreement">
                        <input name="agreement" type="checkbox" />
                        <span>Required - By checking this box, I understand that requests are not guarenteed to be fulfilled.
                        We will forward your request on to the appropriate vacation provider (hotel, airline, etc)
                        and they will accommodate if possible.</span>
                    </div>
                </div>
                <div className="button-group">
                    <a className="c-btn btn-block mt-2 " onClick={onCancel}>Cancel</a>
                    <a className="c-btn btn-block mt-2 reservation-submit-btn" onClick={onSubmit}>Submit</a>
                </div>
            </form>
        </div>
    );
}
export default withRouter(CustomRequest);