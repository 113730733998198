import api from "utils/api";
import { withRouter } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Loading from "../../../../../../components/Loading";
import Authhelper from "../../../../../../core/authHelper";
import ReactInputArea from '../../../../../../components/InputAreaField';

const BeddingRequest = (props) => {
    const [roomId, setRoomId] = useState(null);
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState(null);
    const onCancel = () => props.callback(false);
    const [messageColor, setMessageColor] = useState(null);
    const [responseBody, setResponseBody] = useState(null);
    const onMessageChange = (e) => setMessage(e.target.value);


    useEffect(async () => {
        Authhelper.GetAuthenticatedRoomId().then(ROOM_ID => { setRoomId(ROOM_ID) });
    }, []);

    const onSubmit = () => {
        setLoader(true);
        Authhelper.GetAuthenticatedEmailAddress().then(AUTHENTICATED_EMAIL => {
            api.post('/api/support/createBeddingRequestTicket', {
                message: message,
                subject: "Bedding Request",
                issueType: "10184",
                requesterName: Authhelper.GetAuthenticatedFullName(),
                requesterEmail: AUTHENTICATED_EMAIL,
                roomId: roomId,
                serverName: ""
            })
                .then(response => {
                    setLoader(false);
                    if (response.data) {
                        setMessageColor("green");
                        setResponseBody("Bedding request created successfully.");
                    } else {
                        setMessageColor("red");
                        setResponseBody("Something went wrong during Bedding request. Please contact to the administrator.");
                    }
                }).catch(error => {
                    setLoader(false);
                    setMessageColor("red");
                    setResponseBody("Something went wrong during Bedding request. Please contact to the administrator.");
                });
        })
    }

    return (
        <div className="modify-reservation">
            {loader && <Loading />}
            {!loader && <>
                <form className="c-form">
                    <div>
                        <p style={{ color: messageColor }}>{responseBody}</p>
                        <ReactInputArea rows="6" type="text" label="Type in your request below:" name="" onChange={onMessageChange} />
                        <br></br>
                        <div className="agreement">
                            <input name="agreement" type="checkbox" />
                            <span>Required - By checking this box, I understand that requests are not guarenteed to be fulfilled.
                            We will forward your request on to the appropriate vacation provider (hotel, airline, etc) and
                            they will accommodate if possible.</span>
                        </div>
                    </div>
                    <div className="button-group">
                        <a className="c-btn btn-block mt-2 " onClick={onCancel}>Cancel</a>
                        <a className="c-btn btn-block mt-2 reservation-submit-btn" onClick={onSubmit}>Submit</a>
                    </div>
                </form>
            </>}
        </div>
    );
}
export default withRouter(BeddingRequest);