import React from 'react';
import { withRouter } from 'react-router-dom';
import SubMainScreen from '../../../layouts/SubMainScreen';

const RouteNotfound = (props) => {
    const goBack = () => props.history.goBack();

    return (
        <SubMainScreen>
            <div className="c-auth-screen">
                <div className="auth-content">
                    <div className="auth-title">404 Error !!!</div>
                    <div>Page not found assiciated with this Url. Please go back and continue.</div>
                    <br></br>
                    <div>
                        <a type="button" onClick={goBack} className="c-btn btn-block mt-2">Go Back</a>
                    </div>
                </div>
            </div>
        </SubMainScreen>
    )
}

export default withRouter(RouteNotfound);
