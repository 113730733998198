import React from "react";
import CoreHelper from "core/coreHelper";

import {RemainingAmountDueDate,getMMDDYYYFormat} from "./utils"


const Summary = ({
  checkInDate,
  checkOutDate,
  fullPayment,
  hotelPrice,
  totalNights,
  adults,
  childs,
  selectedTransferOption,
  roomDeposit,
  totalAmountDueToday,
  travelInsurance,
  hasSupplierCode,
  showRemainingBalance,
}) => {
  return (
    <div>
      <div className="c-border-box d-flex border-box-horizontal">
        <div className="box-item flex-fill">
          <div className="text-label text-sub-label">Check-in</div>
          <h5 className="header ">{getMMDDYYYFormat(checkInDate)}</h5>
          <p className="text-body">3:00 PM</p>
        </div>
        <div className="box-item flex-fill">
          <div className="text-label text-sub-label">Check-out</div>
          <h5 className="header ">{getMMDDYYYFormat(checkOutDate)}</h5>
          <p className="text-body">11:00 AM</p>
        </div>
      </div>

      <div className="c-border-box border-box-vertical border-box-filled">
        <h2 className="box-header">Summary</h2>
        <div className="box-item">
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-label text-600 text-md text-black mb-0">
              Total Price
            </div>
            <div className="text-title">
              {CoreHelper.GetUsFormattedAmount(fullPayment)}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="text-label text-400 text-md text-black mb-0">
              Hotel Price
            </div>
            <div className="text-title text-400 text-md text-black ">
              {CoreHelper.GetUsFormattedAmount(hotelPrice)}
            </div>
          </div>
          <div className="text-gray-500 ">
            •{" "}
            {adults.length > 1
              ? adults.length + " adults "
              : adults.length + " adult "}
            {childs.length > 1
              ? childs.length + " childs "
              : childs.length + " child "}
            •{" "}
            {totalNights > 1
              ? totalNights + " nights "
              : totalNights + " night "}
          </div>

          {selectedTransferOption && (
            <div>
              <div className="box-content pt-2">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="text-label text-400 text-md text-black mb-0">
                    Transfers
                  </div>
                  <div className="text-title text-400 text-md text-black ">
                    {CoreHelper.GetUsFormattedAmount(
                      selectedTransferOption.travelZapTransferPrice
                    )}
                  </div>
                </div>
              </div>

              {selectedTransferOption.priceType === "PerPerson" && (
                <div className="text-gray-300 ">
                  •{" "}
                  {`${adults.length} ${
                    adults.length > 1 ? "adults" : "adult"
                  } X ${CoreHelper.GetUsFormattedAmount(
                    selectedTransferOption.pricePerRoundTrip
                  )} per guest`}
                  <br />•{" "}
                  {`${childs.length} ${
                    childs.length > 1 ? "children" : "child"
                  } X ${CoreHelper.GetUsFormattedAmount(
                    selectedTransferOption.pricePerRoundTrip
                  )} per guest`}
                </div>
              )}

              {selectedTransferOption.priceType === "PerVehicle" && (
                <div className="text-gray-300 ">
                  •{" "}
                  {`1 X ${CoreHelper.GetUsFormattedAmount(
                    selectedTransferOption.pricePerRoundTrip
                  )} per vehicle`}
                </div>
              )}
            </div>
          )}

          {hasSupplierCode ? (
            <div className="box-content pt-2">
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className={`text-label text-md text-black mb-0 ${
                    travelInsurance > 0 ? "text-800" : "text-400"
                  }`}
                >
                  Travel Insurance
                </div>
                <div
                  className={`text-label text-md text-black ${
                    travelInsurance > 0 ? "text-800" : "text-400"
                  }`}
                >
                  {CoreHelper.GetUsFormattedAmount(travelInsurance)}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="box-content pt-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-label text-400 text-md text-black mb-0">
                Room Deposit
              </div>
              <div className="text-title text-400 text-md text-black ">
                {CoreHelper.GetUsFormattedAmount(roomDeposit)}
              </div>
            </div>
          </div>
          <hr
            style={{
              borderTopWidth: "2px",
              borderRadius: "5px",
            }}
          />
          <div className="box-content pt-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="text-label text-400 text-md text-black mb-0">
                Amount Due Today
              </div>
              <div className="text-title text-400 text-md text-black ">
                {CoreHelper.GetUsFormattedAmount(totalAmountDueToday)}
              </div>
            </div>
          </div>
          {showRemainingBalance && (
            <div className="box-content pt-2">
              <div className="d-flex align-items-center justify-content-between">
                <div className="text-label text-400 text-md text-black mb-0">
                  Remaining Balance due by
                </div>
                <div className="text-title text-400 text-md text-black ">
                  {RemainingAmountDueDate(checkInDate)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
