import api from "utils/api";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Authhelper from '../../../core/authHelper';
import Loading from "../../../components/Loading";
import MainScreen from '../../../layouts/MainScreen';
import BackButton from '../../../components/BackButton';
import ReactInput from '../../../components/InputField';
import ReactPassword from '../../../components/PasswordField';
import { EMAIL_VALIDATION_REGEX, ERROR_GENERAL } from 'core/globalConstant';

class LoginFormScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            errors: {},
            loader: false,
            loginError: false,
            errorMessage: ''
        }
    }
    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        const errors = this.handleFormValidation(this.state);
        this.setState({ errors }, () => {
            if (Object.keys(errors).length === 0) {
                const data = {
                    email: this.state.email,
                    password: this.state.password,
                }
                this.setState({ loader: true });
                api.post('/api/auth/authenticate', data)
                    .then(response => {
                        this.setState({ loader: false });
                        if (response.data.success) {
                            Authhelper.RemoveGroupId();
                            localStorage.setItem("user_info", JSON.stringify(response.data));
                            const queryParams = new URLSearchParams(window.location.search);
                            if (Authhelper.IsGuestRoleAuthorized()) {
                                if (queryParams.get('returnurl') != null)
                                    window.location.replace(queryParams.get('returnurl'))
                                else
                                    window.location.replace("/guest/wedding");
                            } else if (Authhelper.IsGroupLeaderRoleAuthorized()) {
                                if (queryParams.get('returnurl') != null)
                                    window.location.replace(queryParams.get('returnurl'))
                                else
                                    window.location.replace("/guest/wedding");
                            } else if (Authhelper.IsAdminRoleAuthorized()) {
                                if (queryParams.get('returnurl') != null)
                                    window.location.replace(queryParams.get('returnurl'))
                                else
                                    window.location.replace("/admin/users");
                            } else Authhelper.Logout();

                            window.dataLayer?.push({
                                event: "LoginSuccess",
                                method: "Email",
                              });

                        } else this.setState({ loginError: true, errorMessage: "Username or Password doesn't match." });
                    }).catch(error => {
                        this.setState({ loader: false, loginError: true, errorMessage: { ERROR_GENERAL } });
                    });
            } else { return false; }
        });
        return false;
    }

    handleFormValidation = (state) => {
        let errors = {};
        const emailRegex = EMAIL_VALIDATION_REGEX;
        if (!this.state.email) errors.email = "Enter email address";
        if (!emailRegex.test(this.state.email)) errors.email = "Invalid email address";
        if (!this.state.password) errors.password = "Enter your password";

        return errors;
    }
    render() {
        const { errors } = this.state;
        const queryParams = new URLSearchParams(window.location.search);
        return (
            <MainScreen>

                {this.state.loader && <Loading />}
                {!this.state.loader && <>
                    <div className="c-auth-screen">
                        <div className="auth-content">
                            <BackButton target={queryParams.get('returnurl') != null ? "/?returnurl=" + queryParams.get('returnurl') : "/"} />

                            <div className="auth-title text-center">Login</div>

                            {this.state.loginError && <>
                                <div className="error-message">
                                    <span>{this.state.errorMessage}</span>
                                </div>
                            </>}

                            <form className="c-form" onSubmit={this.handleFormSubmit}>
                                <ReactInput name="email" placeholder="Email" label="Email" error={errors && errors.email} onChange={this.handleInputChange} />
                                <ReactPassword name="password" placeholder="Password" label="Password" error={errors && errors.password} onChange={this.handleInputChange} />
                                <div className="text-note mb-4 text-center">Forgot password? <Link to="/forgot-password"><strong className="text-primary text-primary--hover">Recover it</strong></Link></div>
                                <button type="submit" className="c-btn mb-5">Login</button>
                                <div className="text-note mb-4 text-center">Don't have account? <Link to={queryParams.get('returnurl') != null ? "/register?returnurl=" + queryParams.get('returnurl') : "/register"}><strong className="text-primary text-primary--hover">Sign Up</strong></Link></div>
                            </form>
                        </div>
                    </div>
                </>}
            </MainScreen>
        )
    }
}

export default LoginFormScreen;