import React, { Component } from "react";
import api from "utils/api";
import Authhelper from "../../../../../core/authHelper";
import Loading from "../../../../../components/Loading";
import ReactInput from "../../../../../components/InputField";
import ReactPassword from "../../../../../components/PasswordField";
import { EMAIL_VALIDATION_REGEX, ERROR_GENERAL } from 'core/globalConstant';
import { Link } from 'react-router-dom';
/**
 *
 * Login component to show on the Guest reservation booking flow
 *
 * */
class LoginFormScreen extends Component {
    static BAD_LOGIN_CREDS_ERROR = "The Username or Password you've entered is incorrect. Please note that after 5 failed login attempts, your account will be temporarily locked out for 5 minutes.";
    static ACCOUNT_LOCKOUT_ERROR = "Your account has been temporarily locked out, due to too many unsuccesful login attempts. Please wait 5 minutes and try again."

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            validationErrors: {},
            loader: false,
            loginError: false,
            errorMessage: "",
            destifyWebAppUrl: props.destifyWebAppUrl
        };
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        const validationErrors = this.handleFormValidation();
        this.setState({ validationErrors }, () => {
            if (Object.keys(validationErrors).length === 0) {
                const payload = {
                    email: this.state.email,
                    password: this.state.password,
                };
                this.setState({ loader: true });
                api
                    .post("/api/auth/authenticate", payload)
                    .then(({ data }) => {
                        this.setState({ loader: false });
                        if (data.success) {
                            Authhelper.RemoveGroupId();
                            localStorage.setItem("user_info", JSON.stringify(data));
                            this.props.onSubmit();

                             // Add GTM dataLayer event for successful login
                            window.dataLayer?.push({
                                event: "LoginSuccess",
                                method: "Email"
                            });
                        } else {
                            this.setState({
                                loginError: true,
                                errorMessage: data?.signinResult?.isLockedOut ? LoginFormScreen.ACCOUNT_LOCKOUT_ERROR : LoginFormScreen.BAD_LOGIN_CREDS_ERROR,
                            });
                        }
                    })
                    .catch((error) => {
                        const accountLockedOut = error?.response?.data?.signinResult?.isLockedOut ?? false;
                        const returnedErrors = error?.response?.data?.error;
                        let displayError = LoginFormScreen.BAD_LOGIN_CREDS_ERROR;
                        if (accountLockedOut) {
                            displayError = LoginFormScreen.ACCOUNT_LOCKOUT_ERROR;
                        }
                        else if (returnedErrors?.length > 0) {
                            displayError = returnedErrors[0];
                        }

                        this.setState({
                            loader: false,
                            loginError: true,
                            errorMessage: displayError,
                        });
                    });
            } else {
                return false;
            }
        });
        return false;
    }

    handleFormValidation = () => {
        let validationErrors = {};

        if (!this.state.email) {
            validationErrors.email = "Enter email address";
        }
        if (this.state.email && !EMAIL_VALIDATION_REGEX.test(this.state.email)) {
            validationErrors.email = "Invalid email address";
        }
        if (!this.state.password) {
            validationErrors.password = "Enter your password";
        }
        return validationErrors;
    }

    render() {
        const { validationErrors } = this.state;
        return (
            <div>
                {this.state.loader && <Loading />}
                {!this.state.loader && (
                    <>
                        <div className="c-auth-screen" style={{ padding: "0" }}>
                            <div className="auth-content">
                                <div className="auth-title text-center" style={{ margin: "0" }}>
                                    Login
                                </div>

                                {this.state.loginError && (
                                    <div className="error-message">
                                        <span>{this.state.errorMessage}</span>
                                        <br />
                                        <small>
                                            If you're having trouble logging into your account,
                                            please contact guest services at <a href='mailto:guestservices@destify.com' target="_blank">guestservices@destify.com</a> for assistance.</small>
                                    </div>
                                )}

                                <form className="c-form" onSubmit={this.handleFormSubmit}>
                                    <ReactInput
                                        name="email"
                                        placeholder="Email"
                                        error={validationErrors && validationErrors.email}
                                        onChange={this.handleInputChange}
                                    />
                                    <ReactPassword
                                        name="password"
                                        placeholder="Password"
                                        error={validationErrors && validationErrors.password}
                                        onChange={this.handleInputChange}
                                    />
                                    <div className="text-note mb-4 text-center">
                                        Forgot password?{" "}
                                       <Link to="/forgot-password"><strong className="text-primary text-primary--hover">Recover it</strong></Link>

                                    </div>
                                    <button type="submit" className="c-btn mb-3">
                                        Login
                                    </button>
                                    <div className="text-note mb-3 text-center">
                                        Don't have account?{" "}
                                        <button
                                            onClick={this.props.handleHideLoginForm}
                                            className="btn btn-link"
                                        >
                                            <strong className="text-primary text-primary--hover">
                                                Sign Up
                                            </strong>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default LoginFormScreen;