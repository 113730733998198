import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";

import LiveChat from "react-livechat";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./context/store";
import CoreHelper from "./core/coreHelper";

const isIframeDetected = CoreHelper.IsIframeDetected();

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  tracePropagationTargets: [/\.destify\.com/],
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration()],

  // set to lowest sampling rate
  tracesSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,

  beforeSend(ev) {
    // only send error or fatal level threats to sentry
    if (ev.level && (ev.level === "error" || ev.level === "fatal")) {
      return ev;
    }
    return null;
  },
});

ReactDOM.render(
  <StateProvider>
    <BrowserRouter>
      <App />
      {/* {!isIframeDetected &&
        <div className="chat-area">
          <LiveChat className="livechat" license={12099810} />
        </div>
      } */}
    </BrowserRouter>
  </StateProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
