import moment from 'moment';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import React, { useState, useEffect } from 'react';

const ReactInputTimeField = ({ label, name, placeholder, error, onChange, value }) => {
    const timeFormat = "hh:mm a";
    const [defaultValue, setDefaultValue] = useState(moment());

    useEffect(() => {
        let _hour = value?.split(':')[0];
        let _minute = value?.split(':')[1];
        let val = moment().hour(_hour).minute(_minute);
        setDefaultValue(val);
    }, [])

    const handleTimeChange = value => {
        setDefaultValue(value);
        let _formattedValue = moment(value, ["h:mm:ss A"]).format("HH:mm:ss");
        onChange(_formattedValue);
    }

    return (
        <div className="form-group">
            {label && <label> {label} </label>}
            <div>
                <TimePicker
                    value={defaultValue}
                    showSecond={false}
                    className="form-control"
                    onChange={handleTimeChange}
                    format={timeFormat}
                    use12Hours
                    inputReadOnly
                    name={name}
                    placeholder={placeholder}
                />
            </div>
            {error && <p className="error">{error}</p>}
        </div>
    )
}
export default ReactInputTimeField;