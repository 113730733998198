import React from 'react';
import Authhelper from "./authHelper";
import { Redirect, Route } from "react-router-dom";

function PublicRoute({ auth, ...props }) {
    const isAllowed = Authhelper.IsAuthenticated();
    const isAdminUser = Authhelper.IsAdminRoleAuthorized();
    return (isAllowed && isAdminUser) ? (<Redirect to="/admin/users" />)
        : (isAllowed && !isAdminUser) ? (<Redirect to="/guest/wedding" />) : (<Route {...props} />)

};

export default PublicRoute;